import { useMemo, useState } from 'react';
import { UserInfo, PatientInfo } from '../../lib/interfaces/user';
import clsx from 'clsx';
import styles from './style.module.css'

export type AvatarSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
export type AvatarType = 'initials' | 'photo'
type User = UserInfo | PatientInfo

export interface AvatarProps {
  user?: User
  size?: AvatarSize
  type?: AvatarType
  firstName?: string,
  lastName?: string,
  className?: string,
  onClick?: () => void,
  fontSize?: number,
  containerSize?: number,
  photo?: string,
  title?: string
}

export const Avatar = ({
  size = 'lg',
  type = 'photo',
  user,
  className,
  onClick,
  fontSize,
  firstName,
  lastName,
  photo,
  containerSize,
  title
}: AvatarProps) => {
  const [displayedPhoto, setDisplayedPhoto] = useState<string | undefined>(user?.photo || photo)

  const _firstName = useMemo(() => user?.firstName || firstName || ' ', [user, firstName])
  const _lastName = useMemo(() => user?.lastName || lastName || ' ', [user, lastName])
  const initials = useMemo(() => [_firstName[0], _lastName[0]].join(''), [_firstName, _lastName])

  const useInitials = () => setDisplayedPhoto(undefined)

  return (
    <div
      title={title ? title : user ? [_firstName, _lastName].join(' ') : undefined}
      className={clsx(
        styles.avatar,
        styles[size],
        type === 'initials' || !displayedPhoto ? styles.initials : styles.photo,
        onClick && styles.clickable,
        className
      )}
      style={{ fontSize, width: containerSize, height: containerSize }}
      onClick={onClick}>
      {type === 'initials' || !displayedPhoto ? (
        initials
      ) : (
        <img data-dd-privacy="mask" width='100%' src={displayedPhoto} onError={useInitials} />
      )}
    </div>
  )
}
