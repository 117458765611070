import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { Switch, Route, useHistory } from "react-router-dom";
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs'
import config from './config/okta.config.ts'
import ddConfig from './config/datadog.config.ts';
import Login from "./pages/login/Login";
import { Base } from './containers/_base/Base.tsx';
import { AppContextProvider } from './lib/context/appContext';

const oktaAuth = new OktaAuth(config.auth)

 // initialize DataDog RUM && Session Replay
  datadogRum.init(ddConfig.rum);

  // initialize DataDog Logging
  datadogLogs.init(ddConfig.logs);

export default function App() {
  const history = useHistory()

  const onAuthRequired = () => history.push('/login');
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  }

 


  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={onAuthRequired}
      restoreOriginalUri={restoreOriginalUri}
    >
      <AppContextProvider>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route path="/login/callback" component={LoginCallback} />
          <SecureRoute path='/test'>
            here
          </SecureRoute>
          <SecureRoute path='/' component={Base} />
        </Switch>

        <Switch>
        </Switch>
      </AppContextProvider>

    </Security>
  );
}
