import clsx from 'clsx'
import { Check } from 'phosphor-react'
import styles from './style.module.css'

export interface CheckboxProps {
  label?: string
  checked: boolean
  disabled?: boolean
  onToggle?: () => void
  className?: string
}

export const Checkbox = ({
  label,
  checked,
  disabled = false,
  onToggle,
  className
}: CheckboxProps) => (
  <label className={clsx(styles.checkboxContainer, className)} onClick={e => e.stopPropagation()}>
    <div
      tabIndex={!disabled ? 0 : undefined}
      className={clsx(
        styles.checkbox,
        disabled && styles.disabled,
        checked && styles.checked
      )}>
      {checked && <Check size={12} weight='bold' />}
    </div>
    <input
      tabIndex={undefined}
      className={styles.checkboxInput}
      disabled={disabled}
      type='checkbox'
      checked={checked}
      onChange={onToggle}
    />
    {label}
  </label>
)
