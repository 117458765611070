import { AccessToken, OktaAuth, Token } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { createContext, useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "../../components/modal/Modal";
import { LogoutPromptForm } from "../../containers/logout/LogoutPromptForm";
import { sentryException } from "../util/sentry";

export const AppContext = createContext<
  AppContextProviderValueType | undefined
>(undefined);

const API_URL = process.env.REACT_APP_API_URL;

const LOGOUT_PROMPT_REMAINING_TIME = 2 * 60; // 2 minutes, 120 Seconds
const SESSION_TRACK_DELAY = 30000; // milliseconds

type ProviderProps = {
  children: React.ReactNode;
};

type AppContextProviderValueType = {
  auth: OktaAuth;
};

export const AppContextProvider = ({ children }: ProviderProps) => {
  const { oktaAuth } = useOktaAuth();

  const [sessionExpireTime, setSessionExpireTime] = useState(0);

  const sessionTrackTimerRef: { current: NodeJS.Timeout | null } = useRef(null);

  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const [accessToken, setAccessToken] = useState<AccessToken | null>(null);

  const isRenewedRef = useRef(false);
  const logoutTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const populateSessionExpireTime = () => {
    const expireTime = getSessionExpireTime();
    setSessionExpireTime(expireTime);
  };

  const getSessionExpireTime = () => {
    if (accessToken) {
      return oktaAuth.tokenManager.getExpireTime(accessToken);
    }
    return -1;
  };

  const remainingSessionTime = () => {
    const now = Date.now() / 1000;
    const remainingTime = Math.max(sessionExpireTime - now, 0);
    return remainingTime;
  };

  const handleRenewSession = () => {
    if (logoutTimeoutRef.current) {
      clearTimeout(logoutTimeoutRef.current);
    }

    logoutTimeoutRef.current = setTimeout(() => {
      if (!isRenewedRef.current) {
        handleLogoutNow();
      } else {
        isRenewedRef.current = false;
        return;
      }
    }, LOGOUT_PROMPT_REMAINING_TIME * 1000);
  };

  const checkShouldShowLogoutPrompt = () => {
    if (
      sessionExpireTime > 0 &&
      remainingSessionTime() < LOGOUT_PROMPT_REMAINING_TIME
    ) {
      setShowLogoutModal(true);
      handleRenewSession();
    }
  };

  const createSessionTrackTimer = () => {
    const timer = setInterval(() => {
      checkShouldShowLogoutPrompt();
    }, SESSION_TRACK_DELAY);

    return timer;
  };

  const createRequestInterceptor = () => {
    axios.interceptors.request.use(
      (config) => {
        if (config.headers) {
          config.headers.authorization = `Bearer ${oktaAuth.getAccessToken()}`;
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
  };

  const renewSession = async () => {
    return oktaAuth.tokenManager.renew("accessToken");
  };

  const createResponseInterceptor = () => {
    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        sentryException(error);

        if (!error.response || error.response.status !== 401) {
          return Promise.reject(error);
        }

        axios.interceptors.response.eject(responseInterceptor);

        try {
          await renewSession();
          error.response.config.headers[
            "Authorization"
          ] = `Bearer ${oktaAuth.getAccessToken()}`;
          return axios(error.response.config);
        } catch (error) {
          return Promise.reject(error);
        } finally {
          createResponseInterceptor();
        }
      }
    );
  };

  const clearSessionTrackingState = () => {
    if (sessionTrackTimerRef.current) {
      clearInterval(sessionTrackTimerRef.current);
    }

    setShowLogoutModal(false);
    setSessionExpireTime(-1);
  };

  useEffect(() => {
    axios.defaults.baseURL = API_URL;

    // Uncomment to enable forcing the logout prompt popup to appear
    //document.addEventListener("keypress", handleKeyEvent)

    return () => {
      //document.removeEventListener("keypress", handleKeyEvent)

      if (sessionTrackTimerRef.current) {
        clearInterval(sessionTrackTimerRef.current);
      }
    };
  }, []);

  const lookForAccessToken = async () => {
    const isAuth = await oktaAuth.isAuthenticated();

    if (!isAuth) {
      return;
    }

    const tokenStorage = await oktaAuth.tokenManager.getTokens();
    if (tokenStorage && tokenStorage.accessToken) {
      setAccessToken(tokenStorage.accessToken);
    } else {
      setAccessToken(null);
    }
  };

  // const handleKeyEvent = async (ev: KeyboardEvent) => {
  //   if(ev.key === 'r') {
  //     setShowLogoutModal(true)
  //   }
  //   if (ev.key === 's') {

  //     await axios.get('http://localhost:5700/auth')

  //   }
  // }

  useEffect(() => {
    oktaAuth.tokenManager.on("added", async (key: string, token: Token) => {
      if (key === "accessToken") {
        if (
          accessToken === null ||
          accessToken.accessToken !== (token as AccessToken).accessToken
        )
          setAccessToken(token as AccessToken);
      }
    });

    oktaAuth.tokenManager.on("expired", (key: string, _: Token) => {
      clearSessionTrackingState();
      if (key === "accessToken") {
        setAccessToken(null);
        oktaAuth.signOut();
      }
    });

    oktaAuth.tokenManager.on("removed", (key: string, _: Token) => {
      if (key === "accessToken") {
        setAccessToken(null);
        clearSessionTrackingState();
      }
    });

    oktaAuth.tokenManager.on("error", (key: string, _: Token) => {
      setAccessToken(null);
      clearSessionTrackingState();
    });

    if (!accessToken) {
      lookForAccessToken();
    }

    createRequestInterceptor();
    createResponseInterceptor();
  }, [oktaAuth]);

  useEffect(() => {
    if (accessToken) {
      populateSessionExpireTime();
    } else {
      clearSessionTrackingState();
    }
  }, [accessToken]);

  useEffect(() => {
    if (sessionExpireTime > 0) {
      if (sessionTrackTimerRef.current) {
        clearInterval(sessionTrackTimerRef.current);
      }

      const timer = createSessionTrackTimer();
      sessionTrackTimerRef.current = timer;
    }
  }, [sessionExpireTime]);

  const providerValue = useMemo(() => {
    return {
      auth: oktaAuth,
    };
  }, [oktaAuth]);

  const handleLogoutNow = () => {
    setShowLogoutModal(false);
    oktaAuth.signOut();
  };

  const handleStayConnected = async () => {
    setShowLogoutModal(false);
    try {
      await renewSession();
      isRenewedRef.current = true;
      createResponseInterceptor();
    } catch (error) {
      console.error("Error renewing token:", error);
    }
  };

  return (
    <AppContext.Provider value={providerValue}>
      {children}
      <Modal
        dismissable={false}
        visible={showLogoutModal}
        onCloseModal={() => {
          setShowLogoutModal(false);
        }}
        title="Would you like to remain connected?"
      >
        <LogoutPromptForm
          onLogoutNow={handleLogoutNow}
          onStayConnected={handleStayConnected}
        />
      </Modal>
    </AppContext.Provider>
  );
};
