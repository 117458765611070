import React, { useState, useCallback, useEffect, useContext } from "react";
import Video from "twilio-video";
// import { VideoRoomMonitor } from "@twilio/video-room-monitor";
import ConnectingScreen from "../connectingScreen/ConnectingScreen";
import Room from "../room/Room";
import { fetchToken } from "../../../../lib/apis/chat";
import { useOktaAuth } from "@okta/okta-react";
import { BaseContext } from "../../../../lib/context/context";
import { VideoRoomMonitor } from '@twilio/video-room-monitor';
export interface VideoChatProps {
  visitId: any;
  patient: any;
  noCarePlan: boolean;
  providerId: string;
}

const VideoChat = ({
  visitId,
  patient,
  noCarePlan,
  providerId,
}: VideoChatProps) => {
  const { user } = useContext(BaseContext);

  // TODO: use provider ID
  const [username, setUsername] = useState<string>(providerId);
  const [roomName, setRoomName] = useState<string>(visitId);

  const [room, setRoom] = useState<Video.Room | null>(null);
  const [connecting, setConnecting] = useState(false);
  const [permissionError, setPermissionError] = useState(false);
  const { oktaAuth } = useOktaAuth();

  const JoinVisit = (token: string) => {
    setConnecting(true);
    // add visit api call here to extract visit id
    Video.connect(token, {
      name: roomName,
      audio: true,
      video: { frameRate: 24 },
      bandwidthProfile: {
        // video: {
        //   mode: "collaboration",
        //   dominantSpeakerPriority: "high",
        // },
      },
      dominantSpeaker: true,
      preferredVideoCodecs: [{ codec: "VP8", simulcast: true }],
      networkQuality: { local: 1, remote: 1 },
    })
      .then((room) => {
        setConnecting(false);
        // setPermissionError(false);
        setRoom(room);
        if(process.env.REACT_APP_SENTRY_ENV !== 'production'){
          VideoRoomMonitor.registerVideoRoom(room);
        }
      })
      .catch((err) => {
        console.error("connection error:", err);
        setPermissionError(true);
        setConnecting(false);
      });
  };

  const handleLogout = useCallback((roomToLeave?: Video.Room) => {
    if (!room && roomToLeave) {
      roomToLeave.localParticipant.tracks.forEach((trackPub) => {
        if ("stop" in trackPub.track) {
          trackPub.track.stop();
        }
      });
      roomToLeave.disconnect();
    }
    setRoom((prevRoom) => {
      if (prevRoom) {
        prevRoom.localParticipant.tracks.forEach((trackPub) => {
          if ("stop" in trackPub.track) {
            trackPub.track.stop();
          }
        });
        prevRoom.disconnect();
      }
      return null;
    });
    if(process.env.REACT_APP_SENTRY_ENV !== 'production'){
      VideoRoomMonitor.closeMonitor();
    }
  }, []);

  useEffect(() => {
    //poss. move below into a hook OR replace with context
    const accessToken = oktaAuth.getAccessToken();
    if (accessToken) {
      fetchToken("video", roomName).then((res) => {
        const twilioToken = res.token;
        JoinVisit(twilioToken);
      });
    }
  }, []);

  const handleStats = (val?: boolean) => {
    if(val){
      VideoRoomMonitor.closeMonitor()
    } else {
      VideoRoomMonitor.toggleMonitor()
    }
  }

  useEffect(() => {
    if (room) {
      const tidyUp = (event: { persisted: any }) => {
        if (event.persisted) {
          return;
        }
        if (room) {
          handleLogout();
        }
      };
      window.addEventListener("pagehide", tidyUp);
      // @ts-ignore
      window.addEventListener("beforeunload", tidyUp);
      return () => {
        window.removeEventListener("pagehide", tidyUp);
        // @ts-ignore
        window.removeEventListener("beforeunload", tidyUp);
      };
    }
  }, [room, handleLogout]);

  let render;
  if (room) {
    render = (
      <Room
        roomName={roomName}
        room={room}
        patient={patient}
        handleLogout={handleLogout}
        permissionError={permissionError}
        noCarePlan={noCarePlan}
        showStats={handleStats}
      />
    );
  } else {
    render = (
      <ConnectingScreen
        room={room}
        username={username}
        handleLogout={handleLogout}
        patient={patient}
        permissionError={permissionError}
      />
    );
  }
  return render;
};

export default VideoChat;
