import { useEffect, useState } from "react"
import { Body } from "../../../components/typography/body/Body"
import { Heading } from "../../../components/typography/heading/Heading"
import { Table } from "../../../components/table/Table"
import { Medication } from "../../../lib/interfaces/medications"
import { CellProps } from "../../../components/table/Table"
import { format } from 'date-fns'
import { useOktaAuth } from "@okta/okta-react"
import styles from './style.module.css'
import { PatientInfo } from "../../../lib/interfaces/user"

export interface MedicalHistoryProps {
  patient: PatientInfo
}

export const MedicalHistory = ({ patient }: MedicalHistoryProps) => {

  const [medicalHistory, setMedicalHistory] = useState([])

  const columns = ['Date', 'Diagnosis', 'Comment']

  return (
    <div>
      <Heading type='02' className={styles.header}>Medical History</Heading>
      <div>
        <Table data-dd-privacy="mask" friendlyTitle='medical history' data={[]} columns={columns} />
      </div>
    </div>
  )
}