import axios, { AxiosResponse } from 'axios'
import { Medication } from "../interfaces/medications"

interface ResponseData {
  active: Medication[],
  inactive: Medication[]
}

interface MedicationsResponse extends AxiosResponse {
  data: ResponseData
}

export const fetchMedications = async (userId: string, controller?: AbortController) => {
  const result = await axios.get<MedicationsResponse, MedicationsResponse>(
    `/prescriptions?userId=${userId}`,
    {
      signal:  controller ? controller.signal : undefined
    })
    .catch(err => { 
      if(axios.isCancel(err)) return {data: 'cancelled'}
      else return err
    })
  return result.data
}