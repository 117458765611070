import { format, isToday, isWithinInterval, isYesterday, startOfToday, subDays } from "date-fns"
import { Pencil } from "phosphor-react"
import { useEffect, useMemo, useState } from "react"
import { Button, ButtonGroup } from "../../../components/button/Button"
import { TextInput } from "../../../components/textInput/TextInput"
import { Body } from "../../../components/typography/body/Body"
import { Heading } from "../../../components/typography/heading/Heading"
import { getLocalDateTime } from "../../../lib/util/date"
import { useProvider } from "../../../lib/hooks/useProvider"
import { Focus } from "../../../lib/interfaces/carePlan"
import { useBaseContext } from "../../_base/Base"
import styles from './style.module.css'

const summaryPlaceholder = {
  focus: 'Add Title of Focus (i.e. Weight Loss, Hypertension)',
  comments: 'Add clinican comments to coaches to help them create actions for assigned pillar below.'
}

interface CarePlanFocusProps {
  focus?: Focus
  onSave: (focus: Focus) => Promise<boolean>
}

export const CarePlanFocus = ({ focus, onSave }: CarePlanFocusProps) => {
  const [draftFocus, setDraftFocus] = useState<string>(focus?.value || '')
  const [draftComments, setDraftComments] = useState<string>(focus?.comments || '')
  const [editing, setEditing] = useState(false)
  const { user } = useBaseContext()
  const [modifiedBy, setModifiedBy] = useState<string>()
  const { getProvider } = useProvider()

  const modifiedOn = useMemo(() => {
    if (focus?.modifiedBy && focus.modifiedOn) {
      const date = getLocalDateTime(focus.modifiedOn)
      const time = format(date, 'h:mm a')
      let day: string;
      if (isToday(date))
        day = 'Today'
      else if (isYesterday(date))
        day = 'Yesterday'
      else if (isWithinInterval(date, { start: subDays(startOfToday(), 6), end: startOfToday() }))
        day = format(date, 'eeee')
      else
        day = format(date, 'M/d/yy')

      return `${day} ${time}`
    }
  }, [focus])

  useEffect(() => {
    if (focus?.modifiedBy)
      getProvider(focus.modifiedBy).then(provider => setModifiedBy(provider.name))
  }, [focus])

  const handleCancel = () => {
    setEditing(false)
    setDraftFocus(focus?.value || '')
    setDraftComments(focus?.comments || '')
  }

  const handleSave = async () => {
    const result = await onSave({
      value: draftFocus,
      modifiedBy: user?.sub as string,
      comments: draftComments
    })

    if (result) {
      setEditing(false)
    } else { }
  }

  const handleFocusChange = (value: string) => setDraftFocus(value)
  const handleCommentsChange = (value: string) => setDraftComments(value)

  return (
    <div className={styles.summarySection}>
      {!editing ? (
        <>
          <div className={styles.header}>
            {draftFocus.length > 0 ? (
              <Heading type='01'>{draftFocus}</Heading>
            ) : (
              <Heading type='01' color='disabled'>{summaryPlaceholder.focus}</Heading>
            )}
            <Button Icon={Pencil} onClick={() => setEditing(true)} size='small' type='secondary' />
          </div>
          <Body size='lg'>
            {draftComments ? draftComments : summaryPlaceholder.comments}
          </Body>
          {modifiedBy && (<Body size='sm' weight='bold' color='link'>{`Edited ${modifiedOn} ${modifiedBy}`}</Body>)}
        </>
      ) : (
        <>
          <TextInput className={styles.headerInput} placeholder={summaryPlaceholder.focus} value={draftFocus} onChange={handleFocusChange} />
          <TextInput className={styles.bodyInput} placeholder={summaryPlaceholder.comments} value={draftComments} onChange={handleCommentsChange} multiline minRows={5} maxRows={5} />
          <ButtonGroup className={styles.buttonGroup} align='end'>
            <Button label='Cancel' onClick={handleCancel} type='secondary-gray' />
            <Button label='Save' onClick={handleSave} />
          </ButtonGroup>
        </>
      )}
    </div >
  )
}