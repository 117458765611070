import clsx from 'clsx'
import { CSSProperties, ReactChild } from 'react'
import styles from './style.module.css'


type BodyWeight = 'regular' | 'medium' | 'bold'
type BodySize = 'lg' | 'md' | 'sm' | 'xs'
export type BodyColor = 'primary' | 'secondary' | 'link' | 'disabled' | 'white' | 'danger' | 'inherit'

interface BodyProps {
  children?: ReactChild | ReactChild[]
  size?: BodySize
  weight?: BodyWeight
  color?: BodyColor
  style?: CSSProperties
  className?: string
  title?: string
  props?: any
}

export const Body = ({
  children,
  size = 'md',
  weight = 'regular',
  color = 'primary',
  style,
  className,
  title,
  ...props
}: BodyProps) => (
  <p
    className={clsx(
      styles.base,
      styles[size],
      styles[weight],
      styles[color],
      className
    )}
    style={style}
    title={title}
    {...props}
  >
    {children}
  </p>
)