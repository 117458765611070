import { useState, useEffect } from "react"
import _ from "lodash"
import { Body } from "../../../components/typography/body/Body"
import styles from './style.module.css'

export interface NotesDetailProps {
  noteId: string,
  author: string,
  date: string,
  notes: string
}

export const NotesDetail = ({noteId, author, date, notes}: NotesDetailProps) => {

  return (
    <div className={styles.notesDetail}>
        <div>
            <Body className={styles.author} size='md' weight='bold'>{'Author'}</Body>
            <Body size='md'>{author}</Body>
            <Body className={styles.date} size='md' weight='bold'>{'Date'}</Body>
            <Body size='md'>{date}</Body>
            <Body className={styles.notes} size='md' weight='bold'>{'Notes'}</Body>
            <div className={styles.epicNote}>
              <div data-dd-privacy="mask" dangerouslySetInnerHTML={{ __html: notes }} />
              <Body size='sm' color="secondary" style={{marginTop: '24px'}}>Imported from Epic</Body>
            </div>
        </div>
    </div>
  )
}