import { v4 as uuidv4 } from 'uuid'
import { useState } from 'react'
import { CalendarBlank } from 'phosphor-react'
import { Body } from "../../../components/typography/body/Body"
import { Switch } from "../../../components/switch/Switch"
import { Button, ButtonGroup } from "../../../components/button/Button"
import { Hours } from "../../../lib/interfaces/availbilityManagement"
import { TextInput } from "../../../components/textInput/TextInput"
import { Option } from "../../../lib/interfaces/input"
import { SelectDateOverrideHours } from "../selectDateOverrideHours/SelectDateOverrideHours"
import { format, isFuture, isToday, isValid } from 'date-fns'
import styles from './style.module.css'
import _ from 'lodash'

export type HourType = 'from' | 'to'

export interface AddDateOverrides {
  handleOnCancel: () => void,
  handleOnSave: (date: string, hours: Hours[], unavailable: boolean) => void, 
}

export const AddDateOverrides = ({ 
  handleOnCancel = () => { }, 
  handleOnSave = () => { }, 
}: AddDateOverrides) => {

  const [hours, setHours] = useState<Hours[]>([{from: { name: '9:00am', value: '9:00am' }, to: { name: '5:00pm', value: '5:00pm' }}])
  const [keys, setKeys] = useState([uuidv4()])
  const [unavailable, setUnavailable] = useState(false)
  const [date, setDate] = useState("")
  const [friendlyDate, setFriendlyDate] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [disableSave, setDisableSave] = useState<number[]>([])

  const saveDisabled = (index: number) => {
    setDisableSave([...disableSave, index])
  }

  const saveNotDisabled = (index: number) => {
    const updDisableSave = JSON.parse(JSON.stringify(disableSave))
    _.remove(updDisableSave, (indexString) => indexString === index)
    setDisableSave(updDisableSave)
  }

  const handleOnAdd = () => {
    setHours([...hours, {from: { name: '9:00am', value: '9:00am' }, to: { name: '5:00pm', value: '5:00pm' }}])
    setKeys([...keys, uuidv4()])
  }

  const updateHours = (type: HourType, index: number, hour: Option) => {
    const updatedHours: Hours[] = JSON.parse(JSON.stringify(hours))
    if(type === 'from') updatedHours[index].from = hour
    else updatedHours[index].to = hour
    setHours(updatedHours)
  }

  const deleteAdditionalHours = (index: number) => {
    const updatedHours: Hours[] = JSON.parse(JSON.stringify(hours))
    updatedHours.splice(index, 1)
    setHours(updatedHours)
    const updatedKeys = JSON.parse(JSON.stringify(keys))
    updatedKeys.splice(index, 1)
    setKeys(updatedKeys)
  }

  const handleSetDate = (newDate: string) => {
    setFriendlyDate(newDate)
    //check if the date is a future date in mm/dd/yyyy or m/d/yyyy format
    const validateFormat = /\d{1,2}\/\d{1,2}\/\d{4}/
    const date = new Date(newDate)
    if(validateFormat.test(newDate)){
      if(isValid(date) && (isFuture(date) || isToday(date))) { 
        setDate(format(new Date(newDate), 'yyyy-MM-dd')) 
        setErrorMessage('') 
      }
      else { 
        setDate("") 
        setErrorMessage('Please select a date not in the past') 
      }
    }
    else {
      setDate("") 
      setErrorMessage('') 
    }
  }

  const reset = () => {
    setHours([{from: { name: '9:00am', value: '9:00am' }, to: { name: '5:00pm', value: '5:00pm' }}])
    setUnavailable(false)
    setDate("")
    setFriendlyDate("")
    setErrorMessage("")
  }

  const handleCancel = () => {
    reset()
    handleOnCancel()
  }

  const handleSave = () => {
    reset()
    handleOnSave(date, hours, unavailable)
  }

  const toggleUnavailable = () => {
    setUnavailable(!unavailable)
    setHours([{from: { name: '9:00am', value: '9:00am' }, to: { name: '5:00pm', value: '5:00pm' }}])
  }

  return (
    <>
      <TextInput label={"Date"} type={"date"} value={friendlyDate} minDate={format(new Date(), 'yyyy-MM-dd')} hint={errorMessage} error={errorMessage !== ""} onChange={handleSetDate} RightIcon={CalendarBlank} placeholder={"MM/DD/YYYY"} className={styles.datePicker}/>
      {date !== "" && 
        <>
          <Body size='md' weight='bold' color='primary'>{"What hours are you available?"}</Body>
          {!unavailable ?
            <>
              <SelectDateOverrideHours
                startHour={hours[0].from}
                endHour={hours[0].to}
                index={0}
                onChange={updateHours}
                onAdd={handleOnAdd}
                buttonIcon={'plus'}
                saveDisabled={saveDisabled}
                saveNotDisabled={saveNotDisabled}
              />
              {hours.length > 1 && 
                hours.slice(1).map((hourSet, index) => (
                  <SelectDateOverrideHours 
                    key={keys[index+1]}
                    startHour={hourSet.from} 
                    endHour={hourSet.to} 
                    index={index+1} 
                    onChange={updateHours} 
                    onDelete={deleteAdditionalHours}
                    buttonIcon={'trash'}
                    saveDisabled={saveDisabled}
                    saveNotDisabled={saveNotDisabled}
                  />
                ))
              }
            </>
          :
            <Body size='sm' color='secondary' className={styles.unavailable}>Unavailable</Body>
          }
          <Switch label={"I'm Unavailable"} active={unavailable} onToggle={toggleUnavailable} className={styles.unavailableSwitch}/>
        </>
      }
      <ButtonGroup className={styles.cancelAndAddButtons} align='start'>
        <Button onClick={() => {handleCancel()}} type='secondary-gray' size='medium' label={"Cancel"}/>
        <Button onClick={() => {handleSave()}} type='primary' size='medium' label={"Add Date"} disabled={date === "" || disableSave.length > 0}/>
      </ButtonGroup>
    </>
  )
}
