import { Pencil } from "phosphor-react";
import { useEffect, useMemo, useState } from "react";
import { Button, ButtonGroup } from "../../../components/button/Button";
import { YouIcon } from "../../../components/customIcons/CustonIcons";
import { TextInput } from "../../../components/textInput/TextInput";
import { Body } from "../../../components/typography/body/Body";
import { Heading } from "../../../components/typography/heading/Heading";
import { LoveLetter as LoveLetterType } from "../../../lib/interfaces/carePlan";
import { format, isToday, isWithinInterval, isYesterday, startOfToday, subDays } from "date-fns"
import styles from "./style.module.css";
import { useProvider } from "../../../lib/hooks/useProvider";
import { useBaseContext } from "../../_base/Base";
import { getLocalDateTime } from "../../../lib/util/date";

interface LoveLetterProps {
  loveLetter?: LoveLetterType
  onSave: (loveletter: LoveLetterType) => Promise<boolean>
}

export const LoveLetter = ({ loveLetter, onSave }: LoveLetterProps) => {
  const [draftLetter, setDraftLetter] = useState<string>(loveLetter?.value || '')
  const [editing, setEditing] = useState(false)
  const [modifiedBy, setModifiedBy] = useState<string>()
  const { getProvider } = useProvider()
  const { user } = useBaseContext()

  useEffect(() => {
    if (loveLetter?.modifiedBy)
    getProvider(loveLetter.modifiedBy).then(provider => {setModifiedBy(provider.name)})
  }, [loveLetter])

  const modifiedOn = useMemo(() => {
    if (loveLetter?.modifiedBy && loveLetter.modifiedOn) {
      const date = getLocalDateTime(loveLetter.modifiedOn)
      const time = format(date, 'h:mm a')
      let day: string;
      if (isToday(date))
        day = 'Today'
      else if (isYesterday(date))
        day = 'Yesterday'
      else if (isWithinInterval(date, { start: subDays(startOfToday(), 6), end: startOfToday() }))
        day = format(date, 'eeee')
      else
        day = format(date, 'M/d/yy')

      return `${day} ${time}`
    }
  }, [loveLetter])

  const handleCancel = () => {
    setEditing(false);
    setDraftLetter(loveLetter?.value || '');
  };

  const handleSave = async () => {
    const result = await onSave({
      value: draftLetter,
      modifiedBy: user?.sub as string,
    })
    if (result) {
      setEditing(false)
    } else { }
  };


  return (
    <div className={styles.loveLetterSection}>
      <div className={styles.loveLetterBox}>
        <div className={styles.header}>
          <div className={styles.headerTitle}>
            <YouIcon size={52} />
            <div>
              <Heading type="02" style={{ color: "white" }}>
                Love Letter
              </Heading>
              <Body size="md" weight="regular" color="white" style={{ opacity: "0.7" }}>
                Write a love letter to the patient
              </Body>
            </div>
          </div>
          {!editing && (
            <Button
              Icon={Pencil}
              onClick={() => setEditing(true)}
              size="small"
              type="secondary"
            />
          )}
        </div>

        {editing ? (
          <>
            <div className={styles.contents}>
              <div className={styles.inputContentBox}>
                <TextInput
                  placeholder="Write a love letter to the patient"
                  value={draftLetter}
                  onChange={setDraftLetter}
                  multiline
                  minRows={5}
                  maxRows={5}
                />

                <ButtonGroup className={styles.buttonGroup} align="end">
                  <Button
                    label="Cancel"
                    onClick={handleCancel}
                    type="secondary-gray"
                  />
                  <Button label="Save" onClick={handleSave} />
                </ButtonGroup>
              </div>
            </div>
          </>
        ) : (draftLetter ? (
          <>
            <div className={styles.savedLetter}>
              <Body size="md" style={{ color: "white" }}>
                {draftLetter}
              </Body>
            </div>
            <div className={styles.submissionDateTime}>
              <Body weight="bold" size="sm" style={{ color: "white" }}>
                {`Edited ${modifiedOn} ${modifiedBy}`}
              </Body>
            </div>
          </>
        ) : (
          <> </>
        ))}
      </div>
    </div>
  );
};
