import _ from "lodash";
import { Conversation } from "@twilio/conversations";
import { CaretDown, CaretUp, X } from "phosphor-react";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  AvatarLabelGroup,
  User,
} from "../../../components/avatarLabelGroup/AvatarLabelGroup";
import { Button, ButtonGroup } from "../../../components/button/Button";
import { useChats } from "../../../lib/hooks/useChats";
import { Resource } from "../../../lib/interfaces/resource";
import {
  DiscussedPatient,
  Message,
  Participant,
} from "../../../lib/interfaces/messaging";
import { UserInfo } from "../../../lib/interfaces/user";
import { BaseContext } from "../../../lib/context/context";
import { logError } from "../../../lib/util/logger";
import { SelectedChat } from "../../../pages/messages/Messages";
import { ChatHeader } from "../chatHeader/ChatHeader";
import { ChatInput } from "../chatInput/ChatInput";
import { ChatThread } from "../chatThread/ChatThread";
import { AlertBanner } from "../../../components/alertBanner/AlertBanner";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import styles from "./style.module.css";
import { ResourceDetails } from "../../carePlan/resourceDetails/ResourceDetails";
import { Drawer } from "../../../components/drawer/Drawer";
import { v4 as uuidv4 } from "uuid";
import { format } from "date-fns";

type messageAddedType = ( message: Message ) => void;

interface ChatViewProps {
  chat: SelectedChat;
  message?: string;
  popout?: boolean;
  handleMessageAdded?: messageAddedType
}

export const ChatView = ({ chat, message, popout, handleMessageAdded }: ChatViewProps) => {
  const {
    user,
    twilio,
    getChatMessages,
    sendMessage,
    formatTwilioMessage,
    updateLastReadMessageIndex,
    getChatById,
    getConversationById,
  } = useChats();
  const [messages, setMessages] = useState<Message[]>([]);
  const [body, setBody] = useState(message || "");
  const [resources, setResources] = useState<Resource[]>([]);
  const [defaultResourceIds, setDefaultResourceIds] = useState<string[]>([]);
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [popoutMinimized, setPopoutMinimized] = useState(false);
  const [expandedResource, setExpandedResource] = useState<Resource | string>();
  const [sendMessageFail, setSendMessageFail] = useState<boolean>(false);
  const [updChat, setUpdChat] = useState<SelectedChat>();
  const { openPopoutChat, closePopoutChat } = useContext(BaseContext);
  const client = useRef<Conversation>();
  const chatIdRef = useRef<string>(chat.id);
  const messagesRef = useRef<Message[]>([]);
  const threadEndRef = useRef<HTMLDivElement>(null);

  const controller = new AbortController();

  useEffect(() => {
    return () => controller.abort();
  }, []);

  useEffect(() => {
    message ? setBody(message) : setBody("");
  }, [message]);

  useEffect(() => {
    chatIdRef.current = chat.id;
    initialize();
    // updChat will contain the most up to date chat
    // i.e. if a provider joins a chat and is now a participant, we will update updChat to reflect that
    setUpdChat(chat);
    return () => {
      setBody("");
      setResources([]);
    };
  }, [chat]);

  useEffect(() => {
    // only set up the messageAdded listener if the user is part of the chat
    if (updChat && updChat.participants.some((p) => p.id === user?.sub)) {
      getConversationById(chat.id)
        .then((twilioClient) => {
          client.current = twilioClient;
          client.current?.on("messageAdded", async (message) => {
            if (message.conversation.sid === chatIdRef.current) {
              formatTwilioMessage(message).then((_message) => {
                if (
                  !messagesRef.current
                    .map((message) => message.id)
                    .includes(_message.id)
                ) {
                  messagesRef.current.unshift(_message);
                  if(handleMessageAdded){
                    handleMessageAdded(_message);
                  }
                  setMessages(_.clone(messagesRef.current));
                }
              });
            }
          });
        })
        .catch((err) => logError('ChatView getConversationById: ', {chatId: chat.id} , err));
    }
    return () => {
      client.current?.removeAllListeners();
    };
  }, [updChat, twilio]);

  useEffect(() => {
    scrollToBottom();
    updateReadHorizon();
    //@ts-ignore
    setDefaultResourceIds(
      messages
        ?.filter((message: any) => message?.resource?.id)
        .map((message: any) => message.resource.id)
    );
    // setDefaultResourceIds(messages.map(message => message?.resource?.id).filter(resourceId => typeof resourceId !== 'undefined'))
    return () => setLoadingMessages(false);
  }, [messages]);

  const initialize = async () => {
    setLoadingMessages(true);
    setSendMessageFail(false);
    messagesRef.current = await getChatMessages(chat.id, undefined, controller);
    if (chat.id === chatIdRef.current) setMessages(messagesRef.current);
  };

  const handleOpenPopout = () => {
    if (openPopoutChat) openPopoutChat(chat);
  };

  const handleClosePopout = () => {
    if (closePopoutChat) closePopoutChat(chat);
  };

  const handleMinimizePopout = () => {
    setPopoutMinimized(!popoutMinimized);
  };

  const patientParticipant = useMemo(
    () =>
      chat.participants.filter(
        (participant) => participant.title.toUpperCase() === "PATIENT"
      )[0],
    [chat]
  );

  const handleSendMessage = async (body: string, resources: Resource[]) => {
    if (body.trim().length > 0 || resources.length > 0) {
      const res = await sendMessage(chat.id, body.trim(), resources);
      if (!res || res.status >= 200 || res.status < 300) {
        //updateMessageThreadAfterSuccess();
        //after successfully sending the message, check if the user was originally part of the chat
        if (updChat && !updChat.participants.some((p) => p.id === user?.sub)) {
          //if the user wasn't originally part of the chat, we need to get the new version of the chat that includes them as a participant
          const c = await getChatById(chat.id);
          setUpdChat(c);
          //manually add the user's message to the messages (since the messageAdded listener isn't set up yet)
          const _message = {
            id: uuidv4(), //temporary id
            chatId: chat.id,
            author: `provider_${user ? user.sub : ""}`,
            body: body.trim(),
            metadata: {},
            createdDate:
              format(new Date(), "yyyy-MM-dd") +
              "T" +
              format(new Date(), "HH:mm:ss") +
              "Z",
            index: messages.length,
          };
          messagesRef.current.unshift(_message);
          setMessages(_.clone(messagesRef.current));
        }
      }
      return res;
    } else return await Promise.resolve();
  };

  const updateReadHorizon = () => {
    if (updChat) {
      const userAsMember = updChat.participants.filter(
        (participant) => participant.id === user?.sub
      )[0];
      if (
        userAsMember &&
        messages.length > 0 &&
        updChat.id === chatIdRef.current
      )
        updateLastReadMessageIndex(
          updChat.id,
          userAsMember.participantId,
          messages[0].index
        );
    }
  };

  const scrollToBottom = () => {
    if (threadEndRef.current) threadEndRef.current.scrollIntoView();
  };

  return (
    <div
      className={clsx(
        styles.chatView,
        popout && [styles.popout, popoutMinimized && styles.minimized]
      )}
    >
      {popout ? (
        <PopoutHeader
          participants={updChat ? updChat.participants : chat.participants}
          discussedPatient={chat.regarding}
          minimized={popoutMinimized}
          onCloseClick={handleClosePopout}
          onMinimizeClick={handleMinimizePopout}
        />
      ) : (
        <ChatHeader
          participants={updChat ? updChat.participants : chat.participants}
          discussedPatient={chat.regarding}
          onPopoutClick={handleOpenPopout}
        />
      )}
      <div className={clsx(styles.chatContainer, popout && styles.popout)}>
        <ChatThread
          loading={loadingMessages}
          messages={messages}
          participants={chat.participants}
          onResourceExpand={setExpandedResource}
        />
      </div>
      {sendMessageFail && (
        <AlertBanner
          message={`Could not send message. Please try again.`}
          type="danger"
        />
      )}
      {patientParticipant && (
        <AlertBanner
          data-dd-privacy="mask" 
          message={`Patient ${patientParticipant.name} is in this chat`}
        />
      )}
      <ChatInput
        onSendMessage={handleSendMessage}
        onMessageFail={setSendMessageFail}
        body={body}
        resources={resources}
        defaultResourceIds={defaultResourceIds}
        onBodyChange={setBody}
        onResourceChange={setResources}
        onResourceExpand={setExpandedResource}
      />

      <Drawer
        title="Resource"
        visible={Boolean(expandedResource)}
        onClose={() => setExpandedResource(undefined)}
      >
        <ResourceDetails
          resource={
            typeof expandedResource !== "string" ? expandedResource : undefined
          }
          resourceId={
            typeof expandedResource === "string" ? expandedResource : undefined
          }
        />
      </Drawer>
    </div>
  );
};

interface PopoutHeaderProps {
  participants: Participant[];
  discussedPatient?: DiscussedPatient;
  minimized: boolean;
  onCloseClick: () => void;
  onMinimizeClick: () => void;
}

const PopoutHeader = ({
  participants,
  discussedPatient,
  minimized,
  onMinimizeClick,
  onCloseClick,
}: PopoutHeaderProps) => {
  const { user } = useContext(BaseContext);
  const history = useHistory();
  let userIncluded = false;

  const participantsInfo: UserInfo[] = participants.map((participant) => {
    const { firstName, lastName, photo, title, id } = participant;
    const name = [firstName, lastName].join(" ");
    return { name, firstName, lastName, title, id, photo };
  });

  const participantsInfoExcludingUser = participantsInfo.filter(
    (participantInfo) => participantInfo.id !== user?.sub
  );
  if (participantsInfoExcludingUser.length !== participantsInfo.length)
    userIncluded = true;

  const handleDiscussedPatientClick = () => {
    history.push(`/patient/${discussedPatient?.id}/careplan`);
  };

  // const userTitles = useMemo(() => [...participantsInfo.map((user: User) => user.title)].filter(Boolean).join(', '), [participantsInfo])
  const userTitles = useMemo(
    () =>
      [
        userIncluded ? "You" : null,
        ...participantsInfoExcludingUser.map((user: User) => user.title),
      ]
        .filter(Boolean)
        .join(", "),
    [participantsInfoExcludingUser]
  );

  const titleOverride: JSX.Element | undefined = discussedPatient ? (
    <div className={styles.titleOverride}>
      {userTitles}
      <div>
        {`Discussing `}
        <a
          className={styles.discussedPatient}
          onClick={handleDiscussedPatientClick}
        >
          {`${discussedPatient.firstName} ${discussedPatient.lastName} (Patient)`}
        </a>
      </div>
    </div>
  ) : undefined;

  const patientParticipant = useMemo(
    () =>
      participants.filter(
        (participant) => participant.title.toUpperCase() === "PATIENT"
      )[0],
    [participants]
  );

  const handleAvatarClick = () => {
    if (patientParticipant) {
      history.push(`/patient/${patientParticipant.id}/careplan`);
    }
  };

  return (
    <div className={styles.popoutHeader}>
      <AvatarLabelGroup
        users={participantsInfoExcludingUser}
        size="lg"
        titleOverride={titleOverride}
        userIncluded={userIncluded}
        onClick={handleAvatarClick}
      />
      <ButtonGroup>
        <Button
          type="secondary-gray"
          size="small"
          Icon={minimized ? CaretUp : CaretDown}
          onClick={onMinimizeClick}
        />
        <Button
          type="secondary-gray"
          size="small"
          Icon={X}
          onClick={onCloseClick}
        />
      </ButtonGroup>
    </div>
  );
};
