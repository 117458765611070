import { useContext, useMemo, useState } from "react";
import { Heading } from "../../components/typography/heading/Heading";
import { SearchSelect } from "../../components/searchSelect/SearchSelect";
import { Button } from "../../components/button/Button";
import { Modal } from "../../components/modal/Modal";
import { FiltersForm } from "../../containers/listOfPatients/filtersForm/FiltersForm";
import { Option } from "../../lib/interfaces/input";
import {
  FilterOptions,
  SearchOption,
} from "../../lib/interfaces/listOfPatients";
import { PatientList } from "../../containers/listOfPatients/patientList/PatientList";
import { PatientCardInfo } from "../../lib/interfaces/user";
import { MagnifyingGlass, FunnelSimple } from "phosphor-react";
import { Spinner } from "../../components/spinner/Spinner";
import styles from "./style.module.css";
import { Drawer } from "../../components/drawer/Drawer";
import { NewAppointment } from "../../containers/schedule/newAppointment/NewAppointment";
import { AlertContext, BaseContext } from "../../lib/context/context";

export const ListOfPatients = () => {
  const { allPatients } = useContext(BaseContext);
  const { pushAlert } = useContext(AlertContext);

  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [sortingOption, setSortingOption] = useState<Option>({
    name: "Patient Last Name - A to Z",
    value: "patient-alph",
  });
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    providers: [],
  });
  const [
    selectedSearchOption,
    setSelectedSearchOption,
  ] = useState<SearchOption>({ patient: { name: "", value: "" } });
  const [sortDisabled, setSortDisabled] = useState(false);
  const [noAppointmentOption, setNoAppointmentOption] = useState(false);
  const [compassPlusOption, setCompassPlusOption] = useState(false);
  const [applyFilterDisabled, setApplyFilterDisabled] = useState(true);
  const [clearDisabled, setClearDisabled] = useState(true);
  const [chosenPatient, setChosenPatient] = useState<PatientCardInfo>();
  const [refreshPatientCard, setRefreshPatientCard] = useState<string>();

  const handleFilterButtonClick = () => {
    setShowFiltersModal(true);
  };

  const handleFiltersApply = (
    selectedSortingOption: Option,
    selectedFilterOptions: FilterOptions,
    noAppointmentOption: boolean,
    compassPlusOption: boolean,
  ) => {
    setSortingOption(selectedSortingOption);
    setFilterOptions(selectedFilterOptions);
    setNoAppointmentOption(noAppointmentOption);
    setCompassPlusOption(compassPlusOption);
    setApplyFilterDisabled(false);
    setClearDisabled(false);
    setShowFiltersModal(false);
  };

  const handleClearAll = () => {
    setSortingOption({
      name: "Patient Last Name - A to Z",
      value: "patient-alph",
    });
    setFilterOptions({ providers: [] });
    setNoAppointmentOption(false);
    setApplyFilterDisabled(true);
    setClearDisabled(true);
    setShowFiltersModal(false);
    setCompassPlusOption(false);
  };

  const handleSelectedPatientsChange = (selectedPatient: Option) => {
    setSelectedSearchOption({
      patient: selectedPatient,
    });
  };

  const handleOnApplyFilterClick = () => {
    setApplyFilterDisabled(false);
    setClearDisabled(false);
  };

  const handleEmptyList = (patientList: PatientCardInfo[]) => {
    if (patientList.length === 0) {
      setSortDisabled(true);
    } else {
      setSortDisabled(false);
    }
  };

  const handleNewAppointmentBook = (bookAnother?: boolean) => {
    if (!bookAnother) {
      setChosenPatient(undefined);
      pushAlert("Appointment successfully created!", "success");
    }
    setRefreshPatientCard(chosenPatient?.id);
  };

  const handleNewAppointmentExit = () => {
    setChosenPatient(undefined);
  };

  const onNewApptClick = (patient: PatientCardInfo) => {
    setChosenPatient(patient);
  };

  const resetRefresh = () => {
    setRefreshPatientCard(undefined);
  };

  const sortByLastName = (
    patientA: PatientCardInfo,
    patientB: PatientCardInfo
  ): number => {
    return patientA.lastName.localeCompare(patientB.lastName);
  };

  const numFilters = useMemo(() => {
    let count = 0;
    if (filterOptions.providers.length > 0) count++;
    if (noAppointmentOption) count++;
    if (compassPlusOption) count++;
    if (sortingOption.value === "patient-alph-rev") count++;
    return count;
  }, [filterOptions, noAppointmentOption, compassPlusOption, sortingOption]);

  return (
    <div>
      <div className={styles.headerAndBanner}>
        <div className={styles.header}>
          <Heading type="02">Patients</Heading>
          <div className={styles.searchAndFilter}>
            <SearchSelect
              placeholder="Search"
              LeftIcon={MagnifyingGlass}
              width={400}
              value={selectedSearchOption.patient}
              onChange={handleSelectedPatientsChange}
              options={
                allPatients
                  ? allPatients.sort(sortByLastName).map((patient) => ({
                      avatarUrl: patient.photo || "",
                      name: patient.firstName + " " + patient.lastName,
                      value: patient.id,
                      premiumMember:
                      patient?.settings?.General?.Membership === "Premium",
                    }))
                  : []
              }
              loading={!allPatients}
              className={styles.search}
            />
            <Button
              onClick={() => {
                handleFilterButtonClick();
              }}
              Icon={FunnelSimple}
              label={numFilters > 0 ? `Filters (${numFilters})` : "Filters"}
              type="secondary-gray"
            />
          </div>
        </div>
      </div>

      {!allPatients ? (
        <Spinner />
      ) : (
        <PatientList
          sortingOption={sortingOption}
          filterOptions={filterOptions}
          searchOption={selectedSearchOption}
          handleEmptyList={handleEmptyList}
          noAppointmentOption={noAppointmentOption}
          compassPlusOption={compassPlusOption}
          onNewApptClick={onNewApptClick}
          refreshPatientCard={refreshPatientCard}
          resetRefresh={resetRefresh}
        />
      )}

      <Modal
        visible={showFiltersModal}
        onCloseModal={() => setShowFiltersModal(false)}
        title="Filter"
      >
        <FiltersForm
          sortingOption={sortingOption}
          filterOptions={filterOptions}
          onClearAll={handleClearAll}
          onApply={handleFiltersApply}
          sortDisabled={sortDisabled}
          noAppointmentOption={noAppointmentOption}
          compassPlusOption={compassPlusOption}
          handleNoAppointmentToggleChange={handleOnApplyFilterClick}
          handleCompassPlusToggleChange={handleOnApplyFilterClick}
          applyFilterDisabled={applyFilterDisabled}
          clearDisabled={clearDisabled}
          handleOnFilterClick={handleOnApplyFilterClick}
        />
      </Modal>

      <Drawer
        title={"New Appointment"}
        onClose={() => {
          handleNewAppointmentExit();
        }}
        visible={Boolean(chosenPatient)}
        className={styles.drawer}
      >
        <NewAppointment
          onNewAppointmentBooked={handleNewAppointmentBook}
          chosenPatient={chosenPatient}
        />
      </Drawer>
    </div>
  );
};
