import { useMemo } from "react";
import { UserInfo } from "../../lib/interfaces/user";
import { AvatarLabelGroup } from "../avatarLabelGroup/AvatarLabelGroup";
import { Checkbox } from "../checkbox/Checkbox";
import { Radio } from "../radio/Radio";
import { v4 as uuid } from "uuid"
import styles from "./style.module.css";

interface InteractiveListProps {
  items: UserInfo[];
  value?: UserInfo | UserInfo[];
  onChange: (value: UserInfo | UserInfo[]) => void;
}

export const InteractiveList = ({
  items,
  value,
  onChange,
}: InteractiveListProps) => {
  const selectedItemId = useMemo(() => {
    if (!Array.isArray(value)) return value ? [value.id] : [];
    else return value.map((item) => item.id);
  }, [value]);

  const handleItemSelect = (selected: boolean, item: UserInfo) => {
    if (Array.isArray(value)) {
      if (selected && !selectedItemId.includes(item.id))
        onChange(value.concat(item));
      else if (!selected && selectedItemId.includes(item.id))
        onChange(value.filter((_item) => _item.id !== item.id));
    } else {
      onChange(item);
    }
  };

  return (
    <div>
      {items.map((item) => (
        <Item
          user={item}
          key={uuid()}
          selected={selectedItemId.includes(item.id)}
          onSelect={(selected: boolean) => handleItemSelect(selected, item)}
          type={Array.isArray(value) ? "checkbox" : "radio"}
        />
      ))}
    </div>
  );
};

interface ItemProps {
  user: UserInfo;
  selected?: boolean;
  onSelect: (selected: boolean) => void;
  type?: "radio" | "checkbox";
}

export const Item = ({
  user,
  selected = false,
  onSelect,
  type = "checkbox",
}: ItemProps) => (
  <div
    className={styles.item}
    onClick={(e) => {
      e.stopPropagation();
      onSelect(!selected);
    }}
  >
    <AvatarLabelGroup size="md" data-dd-privacy="mask" users={[user]} />
    {type === "checkbox" ? (
      <Checkbox checked={selected} onToggle={() => onSelect(!selected)} />
    ) : (
      <Radio selected={selected} onSelect={() => onSelect(!selected)} />
    )}
  </div>
);
