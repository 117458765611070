import { useEffect, useState } from "react";

import { Heading } from "../../../components/typography/heading/Heading";
import { Spinner } from "../../../components/spinner/Spinner";
import { RecordsApprovalCard } from "../../../components/recordsApprovalsCard/RecordsApprovalCard";
import { PatientInfo } from "../../../lib/interfaces/user";
import { Option } from "../../../lib/interfaces/input";
import { usePatient } from "../../../lib/hooks/usePatient";

import styles from "./style.module.css";

interface RequiredApprovalsProps {
  patient: PatientInfo;
}

const statusOptions = [
  { name: "Failed", value: "ERROR" },
  { name: "Approved", value: "VERIFIED" }
];

const DEFAULT_STATUS = { name: "Not Verified/Not Initiated", value: "NOT INITIATED" }

const status = [
  ...statusOptions,
  ...[DEFAULT_STATUS],
  { name: "Initiated", value: "IN PROGRESS" }
]

export const RequiredApprovals = ({ patient }: RequiredApprovalsProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [insuranceStatus, setInsuranceStatus] = useState<Option>(DEFAULT_STATUS);
  const [IDStatus, setIDStatus] = useState<Option>(DEFAULT_STATUS);
  const { getInsurancestatus } = usePatient();
  const [initialInsuranceStatus, setinitialInsuranceStatus] = useState<string>('');
  const [initialIDStatus, setinitialIDStatus] = useState<string>('');
  const { getPatient } = usePatient();
  const setInitialStatus = async () => {
    setLoading(true);
    //initial ID verification status is from patient profile settings > General > IdentityVerified
    const patientInfo = await getPatient(patient.id);
    const idStatus = patientInfo.settings?.General?.IdentityVerified;
    if(idStatus){
      setinitialIDStatus(idStatus);
    const idVerificationStatus = getInitialStatus(idStatus);
    setIDStatus(idVerificationStatus);
  }
  //Initial insurance verification status is a GET call
    const insuranceStatusRes = await getInsurancestatus(patient.id);
    if (insuranceStatusRes) {
      setinitialInsuranceStatus(insuranceStatusRes);
      const insuranceVerificationStatus = getInitialStatus(insuranceStatusRes);
      setInsuranceStatus(insuranceVerificationStatus);
    }
    setLoading(false);
  };

  //Find the verification value from the list of possible status
  const getStatusValue = (resStatus : string) => status.find((states:Option) => states.value === resStatus);

  //Function to format the initial status
  const getInitialStatus = (resStatus : string) => ({  
    name: getStatusValue(resStatus)?.name || "Not Verified/Not Initiated",
    value: resStatus || "NOT INITIATED"
  });

  // Run setInitialStatus only once when the component mounts
  useEffect(() => {
    setInitialStatus();
  }, []);

  const verificationCards = [
    {
      cardType: "insurance",
      status: insuranceStatus,
      handleStatusChange: setInsuranceStatus,
      initialStatus: initialInsuranceStatus
    },
    {
      cardType: "identification",
      status: IDStatus,
      handleStatusChange: setIDStatus,
      initialStatus: initialIDStatus
    }
  ];

  return (
    <div>
      <Heading className={styles.heading} type="02">
        Required Approvals
      </Heading>
      <div className={styles.cardsContainer}>
      {!loading ? (
        verificationCards.map(card => <RecordsApprovalCard
          key={card.cardType}
          cardType={card.cardType}
          status={card.status}
          statusOptions={statusOptions}
          handleStatusChange={card.handleStatusChange}
          patient={patient}
          initialStatus={card.initialStatus}
        />)
      ) : (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      )}
      </div>
    </div>
  )
}
