import axios, { AxiosResponse } from 'axios'
import { Author, ID, Visit } from '../interfaces/notes';
import { CarePlanNote } from '../interfaces/carePlan'

export interface ResponseData {
  author?: Author,
  contactId?: string,
  dateOfService?: string,
  detail?: string,
  displayName?: string,
  ids: ID[],
  status?: string,
  type?: string,
  visit?: Visit
}

interface NotesResponse extends AxiosResponse {
  data: ResponseData[]
}

export const fetchNotes = async (userId: string, controller?: AbortController) => {
  const result = await axios.get<NotesResponse, NotesResponse>(
    `/patient/notes?userId=${userId}`,
    {
      signal:  controller ? controller.signal : undefined
    })
    .catch(err => { 
      if(axios.isCancel(err)) return {data: 'cancelled'}
      else return err
    })
  return result.data
}

export const fetchCarePlanNotes = async (userId: string, controller?: AbortController) => {
  const result = await axios
    .get(`/careplan/notes?userId=${userId}`, {
      signal: controller ? controller.signal : undefined,
    })
    .catch((err) => {
      if (axios.isCancel(err)) return { data: "cancelled" }
      else return err
    })

  return result.data
}

export const fetchCarePlanNote = async (userId: string, carenoteId: string) => {
  const result = await axios
    .get(`/careplan/notes?userId=${userId}&carenoteId=${carenoteId}`)
    .catch((err) => err)

  return result.data as CarePlanNote[]
}

export interface PostCarePlanNotePayload {
  id?: string
  userId: string
  description: string
  title: string
}

export const postCarePlanNote = async (note: PostCarePlanNotePayload) => {
  const result = await axios
    .post("/careplan/notes", {
      ...note,
    })
    .catch((err) => err)

  return result
}

export interface PostCarePlanNoteAttachmentPayload {
  carenoteId: string
  name: string
  file: string
}

export const postCarePlanNoteAttachment = async (attachment: PostCarePlanNoteAttachmentPayload) => {
  const result = await axios
    .post("/careplan/notes-attach", attachment)
    .catch((err) => err)

  return result
}

export interface DeleteCarePlanNoteAttachmentPayload {
  carenoteId: string
  name: string
}

export const deleteCarePlanNoteAttachment = async (targetAttachment: DeleteCarePlanNoteAttachmentPayload) => {
  const result = await axios
    .delete("/careplan/notes-attach", {
      data: targetAttachment,
    })
    .catch((err) => err)

  return result
}
