import { useOktaAuth } from "@okta/okta-react";
import { useContext, useRef } from "react";
import providerAPI from "../apis/provider";
import { v4 as uuid } from "uuid";
import { ProviderInfo } from "../interfaces/user";
import { AlertContext } from "../context/context";
import ProviderType from "../models/providerType";

interface Ref {
  providers: { [id: string]: ProviderInfo };
  pendingCalls: { [id: string]: Promise<any> };
}

export interface typeInterface {
  physician: string;
  careNavigator: string;
  insideSpecialist: string;
  outsideSpecialist: string;
  coach: string;
  triage: string;
  medAssistant: string;
  admin: string;
  coachPool: string;
  nursePractitioner: string;
}

export const types: typeInterface = {
  physician: "Primary Care Physician",
  careNavigator: "Care Navigator",
  insideSpecialist: "Specialist (Inside)",
  outsideSpecialist: "Specilaist (Outside)",
  coach: "Health Coach",
  triage: "Triage Nurse",
  medAssistant: "Medical Assistant",
  admin: "Site Administrator",
  coachPool: "Coach Pool",
  nursePractitioner: "Nurse Practitioner"
};

export const useProvider = () => {
  const { oktaAuth } = useOktaAuth();
  const { pushAlert } = useContext(AlertContext);
  const accessToken = oktaAuth.getAccessToken();
  const ref = useRef<Ref>({ providers: {}, pendingCalls: {} });

  const getAllProviders = async () => {
    if (accessToken) {
      const _providers = await providerAPI.fetchAll();
      if (!_providers) {
        pushAlert("Failed to get providers", "danger");
        return [];
      }
      //@ts-ignore
      const providers: ProviderInfo[] = _providers.map((provider) => {
        const formattedProvider = {
          ...provider,
          name: `${provider.firstName} ${provider.lastName}`,
          providerId: provider.id,
          id: provider.id,
          title: types[provider.type as keyof typeInterface],
        };
        ref.current.pendingCalls[formattedProvider.id] = Promise.resolve(
          formattedProvider
        );

        return formattedProvider;
      });
      return providers;
    } else return [];
  };

  const getNonEpicProviders = async () => {
    if (accessToken) {
      const _providers = await providerAPI.fetchAll();
      if (!_providers) {
        pushAlert("Failed to get non-epic providers", "danger");
        return [];
      }
      const providers: ProviderInfo[] = [];
      //@ts-ignore
      _providers.map((provider) => {
        //if a provider doesn't have a ehrId, they are non epic
        if (provider.ehrId === "") {
          const formattedProvider = {
            ...provider,
            name: `${provider.firstName} ${provider.lastName}`,
            providerId: provider.id,
            id: provider.id,
            title: types[provider.type as keyof typeInterface],
          };
          ref.current.pendingCalls[formattedProvider.id] = Promise.resolve(
            formattedProvider
          );

          providers.push(formattedProvider);
        }
      });
      return providers;
    } else return [];
  };

  const getSpecialistAndHealthCoachProviders = async () => {
    if (accessToken) {
      const _providers = await providerAPI.fetchAll();
      if (!_providers) {
        pushAlert("Failed to get specialists and health coaches", "danger");
        return [];
      }
      const providers: ProviderInfo[] = [];
      //@ts-ignore
      _providers.map((provider) => {
        //if a provider doesn't have a hoagProviderId, they are non epic
        if (
          provider.type === ProviderType.InsideSpecialist ||
          provider.type === ProviderType.OutsideSpecialist ||
          provider.type === ProviderType.Coach
        ) {
          const formattedProvider = {
            ...provider,
            name: `${provider.firstName} ${provider.lastName}`,
            providerId: provider.id,
            id: provider.id,
            title: types[provider.type as keyof typeInterface],
          };
          ref.current.pendingCalls[formattedProvider.id] = Promise.resolve(
            formattedProvider
          );

          providers.push(formattedProvider);
        }
      });
      return providers;
    } else return [];
  };

  const getProvider = async (
    id: string,
    newCall?: boolean
  ): Promise<ProviderInfo> => {
    // const userId = user?.preferred_username
    if (accessToken) {
      if (!ref.current.pendingCalls[id] || newCall) {
        ref.current.pendingCalls[id] = providerAPI.fetch(id);
      }

      const provider = await ref.current.pendingCalls[id];

      if (provider) {
        const formattedProvider: ProviderInfo = {
          ...provider,
          name: `${provider.firstName} ${provider.lastName}`,
          providerId: provider.id,
          id: provider.id,
          ehr: provider.ehr,
          ehrId: provider.ehrId,
          title: types[provider.type as keyof typeInterface],
        };
        return formattedProvider;
      } else
        return {
          id: uuid(),//TODO this might be misleading because chats are being loaded on behalf of a random provider
          firstName: "",
          lastName: "",
          name: "",
          title: "",
          primarySpecialty: "",
        };
    }
    return {
      id: uuid(),
      firstName: "",
      lastName: "",
      name: "",
      title: "",
      primarySpecialty: "",
    };
  };

  const getProviderById = async (providerId: string): Promise<ProviderInfo> => {
    const provider = await providerAPI.fetch(providerId);

    return provider;
  };

  return {
    getAllProviders,
    getNonEpicProviders,
    getSpecialistAndHealthCoachProviders,
    getProvider,
    getProviderById,
  };
};
