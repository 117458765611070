import clsx from "clsx";
import { CaretLeft, X } from "phosphor-react";
import {
  createContext,
  ReactChild,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import ReactDOM from "react-dom";
import { Button } from "../button/Button";
import {
  Transition,
  TransitionGroup,
  CSSTransition,
} from "react-transition-group";
import { Heading } from "../typography/heading/Heading";
import styles from "./style.module.css";
import { Body } from "../typography/body/Body";
import _ from "lodash";

const mountingClassnames = {
  enter: styles.enter,
  exit: styles.exit,
  enterActive: styles.enterActive,
  exitActive: styles.exitActive,
  enterDone: styles.enterDone,
  exitDone: styles.exitDone,
};

const transitionClassnames = {
  enter: styles.appear,
  exit: styles.hide,
  enterActive: styles.appearActive,
  exitActive: styles.hideActive,
  enterDone: styles.appearDone,
  exitDone: styles.hideDone,
};

export interface ComponentMap {
  [route: string]: {
    title: string;
    subTitle?: string;
    tag?: string;
    tagType?: string;
    route: string;
    component: (...props: any) => JSX.Element;
    props?: object;
  };
}

interface params {
  props?: object;
  title?: string;
  subTitle?: string;
}

interface MemoryRouterContext {
  pushRoute: (newRoute: string, params?: params) => void;
  popRoute: () => void;
  closeModal: () => void;
}

//@ts-ignore
const memoryRouterContext = createContext<MemoryRouterContext>();

interface ModalProps {
  onCloseModal: () => void;
  children?: ReactChild | ReactChild[];
  title?: string;
  subTitle?: string;
  className?: string;
  dismissable?: boolean;
  usePortal?: boolean;
  visible?: boolean;
  centerTitle?: boolean;
  componentMap?: ComponentMap;
  initialRoute?: string;
}

export const Modal = ({
  children,
  title,
  subTitle,
  onCloseModal,
  className,
  usePortal = true,
  dismissable = true,
  visible = true,
  centerTitle = false,
  componentMap,
  initialRoute,
}: ModalProps) => {
  if (usePortal)
    return ReactDOM.createPortal(
      <ModalComponent
        {...{
          children,
          title,
          subTitle,
          onCloseModal,
          className,
          usePortal,
          dismissable,
          visible,
          centerTitle,
          componentMap,
          initialRoute,
        }}
      />,
      document.getElementById("app") || document.body
    );
  else
    return (
      <ModalComponent
        {...{
          children,
          title,
          subTitle,
          onCloseModal,
          className,
          usePortal,
          dismissable,
          visible,
          centerTitle,
          componentMap,
          initialRoute,
        }}
      />
    );
};

const ModalComponent = ({
  children,
  title,
  subTitle,
  onCloseModal,
  className,
  usePortal = true,
  dismissable = true,
  visible = true,
  centerTitle = false,
  componentMap,
  initialRoute,
}: ModalProps) => {
  const [routeStack, setRouteStack] = useState<string[]>(
    componentMap && initialRoute ? [componentMap[initialRoute].route] : []
  );
  const [componentDir, setComponentDir] = useState(componentMap);

  useEffect(() => setComponentDir(componentMap), [componentMap]);
  const displayedTitle = useMemo(() => {
    if (componentDir)
      return componentDir[routeStack[routeStack.length - 1]]?.title;
    else return title;
  }, [routeStack, componentDir]);

  const displayedSubTitle = useMemo(() => {
    if (componentDir)
      return componentDir[routeStack[routeStack.length - 1]]?.subTitle;
    else return subTitle;
  }, [routeStack, componentDir]);

  const activePages = useMemo(() => {
    if (componentDir)
      return routeStack.map((route, index) => {
        const Component = componentDir[route].component;
        const props = componentDir[route].props || {};
        const currentPage = index === routeStack.length - 1;
        return { Component, props, currentPage, route };
      });
  }, [routeStack, componentDir]);

  const handleClose = () => {
    onCloseModal();
  };

  const handleOnClosed = () => {
    setRouteStack(
      componentMap && initialRoute ? [componentMap[initialRoute].route] : []
    );
  };

  const popRoute = () =>
    setRouteStack(routeStack.slice(0, routeStack.length - 1));
  const pushRoute = (newRoute: string, params?: params) => {
    setRouteStack([...routeStack, newRoute]);
    const props = params?.props;
    const title = params?.title;
    const subTitle = params?.subTitle;

    if (componentDir)
      setComponentDir({
        ...componentDir,
        [newRoute]: {
          ...componentDir[newRoute],
          title: title ? title : componentDir[newRoute].title,
          subTitle: subTitle ? subTitle : componentDir[newRoute].subTitle,
          props: {
            ...componentDir[newRoute].props,
            ...props,
          },
        },
      });
  };

  const memoryRouterValues = useMemo(
    () => ({
      pushRoute,
      popRoute,
      closeModal: handleClose,
    }),
    [routeStack, componentDir]
  );

  return (
    <Transition
      in={visible}
      timeout={200}
      unmountOnExit
      onExited={handleOnClosed}
    >
      <div
        className={clsx(styles.modalContainer, !visible && styles.closing)}
        onClick={dismissable ? onCloseModal : undefined}
        style={{ position: usePortal ? "absolute" : "static" }}
      >
        <div
          className={clsx(styles.modal, className)}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={clsx(styles.modalHeader)}>
            {routeStack.length > 1 && (
              <Button
                size="small"
                type="secondary-gray"
                onClick={popRoute}
                Icon={CaretLeft}
              />
            )}
            {centerTitle && (
              <div style={{ visibility: "hidden" }}>
                {" "}
                <Button onClick={() => {}} />{" "}
              </div>
            )}
            <div className={styles.headerTitle}>
              <Heading type="02">{displayedTitle}</Heading>
              {displayedSubTitle && (
                <Body size="sm" color="secondary">
                  {displayedSubTitle}
                </Body>
              )}
            </div>

            {dismissable && (
              <Button
                size="small"
                type="secondary-gray"
                onClick={handleClose}
                Icon={X}
              />
            )}
          </div>

          <memoryRouterContext.Provider value={memoryRouterValues}>
            <div className={styles.contentContainer}>
              {children ? (
                children
              ) : componentDir ? (
                <TransitionGroup component={null}>
                  {activePages?.map(
                    ({ Component, props, currentPage, route }) => {
                      return (
                        <CSSTransition
                          timeout={300}
                          classNames={mountingClassnames}
                          key={route}
                        >
                          <CSSTransition
                            in={currentPage}
                            timeout={300}
                            classNames={transitionClassnames}
                          >
                            <div className={styles.page}>
                              <Component {...props} />
                            </div>
                          </CSSTransition>
                        </CSSTransition>
                      );
                    }
                  )}
                </TransitionGroup>
              ) : undefined}
            </div>
          </memoryRouterContext.Provider>
        </div>
      </div>
    </Transition>
  );
};

export const useMemoryRouter = () => {
  return useContext<MemoryRouterContext>(memoryRouterContext);
};

