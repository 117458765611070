import clsx from "clsx";
import { useEffect, useRef, useState, useMemo } from "react";
import { IconProps, MagnifyingGlass, XCircle } from "phosphor-react";
import { Body } from "../typography/body/Body";
import { Tag } from "../tag/Tag";
import { Dropdown } from "../dropdown/Dropdown";
import { Option } from "../../lib/interfaces/input";
import styles from './style.module.css'
import { Avatar } from "../avatar/Avatar";
import Fuse from 'fuse.js'

interface SearchSelectProps {
  label?: string
  placeholder?: string
  LeftIcon?: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>
  hint?: string
  disabled?: boolean
  error?: boolean
  width?: number | string
  options?: Option[] | ((searchQuery: string) => Option[])
  value?: Option | Option[]
  onChange?: (value: any) => void
  className?: string,
  loading?: boolean,
}

export const SearchSelect = ({
  label,
  placeholder,
  LeftIcon,
  hint,
  error = false,
  disabled = false,
  width,
  options = [],
  value,
  onChange = () => { },
  className,
  loading,
}: SearchSelectProps) => {
  const multiple = Array.isArray(value)
  const [searchValue, setSearchValue] = useState<string>('')
  const [inputFocused, setInputFocused] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const fuzzySearchProps = {
    keys: ['name'],
    threshold: 0.1,
    minMatchCharLength: 1,
  };

  const fuzzySearcher = useMemo(() => {
    if (Array.isArray(options)) {
      const fuse = new Fuse(options, fuzzySearchProps)
      return fuse
    }
    return null
  }, [options])

  useEffect(() => {
    if (multiple) inputRef.current?.scrollIntoView()
    else { setSearchValue(value?.name || '') }
  }, [value])

  const clearInput = () => { onChange(''); setSearchValue(''); }

  const removeSelectedOption = (option: Option) => {
    if (multiple)
      onChange(value.filter(selected => option.value !== selected.value))
  }

  const addSelectedOption = (option: Option) => {
    if (multiple && value.filter(selected => option.value === selected.value).length === 0) {
      onChange([...value, option])
    }
  }

  const handleOptionSelect = (option: Option, selected: boolean) => {
    if (multiple) {
      if (selected) {
        addSelectedOption(option)
        setSearchValue('')
      }
      else removeSelectedOption(option)
    } else {
      setSearchValue(option.name)
      onChange(option)
    }
    inputRef.current?.blur()
  }

  const displayOptions = useMemo(() => {

    if (Array.isArray(options)) {
      // Filter out repeated values if any.
      const filteredOptions = options.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.id === value.id && t.name === value.name
        ))
      )

      if (!searchValue) {
        return filteredOptions
      }

      fuzzySearcher?.setCollection(filteredOptions)
      return fuzzySearcher?.search(searchValue).map(fuzzyResult => fuzzyResult.item) ?? []
    }
    else if (typeof options === 'function') {
      return options(searchValue)
    }

    return []

  }, [options, searchValue])

  const displaySelectedOption = useMemo(() => {
    if (multiple)
      return (value.map(selectedOption => selectedOption.value))
    else
      return value?.value
  }, [value])

  const popSelectedValue = () => {
    if (searchValue.length === 0 && multiple)
      onChange(value.slice(0, -1))
  }

  const handleInvalidInput = () => {
    if (multiple) {
      setSearchValue('')
    } else if (!value) {
      setSearchValue('')
    } else if (value.name !== searchValue) {
      if (searchValue.length > 0) setSearchValue(value.name)
      else onChange(undefined)
    }
  }

  const displayPlaceholder = useMemo(() => {
    if (multiple && value.length > 0) {
      return undefined
    }
    else return placeholder
  }, [value])

  return (
    <div className={clsx(styles.searchSelect, className)} style={width ? { flexGrow: 0, width: width } : undefined}>
      {label && (
        <label className={clsx(styles.label)}> {label} </label>
      )}

      <div
        onClick={() => inputRef.current?.focus()}
        className={clsx(
          styles.inputContainer,
          inputFocused && styles.focused,
          error && styles.error
        )}>

        {!multiple && value ? (
          typeof value.avatarUrl === 'string' && (
            <Avatar
              size='xs'
              photo={value.avatarUrl}
              firstName={value.name.split(' ')[0]}
              lastName={value.name.split(' ')[1]} />
          )
        ) : !LeftIcon ? (
          <div className={clsx(styles.icon, styles.iconLeft)}><MagnifyingGlass size={24} /></div>
        ) : (
          <div className={clsx(styles.icon, styles.iconLeft)}><LeftIcon size={24} /></div>
        )}

        <div  data-dd-privacy="mask" className={styles.inputTextContainer}>
          {multiple && value.length > 0 && (
            value.map(selectedOption => (
              <div key={selectedOption.id || selectedOption.value} className={styles.tagContainer}>
                <Tag content={selectedOption.name} key={selectedOption.value} type='black' onDelete={() => removeSelectedOption(selectedOption)} />
              </div>
            ))
          )}
          <input
            ref={inputRef}
            onKeyDown={event => {
              if (event.key.toUpperCase() === 'BACKSPACE') popSelectedValue()
            }}
            disabled={disabled}
            className={styles.input}
            placeholder={displayPlaceholder}
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            onFocus={() => setInputFocused(true)}
            onBlur={() => { setInputFocused(false); handleInvalidInput() }}
          />
        </div>

        {!multiple && searchValue.length > 0 && !disabled && (
          <button
            onClick={clearInput}
            className={styles.clearButton}
          >
            <XCircle weight='fill' size={20} />
          </button>
        )}

      </div>

      {inputFocused && (
        <Dropdown  data-dd-privacy="mask" options={displayOptions} selectedValue={displaySelectedOption} onOptionSelect={handleOptionSelect} width={width} loading={loading} />
      )}

      {hint &&
        <Body
          className={styles.hint}
          size='sm' weight='regular' color={error ? 'danger' : 'secondary'}>
          {hint}
        </Body>
      }
    </div>
  )
}