import { useState } from "react";
import clsx from "clsx";
import { CheckCircle, MinusCircle, Prohibit, UserSwitch } from 'phosphor-react';
import { Heading } from "../typography/heading/Heading";
import { Select } from "../../components/select/Select";
import { Button } from "../button/Button";
import { Option } from "../../lib/interfaces/input";
import { useChats } from "../../lib/hooks/useChats";
import { ChatPreview } from "../../lib/interfaces/messaging";
import { useBaseContext } from "../../containers/_base/Base";
import { PatientInfo } from "../../lib/interfaces/user";
import { usePatient } from "../../lib/hooks/usePatient";
import { BEFORE_VERIFICATION_FAIL } from '../../constants/Errors';
import styles from './style.module.css'

export interface RecordApprovalCardProps {
  cardType: string,
  status: Option,
  statusOptions: Option[],
  handleStatusChange: (option: any) => void,
  patient: PatientInfo,
  initialStatus: string
}

export const RecordsApprovalCard = ({ cardType, status, statusOptions, handleStatusChange, patient, initialStatus }: RecordApprovalCardProps) => {
  const [updatedStatus, setUpdatedStatus] = useState(true);
  const [newMsgLoading, setNewMsgLoading] = useState<boolean>(false);
  const { getChatWithUsers } = useChats();
  const { openPopoutChat } = useBaseContext();
  const { updateIDstatus, updateInsurancestatus } = usePatient();

  const getIcon = () => {
    switch (status.value) {
      case "NOT VERIFIED": return <div className={styles.iconbg}><MinusCircle size={14} /></div>
      case "IN PROGRESS": return <div className={clsx(styles.iconbg, styles.iconBlue)}><UserSwitch size={14} /></div>;
      case "VERIFIED": return <div className={clsx(styles.iconbg, styles.iconGreen)}><CheckCircle size={14} /></div>;
      case "ERROR": return <div className={clsx(styles.iconbg, styles.iconRed)}><Prohibit size={14} /></div>
    }
  }

  const getInfoMessage = () => {
    if (status.value === "ERROR") {
      return updatedStatus ? `Message the patient to let them know what needs to be changed, updated, or done differently to properly validate their ${cardType} information.` : BEFORE_VERIFICATION_FAIL;
    } else if (status.value === "VERIFIED" && !updatedStatus) {
      return `Updating the status to “Approved” will alert the member that their ${cardType} is entered and approved.`;
    } else return "";
  }

  const updateStatus = async () => {
    if (status.value === "ERROR" && updatedStatus) {
      setNewMsgLoading(true);
      getChatWithUsers(patient.id).then((res) => {
        const chat: ChatPreview | undefined = res?.chat;
        chat && openPopoutChat &&
          openPopoutChat(
            {
              id: chat.id,
              participants: chat.participants,
              regarding: chat.regarding,
            });
        setNewMsgLoading(false);
      });
    }
    const updateVerificationStatus = cardType === "identification" ?
      await updateIDstatus(patient.id, status.value):
      await updateInsurancestatus(patient.id, status.value);
    if (updateVerificationStatus) {
      setUpdatedStatus(true);
    }
  }

  const handleStatusSelection = (value: Option) => {
    handleStatusChange(value);
    setUpdatedStatus(false);
  }

  const getNextStep = () => {
    if (status.value === "ERROR" && updatedStatus) {
      return `Member is required to resubmit ${cardType} information.`
    } else if (status.value === "VERIFIED" && updatedStatus) {
      return 'No further action is required.'
    }
    else return `Go to Epic > Patient Profile > Media to approve ${patient.firstName}’s submitted ${cardType} information.`
  }

  return (
    <div className={styles.base}>
      <div className={styles.selectionContainer}>
        <div>
          <Heading className={styles.heading} type="03">
            {cardType === "insurance" ? "Insurance Validation Status" : "Identity Verification Status"}
          </Heading>
          <div data-dd-privacy="mask" className={styles.steps}>{getNextStep()}</div>
        </div>
        <div className={styles.rightContainer}>
          {getIcon()}
          <Select className={styles.valueContainer} options={statusOptions} value={status} onChange={handleStatusSelection} />
        </div>
      </div>
      {getInfoMessage() && <div className={styles.infoMsg}>
        <div className={clsx(styles.info, status.value === "ERROR" ? styles.fail : styles.approved)}>
          {getInfoMessage()}
        </div>
        <Button
          data-dd-privacy="mask"
          label={status.value === "ERROR" && updatedStatus ? "Message Patient" : "Update Status"}
          onClick={updateStatus}
          loading={newMsgLoading}
        />
      </div>
      }
    </div>
  )
};