import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { PatientCard } from "../../../components/patientCard/PatientCard";
import { PatientCardInfo, ProviderInfo } from "../../../lib/interfaces/user";
import { FeaturedIcon } from "../../../components/featuredIcon/FeaturedIcon";
import { Body } from "../../../components/typography/body/Body";
import { Heading } from "../../../components/typography/heading/Heading";
import { Users } from "phosphor-react";
import { Option } from "../../../lib/interfaces/input";
import {
  FilterOptions,
  SearchOption,
} from "../../../lib/interfaces/listOfPatients";
import { useChats } from "../../../lib/hooks/useChats";
import styles from "./style.module.css";
import { useBaseContext } from "../../_base/Base";
import { ChatPreview } from "../../../lib/interfaces/messaging";
import { BaseContext } from "../../../lib/context/context";
import { Visit } from "../../../lib/interfaces/visits";
import { MembershipPremium } from "../../../lib/interfaces/patientInfo";
import { Button } from "../../../components/button/Button";

export interface PatientListProps {
  sortingOption: Option;
  filterOptions: FilterOptions;
  searchOption: SearchOption;
  handleEmptyList: (patientList: PatientCardInfo[]) => void;
  noAppointmentOption: boolean;
  compassPlusOption: boolean;
  onNewApptClick: (patient: PatientCardInfo) => void;
  refreshPatientCard?: string;
  resetRefresh?: () => void;
}

const NoPatientsToDisplay = () => (
  <div className={styles.emptyState}>
    <FeaturedIcon Icon={Users} type="gray" />
    <Heading className={styles.heading} type="02">
      No Patients to Display
    </Heading>
    <Body color="secondary">Try removing filters to widen the scope</Body>
  </div>
);

export const PatientList = ({
  sortingOption,
  filterOptions,
  searchOption,
  handleEmptyList,
  noAppointmentOption,
  compassPlusOption,
  onNewApptClick,
  refreshPatientCard,
  resetRefresh,
}: PatientListProps) => {

  const { openPopoutChat } = useBaseContext();
  const { getChatWithUsers } = useChats();
  const { userInfo, allPatients } = useContext(BaseContext);

  const [patientList, setPatientList] = useState<PatientCardInfo[]>();
  const [signedInUser, setSignedInUser] = useState<ProviderInfo>(
    userInfo || {
      id: "",
      name: "",
      firstName: "",
      lastName: "",
      title: "",
      primarySpecialty: "",
    }
  );
  const [newMsgLoading, setNewMsgLoading] = useState<string[]>([]);
  const patientListRef = useRef<PatientCardInfo[]>();
  const [currentPage, setCurrentPage] = useState(0);

  const pageSize = 10;

  useEffect(() => {
    if (allPatients) setPatientList(allPatients);
  }, [allPatients]);

  useEffect(() => {
    if (patientList) patientListRef.current = patientList;
  }, [patientList]);

  useEffect(() => {
    if (userInfo) setSignedInUser(userInfo);
  }, [userInfo]);

  const updatePatientVisits = (
    id: string,
    nextAppt: Visit,
    lastAppt: Visit
  ) => {
    const updPatientList: PatientCardInfo[] = JSON.parse(
      JSON.stringify(patientListRef.current)
    );
    const index = updPatientList.findIndex(
      (patient: PatientCardInfo) => patient.id === id
    );
    updPatientList[index] = {
      ...updPatientList[index],
      nextAppointment: nextAppt,
      lastAppointment: lastAppt,
    };
    patientListRef.current = updPatientList;
  };

  const updatePatientCareTeam = (id: string, careTeamIds: string[]) => {
    const updPatientList: PatientCardInfo[] = JSON.parse(
      JSON.stringify(patientListRef.current)
    );
    const index = updPatientList.findIndex(
      (patient: PatientCardInfo) => patient.id === id
    );
    updPatientList[index] = {
      ...updPatientList[index],
      careTeam: careTeamIds,
    };
    patientListRef.current = updPatientList;
  };

  const sortBySelectedOption = useCallback((patientA: PatientCardInfo,
    patientB: PatientCardInfo): number => {
    const sortingMethod = sortingOption.value;
    switch (sortingMethod) {
      case "patient-alph":
      case "patient-alph-rev":
        return (
          patientA.lastName.localeCompare(patientB.lastName) *
          (sortingMethod === "patient-alph" ? 1 : -1)
        );
      case "":
        return 0;
      default:
        console.error(`Invalid sorting method: '${sortingMethod}'`);
        return 0;
    }
  }, [sortingOption])


  const filterByProvider = (patientFromList: PatientCardInfo): boolean => {
    if (filterOptions.providers.length > 0) {
      if (patientListRef.current) {
        const patient = patientListRef.current.find(
          (p) => p.id === patientFromList.id
        );
        if (patient) {
          if (!patient.careTeam || patient.careTeam.length === 0) {
            return false;
          } else {
            const res =
              patient.careTeam.filter((providerId) => {
                return (
                  filterOptions.providers.filter(
                    (provider) => provider.value === providerId
                  ).length > 0
                );
              }).length > 0;
            return res;
          }
        } else return true;
      } else return true;
    } else return true;
  };

  const filterByPatient = (patient: PatientCardInfo): boolean => {
    if (searchOption?.patient?.value) {
      return searchOption?.patient?.value === patient.id;
    }
    return true;
  };

  const filterByNoAppointment = (patientFromList: PatientCardInfo): boolean => {
    if (!noAppointmentOption) {
      return true;
    }

    return !patientFromList.alreadyScheduledAppointments;
  };

  const filterByCompassPlus = (patientFromList: PatientCardInfo): boolean => {
    if (compassPlusOption === true && patientListRef.current) {
      const patient = patientListRef.current.find(
        (p) => p.id === patientFromList.id
      );
      return patient?.settings?.General?.Membership === MembershipPremium;
    } else return true;
  };

  const onNewMsgClick = (patient: PatientCardInfo, template: boolean) => {
    setNewMsgLoading((currentNewMsgLoading) => [
      ...currentNewMsgLoading,
      patient.id,
    ]);
    getChatWithUsers(patient.id).then((res) => {
      const chat: ChatPreview | undefined = res?.chat;
      if (chat) {
        openPopoutChat &&
          openPopoutChat(
            {
              id: chat.id,
              participants: chat.participants,
              regarding: chat.regarding,
            },
            !template
              ? ""
              : `Hello ${patient.firstName}! How has your week been? I noticed that you haven't booked an appointment yet. I'm reaching out to make sure you're alright and see how I can help.`
          );
      }
      setNewMsgLoading((currentNewMsgLoading) =>
        currentNewMsgLoading.filter((patientId) => patientId !== patient.id)
      );
    });
  };

  const filteredPatientList: PatientCardInfo[] = useMemo(() => {
    if (!patientList) {
      return [];
    }

    // it resets the page to 0 when the filters change
    setCurrentPage(0);
    return patientList
      .filter(filterByPatient)
      .filter(filterByProvider)
      .filter(filterByNoAppointment)
      .filter(filterByCompassPlus)
      .sort(sortBySelectedOption);
  }, [
    sortingOption,
    filterOptions,
    searchOption,
    noAppointmentOption,
    compassPlusOption,
    patientList,
  ]);

  const paginatedPatientsList: PatientCardInfo[] = useMemo(() => {
    return filteredPatientList
      .slice(currentPage * pageSize, ((currentPage * pageSize) + pageSize))
  }, [
    filteredPatientList,
    currentPage
  ]);

  useEffect(() => {
    handleEmptyList(paginatedPatientsList);
  }, [paginatedPatientsList, handleEmptyList]);

  const pageCount = useMemo(() => {
    if (filteredPatientList && filteredPatientList.length > 0) {
      return Math.ceil(filteredPatientList.length / pageSize);
    }
    return 0;
  }, [paginatedPatientsList]);

  const changePage = useCallback((page: number) => {
    if (page < 0) {
      page = 0;
    }
    else if (page > pageCount - 1) {
      page = pageCount - 1;
    }
    setCurrentPage(page)
  }, [currentPage, pageCount]);

  return (
    <div className={styles.patientList}>
      {paginatedPatientsList.length === 0 && (<NoPatientsToDisplay />)}
      {paginatedPatientsList.length > 0 && (
        <>
        <div>
          {paginatedPatientsList.map((patient) => (
              <div key={patient.id} className={styles.patientCard}>
                <PatientCard
                  key={patient.id}
                  patient={patient}
                  user={signedInUser}
                  onNewApptClick={onNewApptClick}
                  onNewMsgClick={onNewMsgClick}
                  newMsgLoading={newMsgLoading.includes(patient.id)}
                  updatePatientVisits={updatePatientVisits}
                  updatePatientCareTeam={updatePatientCareTeam}
                  refreshPatientCard={refreshPatientCard === patient.id}
                  resetRefresh={resetRefresh}
                />
              </div>
          ))}
        </div>
        <nav className={styles.paginationContainer}>
          <Button disabled={currentPage === 0} label="<<" onClick={() => changePage(0)} />
          <Button disabled={currentPage === 0} label="<" onClick={() => changePage(currentPage - 1)} />
          <Button disabled={currentPage === pageCount - 1} label=">" onClick={() => changePage(currentPage + 1)} />
          <Button disabled={currentPage === pageCount - 1} label=">>" onClick={() => changePage(pageCount - 1)} />
          <div>
            <h3>Page {currentPage + 1} of {pageCount}</h3>
          </div>
        </nav>
        </>
      )}
    </div>
  );
};
