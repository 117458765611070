import React, { useState, useEffect, useRef, useMemo } from "react";
import styles from './style.module.css'
import {
  RemoteAudioTrack,
  RemoteAudioTrackPublication,
  RemoteParticipant,
  RemoteTrack,
  RemoteVideoTrack,
  RemoteVideoTrackPublication,
  Participant as videoParticipant
} from "twilio-video";

type props = {
  participant: RemoteParticipant;
  videoOn: boolean,
  mirrored?: boolean
};

const Participant: React.FC<props> = ({ participant, videoOn, mirrored = false }) => {
  const [videoTracks, setVideoTracks] = useState<(RemoteVideoTrack | null)[]>([]);
  const [audioTracks, setAudioTracks] = useState<(RemoteAudioTrack | null)[]>([]);
  

  const videoRef = useRef();
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const trackpubsToTracks = (
    trackMap:
      | Map<string, RemoteVideoTrackPublication>
      | Map<string, RemoteAudioTrackPublication>
  ) =>
    Array.from<RemoteVideoTrackPublication | RemoteAudioTrackPublication>(
      trackMap.values()
    )
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    setVideoTracks(
      trackpubsToTracks(participant.videoTracks) as (RemoteVideoTrack | null)[]
    );

    setAudioTracks(
      trackpubsToTracks(participant.audioTracks) as (RemoteAudioTrack | null)[]
    );

    const trackSubscribed = (track: RemoteTrack) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track: { kind: string }) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    let videoContainer: HTMLElement | null = document.getElementById('video-container');
    const userVideo: HTMLVideoElement | null = document.getElementById('user-video') as HTMLVideoElement;

    const handleParticipantConnected = (participant: videoParticipant) => {
      // Create a new video element for the participant
      const participantVideo = document.createElement('video');
      participantVideo.autoplay = true;
      const videoTrack = participant.videoTracks.values().next().value.track;
      participantVideo.srcObject = new MediaStream([videoTrack.mediaStreamTrack]);
      videoContainer = document.getElementById('video-container');
      if (videoContainer) {
        videoContainer.appendChild(participantVideo);
      }

      // Update the video dimensions when the video track dimensions change
      videoTrack.onDimensionsChanged(() => {
        updateVideoDimensions(videoTrack.dimensions.width, videoTrack.dimensions.height);
      });

      // Initialize the video dimensions
      updateVideoDimensions(videoTrack.dimensions.width, videoTrack.dimensions.height);
    }

    const handleParticipantDisconnected = (participant: videoParticipant) => {
      // Remove the participant's video element from the video container
      videoContainer = document.getElementById('video-container');
      if (videoContainer) {
        const videoElement = videoContainer.querySelector(`[data-participant-id="${participant.sid}"]`);
        if (videoElement) {
          videoElement.remove();
        }
      }
    }

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);
    participant.on('participantConnected', handleParticipantConnected);
    participant.on('participantDisconnected', handleParticipantDisconnected);

    // Function to update the video dimensions while maintaining aspect ratio
     const updateVideoDimensions = (width: number, height: number) => {
      if (!videoContainer || !userVideo) {
        return;
      }
      const containerWidth = videoContainer.offsetWidth;
      const containerHeight = videoContainer.offsetHeight;
      const aspectRatio = width / height;
      const containerAspectRatio = containerWidth / containerHeight;
      if (containerAspectRatio > aspectRatio) {
        userVideo.style.width = `${containerHeight * aspectRatio}%`;
        userVideo.style.height = `${containerHeight}%`;
      } else {
        userVideo.style.width = `${containerWidth}%`;
        userVideo.style.height = `${containerWidth / aspectRatio}%`;
      }
    }

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);


    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      //@ts-ignore
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current ?? "");
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  const videoStyle = useMemo(() => {
    if(mirrored) {
      return styles.videoMirrored
    }
    return styles.video
  }, [styles, mirrored])

  return (
    <div className={styles.videoContainer} id="video-container">
      {/* @ts-ignore */}
      <video id="user-video" ref={videoRef} hidden={!videoOn} autoPlay={true} height={"100%"} width={"100%"} className={videoStyle}/>
      <audio ref={audioRef} autoPlay={true} />
    </div>
  );
};

export default Participant;