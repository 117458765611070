import { useOktaAuth } from "@okta/okta-react";
import { useContext } from "react";
import { fetchVitals, ResponseData } from "../apis/vitals"
import { VitalsInterface } from "../interfaces/vitals"
import { AlertContext } from "../context/context";

export const useVitals = () => {
  const { oktaAuth } = useOktaAuth()
  const { pushAlert } = useContext(AlertContext)
  const accessToken = oktaAuth.getAccessToken()

  const vitalTypes = ['Height', 'Weight', 'BMI', 'Temp', 'SpO2', 'BP']

  const cmToFt = (cm: number | string) => {
    let n: number
    if (typeof cm === 'string') {
      n = parseFloat(cm)
    } else n = cm
    const meters = n / 100
    const feet = Math.trunc(meters*3.280839895)
    const inches = Math.round(((meters*3.280839895)-feet)*12)
    return feet + "' " + inches + '"';
  }

  const cmToIn = (cm: number | string) => {
    let n: number
    if (typeof cm === 'string') {
      n = parseFloat(cm)
    } else n = cm
    return n / 2.54
  }

  const kgToLbs = (kg: number | string) => {
    let n: number
    if (typeof kg === 'string') {
      n = parseFloat(kg)
    } else n = kg
    return Math.round(n/0.45359237).toString() + " lbs" 
  }

  const celToFar = (cel: number | string) => {
    let n: number
    if (typeof cel === 'string') {
      n = parseFloat(cel)
    }
    else n = cel
    n = (n * (9/5)) + 32 //convert to Far
    return (Math.round(n * 100) / 100).toString(); //round to 1st decimal
  }

  const bmi = (kgs: number, cm: number) => {
    //Calculation: [Weight (lbs) ÷ Height (in) squared] x 703
    // use rounded weight, rounded inches
    const lbs = parseInt(kgToLbs(kgs))
    const inches = Math.round(cmToIn(cm))
    const result = ((lbs / (inches * inches)) * 703)
    return (Math.round((result * 100))/100).toString()
  }
 
  const getVitals = async (userId: string, controller?: AbortController) => {
    if (accessToken) {
        const _vitals = await fetchVitals(userId, controller)

        const updatedVitals: VitalsInterface = {
          "Height": {reading: "-", date: ""},
          "Weight": {reading: "-", date: ""},
          "BMI": {reading: "-", date: ""},
          "Temp": {reading: "-", date: ""},
          "SpO2": {reading: "-", date: ""},
          "BP": {reading: "-", date: ""},
        }

        if(!_vitals){
          pushAlert("Failed to get patient's vitals", 'danger')
        }
        if(!_vitals || _vitals === 'cancelled') return updatedVitals

        _vitals.vitalSigns.map((item: ResponseData) => {
          if(item.code && vitalTypes.includes(item.code) && item.readings){ 
              if(updatedVitals[item.code].date === "" || (item.dateTime && Date.parse(updatedVitals[item.code].date) < Date.parse(item.dateTime))){
                if(item.code === 'BP' && item.readings.length > 0 && item.readings[0].value){
                  updatedVitals[item.code].reading = item.readings[0].value + "/" + item.readings[1].value
                }
                else if(item.code === 'SpO2' && item.readings.length > 0 && item.readings[0].value && item.readings[0].unit){  
                  updatedVitals[item.code].reading = item.readings[0].value + item.readings[0].unit
                }
                else if(item.readings.length > 0 && item.readings[0].value && item.readings[0].unit){
                  updatedVitals[item.code].reading = item.readings[0].value + " " + item.readings[0].unit
                }          
                if(item.dateTime) { updatedVitals[item.code].date = item.dateTime }
              }
          }
        })
        //only calculate bmi if height and weight have the same date
        updatedVitals.BMI.reading = (updatedVitals.Height.reading !== '-' && updatedVitals.Weight.reading !== '-' && updatedVitals.Height.date === updatedVitals.Weight.date) ?
          bmi(parseFloat(updatedVitals.Weight.reading), parseFloat(updatedVitals.Height.reading)) : 'None'
        updatedVitals.BMI.date = (updatedVitals.BMI.reading !== '-' && updatedVitals.BMI.reading !== 'None') ? updatedVitals.Height.date : ""
        //convert height, weight, and temp to ft, lbs, and far
        if(updatedVitals.Height.reading !== '-') { updatedVitals.Height.reading = (cmToFt(updatedVitals.Height.reading)) }
        if(updatedVitals.Weight.reading !== '-') { updatedVitals.Weight.reading = (kgToLbs(updatedVitals.Weight.reading)) }
        if(updatedVitals.Temp.reading !== '-') { updatedVitals.Temp.reading = (celToFar(updatedVitals.Temp.reading)) }
        return updatedVitals
    } else
        return {
          "Height": {reading: "-", date: ""},
          "Weight": {reading: "-", date: ""},
          "BMI": {reading: "-", date: ""},
          "Temp": {reading: "-", date: ""},
          "SpO2": {reading: "-", date: ""},
          "BP": {reading: "-", date: ""},
        }
  }
  return { getVitals }
}
