import { MagnifyingGlass } from 'phosphor-react'
import { useEffect, useMemo, useState } from 'react'
import { Button, ButtonGroup } from '../../../../components/button/Button'
import { InteractiveList, Item } from '../../../../components/interactiveList/InteractiveList'
import { useMemoryRouter } from '../../../../components/modal/Modal'
import { Radio } from '../../../../components/radio/Radio'
import { Spinner } from '../../../../components/spinner/Spinner'
import { TextInput } from '../../../../components/textInput/TextInput'
import { Body } from '../../../../components/typography/body/Body'
import { usePatient } from '../../../../lib/hooks/usePatient'
import { UserInfo } from '../../../../lib/interfaces/user'
import styles from './style.module.css'

export interface PatientSelectFormSubmission {
  patient?: UserInfo
  regarding?: UserInfo
}

interface PatientSelectFormProps {
  patientAsParticipant?: boolean
}

export const PatientSelectForm = ({ patientAsParticipant = true }: PatientSelectFormProps) => {
  const [allPatients, setAllPatients] = useState<UserInfo[]>([])
  const [selectedPatient, setSelectedPatient] = useState<UserInfo>()
  const [providersOnly, setProvidersOnly] = useState(false)
  const [loading, setLoading] = useState(true)
  const [searchValue, setSearchValue] = useState('')
  const { pushRoute } = useMemoryRouter()
  const { getAllPatients } = usePatient()

  useEffect(() => {
    getAllPatients().then(res => setAllPatients(res))
  }, [])

  useEffect(() => () => setLoading(false), [allPatients])
  useEffect(() => { if (providersOnly) setSelectedPatient(undefined) }, [providersOnly])
  useEffect(() => { if (selectedPatient) setProvidersOnly(false) }, [selectedPatient])

  const handleSelect = (patients: UserInfo) => {
    setSelectedPatient(patients)
  }

  const listItems = useMemo(() => {
    return allPatients.filter(patient => {
      const fullName = [patient.firstName, patient.lastName].join(' ')
      return fullName.match(new RegExp(searchValue, 'i')) !== null
    })
  }, [allPatients, searchValue])

  const handleClickNext = () => {
    const props: PatientSelectFormSubmission = {}
    let subTitle: string|undefined = undefined
    if (patientAsParticipant) {
      subTitle = '(Optional)'
      props.patient = selectedPatient
      props.regarding = undefined
    }
    else{
      props.patient = undefined
      props.regarding = selectedPatient
    }

    pushRoute('provider-select', { props, subTitle })
  }

  return (
    <div className={styles.patientSelectForm}>
      <TextInput
        value={searchValue}
        onChange={setSearchValue}
        LeftIcon={MagnifyingGlass}
      />
      <div className={styles.listContaner}>
        <Body className={styles.listLabel} size='sm' color='secondary'>Patients</Body>
        {loading ? (<Spinner />) : (
          <>
            {!patientAsParticipant && (
              <div className={styles.providersOnlyOption} onClick={e => { e.stopPropagation(); setProvidersOnly(true) }}>
                <Body size='md'>No, message providers only.</Body>
                <Radio selected={providersOnly} onSelect={() => setProvidersOnly(true)} />
              </div>
            )}
            <InteractiveList
              items={listItems}
              value={selectedPatient} //@ts-ignore
              onChange={handleSelect} />
          </>
        )}
      </div>

      <ButtonGroup className={styles.buttonContainer}>
        <Button
          label='Next'
          disabled={!selectedPatient && !providersOnly}
          onClick={handleClickNext} />
      </ButtonGroup>
    </div>
  )
}