import { useState } from "react";
import styles from "./style.module.css";
import { Avatar } from "../../../../components/avatar/Avatar";
import { patients } from "../../../../lib/samples/users";
import { FeaturedIcon } from "../../../../components/featuredIcon/FeaturedIcon";
import { Body } from "../../../../components/typography/body/Body";
import { Heading } from "../../../../components/typography/heading/Heading";
import { Modal } from "../../../../components/modal/Modal";
import { Button, ButtonGroup } from "../../../../components/button/Button";
import { useHistory } from "react-router-dom";
import { UserInfo } from "../../../../lib/interfaces/user";
import {
  PhoneDisconnect,
  Microphone,
  MicrophoneSlash,
  VideoCamera,
  VideoCameraSlash,
} from "phosphor-react";

interface ConnectingScreenProps {
  username: string
  patient: UserInfo
  room: any
  handleLogout: () => void
  permissionError: boolean
}

const ConnectingScreen = ({
  patient,
  room,
  handleLogout,
  permissionError,
}: ConnectingScreenProps) => {
  const [audioMuted, setAudioMuted] = useState<boolean>(false);
  const [videoMuted, setVideoMuted] = useState<boolean>(false);
  const [leaveCall, setLeaveCall] = useState<boolean>(false);

  const onCancel = () => {
    setLeaveCall(false);
  };

  const onConfirmLeave = () => {
    setLeaveCall(true);
  };

  const history = useHistory();

  const handleCancel = () => {
    history.push("/patient");
  };

  //AUDIO SETTINGS
  const toggleAudio = () => {
    if (audioMuted) {
      room.localParticipant.audioTracks.forEach(
        (publication: { track: { enable: () => void } }) => {
          publication.track.enable();
        }
      );
      setAudioMuted(false);
    } else {
      room.localParticipant.audioTracks.forEach(
        (publication: { track: { disable: () => void } }) => {
          publication.track.disable();
        }
      );
      setAudioMuted(true);
    }
  };

  //VIDEO SETTINGS
  const toggleVideo = () => {
    if (videoMuted) {
      room.localParticipant.videoTracks.forEach(
        (publication: { track: { enable: () => void } }) => {
          publication.track.enable();
        }
      );
      setVideoMuted(false);
    } else {
      room.localParticipant.videoTracks.forEach(
        (publication: { track: { disable: () => void } }) => {
          publication.track.disable();
        }
      );
      setVideoMuted(true);
    }
  };

  return (
    <div className={styles.room}>
      <div className={styles.greyBox}>
        <div className={styles.patientVideo}>
          <div>
            <div className={styles.callingText}>
              <Body weight="bold" size="md" color="white">
                Calling...
              </Body>
            </div>
            <Avatar
              size="xxl"
              user={patient}
              fontSize={28}
              containerSize={160}
            />
            <div className={styles.patientLabel}>
              <Heading  data-dd-privacy="mask" type="02" style={{ color: "white" }}>
                {patient.name}
              </Heading>
              <Body
                weight="regular"
                size="md"
                color="white"
                style={{ opacity: "0.6" }}
              >
                PATIENT
              </Body>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.buttonRow}>
        <div onClick={toggleAudio}>
          {audioMuted ? (
            <FeaturedIcon
              Icon={MicrophoneSlash}
              size="lg"
              type="gray"
              iconColor="white"
              backgroundColor="#D92D20"
            />
          ) : (
            <FeaturedIcon
              Icon={Microphone}
              size="lg"
              type="gray"
              iconColor="white"
              backgroundColor="#514C48"
            />
          )}
        </div>
        <div onClick={toggleVideo}>
          {videoMuted ? (
            <FeaturedIcon
              Icon={VideoCameraSlash}
              size="lg"
              type="gray"
              iconColor="white"
              backgroundColor="#D92D20"
            />
          ) : (
            <FeaturedIcon
              Icon={VideoCamera}
              size="lg"
              type="gray"
              iconColor="white"
              backgroundColor="#514C48"
            />
          )}
        </div>

        <div onClick={onConfirmLeave}>
          <FeaturedIcon
            Icon={PhoneDisconnect}
            size="lgOval"
            type="gray"
            iconColor="white"
            backgroundColor="#D92D20"
          />
        </div>
      </div>

      <Modal
        onCloseModal={() => { }}
        title="End Visit"
        dismissable={false}
        visible={leaveCall}
      >
        <div style={{ overflow: "auto" }}>
          <Body>Are you sure you want to finish your visit?</Body>
          <div style={{ height: 32 }} />
          <ButtonGroup align="end">
            <Button onClick={onCancel} type="secondary-gray" label="Cancel" />
            <Button onClick={handleLogout} label="Yes, end visit" />
          </ButtonGroup>
        </div>
      </Modal>

      <Modal
        onCloseModal={() => { }}
        dismissable={false}
        visible={permissionError}
      >
        <Heading style={{ paddingBottom: "16px" }} type="02">
          Camera and microphone are blocked
        </Heading>
        <Body size="md">
          Hoag Compass requires access to your camera and microphone. Check your browser settings to allow access.
        </Body>
        <div style={{ height: 32 }} />
        <ButtonGroup align="end">
          <Button onClick={() => window.location.reload()} type="secondary-gray" label="Dismiss" />
        </ButtonGroup>
      </Modal>
    </div>
  );
};

export default ConnectingScreen;