import clsx from 'clsx'
import { IconProps } from 'phosphor-react'
import React from 'react'
import { CustomIcon } from '../customIcons/CustonIcons'
import { Spinner } from '../spinner/Spinner'
import styles from './style.module.css'

const iconSize = {
  'lgOval': 20,
  'xxl': 32,
  'xl': 28,
  'lg': 24,
  'md': 20,
  'sm': 16,
  'xs': 12
}

type FeaturedIconSize = 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs' | 'lgOval'
export type FeaturedIconType = 'gray' | 'primary' | 'white' | 'transparent' | 'success' | 'danger' | 'purple' | 'blue' | 'green' | 'orange'

interface FeaturedIconProps {
  size?: FeaturedIconSize
  type?: FeaturedIconType
  Icon: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>> | CustomIcon
  className?: string
  iconColor?: string
  backgroundColor?: string
  loading?: boolean
}

export const FeaturedIcon = ({
  size = 'xxl',
  type = 'primary',
  Icon,
  iconColor,
  backgroundColor,
  className,
  loading
}: FeaturedIconProps) => (
  <div
    className={clsx(
      styles.featuredIcon,
      styles[type],
      styles[size],
      className)}
    style={backgroundColor ? { backgroundColor: backgroundColor } : undefined}>
    {!loading ?
    <Icon size={iconSize[size]} color={iconColor ? iconColor : undefined} />
    :
    <Spinner size={20} top={6}/>
    }
  </div>
)
