import { useContext, useEffect, useState } from "react";
import {
  Accordion,
  AccordionGroup,
} from "../../../components/accordion/Accordion";
import { Button, ButtonGroup } from "../../../components/button/Button";
import { SearchSelect } from "../../../components/searchSelect/SearchSelect";
import { TextInput } from "../../../components/textInput/TextInput";
import { Body } from "../../../components/typography/body/Body";
import { Option } from "../../../lib/interfaces/input";
import { FilterOptions } from "../../../lib/interfaces/messaging";
import { CalendarBlank, MagnifyingGlass } from "phosphor-react";
import { PatientInfo, UserInfo } from "../../../lib/interfaces/user";
import styles from "./style.module.css";
import { BaseContext } from "../../../lib/context/context";
import { DATE_NOT_VALID, TO_DATE_LESS_THAN_FROM } from "../../../constants/Errors";
import _ from "lodash";
import clsx from "clsx";

export interface FiltersFormProps {
  filterOptions: FilterOptions;
  onClearAll: () => void;
  onApply: (
    selectedFilterOptions: FilterOptions,
    selectedUserOption?: Option
  ) => void;
  selectedUserOptions: Option[];
  patientMessages?: boolean;
}

export const FiltersForm = ({
  filterOptions,
  onApply,
  onClearAll,
  selectedUserOptions,
  patientMessages,
}: FiltersFormProps) => {
  const [selectedUserOption, setSelectedUserOption] = useState<Option>(
    selectedUserOptions[0]
  );
  const [
    selectedFilterOptions,
    setSelectedFilterOption,
  ] = useState<FilterOptions>(filterOptions);
  const [providers, setProviders] = useState<UserInfo[]>([]);
  const [patients, setPatients] = useState<PatientInfo[]>([]);
  const [filterChange, setFilterChange] = useState<boolean>(false);
  const [fromDateErr, setFromDateError] = useState("");
  const [toDateErr, setToDateError] = useState("");
  const { userInfo, allPatients, allProviders } = useContext(BaseContext);

  useEffect(() => {
    if (allPatients) setPatients(allPatients);
  }, [allPatients]);

  useEffect(() => {
    if (allProviders) setProviders(allProviders);
  }, [allProviders]);

  useEffect(() => {
    if (
      !_.isEqual(filterOptions, selectedFilterOptions) ||
      !_.isEqual(selectedUserOptions[0], selectedUserOption)
    )
      setFilterChange(true);
  }, [selectedFilterOptions, selectedUserOption]);

  const handleSelectedPatientsChange = (selectedPatients: Option[]) => {
    setSelectedFilterOption({
      ...selectedFilterOptions,
      patients: selectedPatients,
    });
  };

  const handleSelectedProvidersChange = (selectedProviders: Option[]) => {
    setSelectedFilterOption({
      ...selectedFilterOptions,
      providers: selectedProviders,
    });
  };
  
  // Check if the typed dates are of the corect format and valid
  useEffect(() => {
    const from = selectedFilterOptions.timeRange?.from;
    const to = selectedFilterOptions.timeRange?.to;
    const today = new Date();
  
    if (from && (isNaN(new Date(from).getTime()) || new Date(from) > today)) {
      setFromDateError(from);
    } else {
      setFromDateError("");
    }
  
    if (to && (isNaN(new Date(to).getTime()) || new Date(to) > today)) {
      setToDateError(to);
    } else {
      setToDateError("");
    }
  }, [selectedFilterOptions.timeRange]);

    //Function to check if in the selected date filter the from date is before the to date
    const checkValidDate = (fromDate: string | undefined, toDate?: string | undefined): boolean => {
      if (fromDate && toDate) {
        return new Date(fromDate) < new Date(toDate);
      }
      return true;
    };  

  const handleFromTimeSelect = (from: string) => {
    setSelectedFilterOption({
      ...selectedFilterOptions,
      timeRange: {
        ...selectedFilterOptions.timeRange,
        from,
      },
    });
  };

  const handleToTimeSelect = (to: string) => {
    setSelectedFilterOption({
      ...selectedFilterOptions,
      timeRange: {
        ...selectedFilterOptions.timeRange,
        to,
      },
    });
  };

  //Show error message if there is an error in to/from date or if to date is previous to from date
  const showErrorMessage = () => !!toDateErr || !!fromDateErr || !checkValidDate(selectedFilterOptions.timeRange?.from, selectedFilterOptions.timeRange?.to);

  const handleApply = () => onApply(selectedFilterOptions, selectedUserOption);
  const handleClearAll = () => onClearAll();
  return (
    <div className={styles.filtersForm}>
      <div className={styles.filtersContainer}>
        <div className={clsx(!patientMessages && styles.providerSelect)}>
          <SearchSelect
            label="Care Team Members"
            placeholder="Search"
            LeftIcon={MagnifyingGlass}
            hint={
              !patientMessages
                ? "A member from the care team must be selected"
                : undefined
            }
            value={
              !patientMessages
                ? selectedUserOption
                : selectedFilterOptions.providers[0]
            }
            options={providers.map((provider) => ({
              providerId: provider.id,
              avatarUrl: provider.photo || "",
              name: provider.firstName + " " + provider.lastName,
              value: provider.id,
              secondaryText: provider.title,
            }))}
            onChange={
              !patientMessages
                ? (selectedOption: Option) =>
                    setSelectedUserOption(selectedOption)
                : (selectedOption: Option) =>
                    handleSelectedProvidersChange([selectedOption])
            }
          />
        </div>
        <AccordionGroup>
          {!patientMessages ? (
            <div>
              <Accordion
                title="Patients"
                tag={
                  selectedFilterOptions.patients.length > 0
                    ? {
                        content: `(${selectedFilterOptions.patients.length}) selected`,
                      }
                    : undefined
                }
              >
                <SearchSelect
                  value={selectedFilterOptions.patients}
                  onChange={handleSelectedPatientsChange}
                  options={patients.map((patient) => {
                    return {
                      avatarUrl: patient.photo || "",
                      name: patient.firstName + " " + patient.lastName,
                      value: patient.id,
                      premiumMember:
                        patient?.settings?.General?.Membership === "Premium",
                    };
                  })}
                />
              </Accordion>
            </div>
          ) : (
            <></>
          )}
          <Accordion
            title="Time Range"
            tag={
              selectedFilterOptions.timeRange.from ||
              selectedFilterOptions.timeRange.to
                ? {
                    content: [
                      selectedFilterOptions.timeRange.from?.replaceAll(
                        "-",
                        "/"
                      ) || "any",
                      selectedFilterOptions.timeRange.to?.replaceAll(
                        "-",
                        "/"
                      ) || "any",
                    ].join(" - "),
                  }
                : undefined
            }
          >
            <div className={styles.dateInputContainer}>
              <TextInput
                label="From"
                type="date"
                clearable
                placeholder="MM/DD/YYYY"
                value={selectedFilterOptions.timeRange?.from || ""}
                onChange={handleFromTimeSelect}
                RightIcon={CalendarBlank}
                error={!!fromDateErr}
                maxDate={new Date().toISOString().split('T')[0]}
              />
              <Body className={styles.dateInputSeparator} size="md">
                {"–"}
              </Body>
              <TextInput
                label="To"
                type="date"
                clearable
                placeholder="MM/DD/YYYY"
                value={selectedFilterOptions.timeRange?.to || ""}
                onChange={handleToTimeSelect}
                RightIcon={CalendarBlank}
                maxDate={new Date().toISOString().split('T')[0]}
                error={!fromDateErr && !checkValidDate(selectedFilterOptions.timeRange?.from, selectedFilterOptions.timeRange?.to)
                  || (!!selectedFilterOptions.timeRange?.to && !!toDateErr)}
                disabled={!!fromDateErr}
              />
            </div>
            <div className={styles.dateErrorMsgContainer}>
              {showErrorMessage() && (
                <Body size="sm" weight="regular" color="danger">
                  {(fromDateErr || toDateErr) ? DATE_NOT_VALID : TO_DATE_LESS_THAN_FROM}
                </Body>
              )}
            </div>
          </Accordion>
        </AccordionGroup>
      </div>
      <ButtonGroup>
        <Button
          type="secondary-gray"
          onClick={handleClearAll}
          label="Clear All"
          disabled={
            (!patientMessages
              ? selectedUserOptions[0].value === userInfo?.id &&
                selectedUserOption.value === userInfo?.id
              : _.isEmpty(selectedFilterOptions.providers)) &&
            (!patientMessages
              ? _.isEmpty(selectedFilterOptions.patients)
              : true) &&
            _.isEmpty(selectedFilterOptions.timeRange)
          }
        />
        <Button
          type="primary"
          onClick={handleApply}
          label="Apply Filters"
          disabled={
            (!patientMessages && !selectedUserOption?.value) || !filterChange || 
            !checkValidDate(selectedFilterOptions.timeRange?.from, selectedFilterOptions.timeRange?.to) ||
            !!fromDateErr || !!toDateErr
          }
        />
      </ButtonGroup>
    </div>
  );
};
