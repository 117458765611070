import { useOktaAuth } from "@okta/okta-react";
import { fetchMedications } from "../apis/medications";
import { Medication } from "../interfaces/medications";
import { CellProps } from "../../components/table/Table";
import format from "date-fns/format";
import { AlertContext } from "../context/context";
import { useContext } from "react";

export const useMedications = () => {
    const { oktaAuth } = useOktaAuth()
    const { pushAlert } = useContext(AlertContext)
    const accessToken = oktaAuth.getAccessToken()

    const getActiveMedications = async (userId: string, controller?: AbortController) => {
        if (accessToken && userId) {
            const _medications = await fetchMedications(userId, controller)
            if(!_medications){
              pushAlert("Failed to get patient's active medications", 'danger')
            }
            if(!_medications || _medications === 'cancelled') return []
            const activeMedications: Medication[] = _medications.active
            return activeMedications
        } else
            return []
    }

    const getInactiveMedications = async (userId: string, controller?: AbortController) => {
        if (accessToken && userId) {
            const _medications = await fetchMedications(userId, controller)
            if(!_medications){
              pushAlert("Failed to get patient's inactive medications", 'danger')
            }
            if(!_medications || _medications === 'cancelled') return []
            const inactiveMedications: Medication[] = _medications.inactive
            return inactiveMedications
        } else
            return []
    }

    const getFriendlyDate = (unfriendlyDate: string) => {
      //if date from med api is returned as something like 2021-11-12
      if(!unfriendlyDate.includes('T')) { unfriendlyDate = unfriendlyDate.concat('T00:00:00') }
      //if date from med api is returned as something like 2022-01-11T23:15:00Z (get rid of Z to avoid UTC problems)
      if(unfriendlyDate.includes('Z')) { unfriendlyDate = unfriendlyDate.slice(0, -1); }
      const date = new Date(unfriendlyDate)
      return format(date, 'MM/d/yyyy')
    }

    const initActiveTableData = (activeMeds: Medication[]) => {
      const tableData: CellProps[][] = []
      activeMeds.map(med => {
          const row = []
          row.push({mainText: med.name, caption: 'Prescribed: ' + (med.startDate != "" ? getFriendlyDate(med.startDate) : "")})
          med.instructions !== "" ? row.push({mainText: med.instructions, caption: 'Refill Requested: '})  : row.push("")
          tableData.push(row)
      })
      return tableData
    }

    const initInactiveTableData = (inactiveMeds: Medication[]) => {
      const tableData: CellProps[][] = []
      inactiveMeds.map(med => {
          const row = []
          row.push({mainText: med.name, caption: 'Prescribed: ' + (med.startDate != "" ? getFriendlyDate(med.startDate) : "")})
          med.instructions !== "" ? row.push({mainText: med.instructions, caption: 'Refill Requested: '})  : row.push("")
          tableData.push(row)
      })
      return tableData
    }

    const getActiveMedsTableData = async (userId: string, controller?: AbortController) => {
        if (accessToken) {
            const _medications = await getActiveMedications(userId, controller)
            const tableData: CellProps[][] = initActiveTableData(_medications)
            return tableData
        } else
            return []
    }

    const getInactiveMedsTableData = async (userId: string, controller?: AbortController) => {
        if (accessToken) {
            const _medications = await getInactiveMedications(userId, controller)
            const tableData: CellProps[][] = initInactiveTableData(_medications)
            return tableData
        } else
            return []
    }

    return { getActiveMedications, getInactiveMedications, getActiveMedsTableData, getInactiveMedsTableData }
}