import VideoChat from "../../containers/videoChat/callScreen/videoChat/VideoChat";
import { useLocation } from "react-router-dom";
import styles from './style.module.css'
import { PatientInfo } from "../../lib/interfaces/user";

export interface VideoCallProps {
  visitId: string
  patient: PatientInfo
  noCarePlan: boolean
  providerId: string
}

export const VideoCall = () => {
  const location = useLocation();
  const { visitId, patient, noCarePlan, providerId } = location.state as VideoCallProps;

  return (
    <div className={styles.videoCall}>
      <VideoChat visitId={visitId} patient={patient} noCarePlan={noCarePlan} providerId={providerId} />
    </div>
  );
};
