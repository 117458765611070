import { Tag, TagProps } from '../tag/Tag'
import { ReactChild, useEffect, useRef, useState } from 'react'
import { Body } from '../typography/body/Body'
import { Plus, Minus } from 'phosphor-react'
import clsx from 'clsx'
import styles from './style.module.css'

export interface AccordionProps {
  tag?: TagProps
  title: string
  children?: ReactChild | ReactChild[]
  className?: string
}

export const Accordion = ({
  tag,
  title,
  children,
  className
}: AccordionProps) => {
  const [expanded, toggleExpanded] = useState<boolean>(false)
  const [height, setHeight] = useState(0)
  const contentRef = useRef<HTMLDivElement>(null)

  const toggleAccordion = () => toggleExpanded(!expanded)

  useEffect(() => setHeight(contentRef.current?.clientHeight || 0), [children])

  return (
    <div className={clsx(styles.accordion, className)}>
      <button onClick={toggleAccordion} className={styles.titleBar}>
        <Body className={styles.title} size='md'>{title}</Body>
        {tag && <Tag {...tag} />}
        {!expanded ? (<Plus size={16} />) : (<Minus size={16} />)}
      </button>
      <div
        className={clsx(styles.contentContainer, expanded && styles.expanded)}
        style={{ maxHeight: expanded ? height : 0 }}
      >
        <div
          ref={contentRef}
          className={styles.content}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

interface AccordionGroupProps {
  children?: ReactChild | ReactChild[]
}

export const AccordionGroup = ({ children }: AccordionGroupProps) => (
  <div className={styles.accordionGroup}>
    {children}
  </div>
)