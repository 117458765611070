import clsx from "clsx";
import { Tag } from "../tag/Tag"
import { Body } from "../typography/body/Body";
import { Heading } from "../typography/heading/Heading";
import { Check, Prohibit } from 'phosphor-react';
import styles from './style.module.css'
import { ProviderInfo } from "../../lib/interfaces/user";

export interface RecordsAppointmentCardProps {
  date: string
  provider?: ProviderInfo
  appointmentType: string
  visitType: string
  insurance: boolean
  creditCard: boolean
  healthQuestionnaire: boolean
  consentForms: boolean
  onProviderClick: () => void
  className?: string
}

export const RecordsAppointmentCard = ({
  date,
  provider,
  appointmentType,
  visitType,
  insurance,
  creditCard,
  healthQuestionnaire,
  consentForms,
  onProviderClick,
  className
  }: RecordsAppointmentCardProps) => {
    
    return (
      <div>
        <div className={clsx(styles.base, className)}>
          <div className={styles.dateAndType}>
              <Heading className={styles.heading} type='03'>{date}</Heading>
              <div className={styles.apptDetails}>
                {/* general consult, annual physical, etc. */}
                <div className={styles.row}>
                  <Body size='md' weight='regular' color='secondary'>{appointmentType} with </Body>
                  <div onClick={() => {onProviderClick()}} style={{cursor: 'pointer'}}>
                    <Body size='md' weight='regular' color='link'>{provider?.name}</Body>
                  </div>
                </div>               
                {/* in person or virtual */}
                <Body size='md' weight='bold' color='secondary'>{visitType !== "" ? visitType.charAt(0).toUpperCase() + visitType.slice(1) + ' Visit' : ''}</Body>
              </div>
          </div>
          <div className={styles.tags}>
              <Tag className={styles.tag} content={"Insurance"} type={insurance ? 'success' : 'danger'} Icon={insurance ? Check : Prohibit} iconPosition={'right'}/>
              <Tag className={styles.tag} content={"Credit Card"} type={creditCard ? 'success' : 'danger'} Icon={creditCard ? Check : Prohibit} iconPosition={'right'}/>
              <Tag className={styles.tag} content={"Health Questionnaire"} type={healthQuestionnaire ? 'success' : 'danger'} Icon={healthQuestionnaire ? Check : Prohibit} iconPosition={'right'}/>
              <Tag content={"Consent Forms"} type={consentForms ? 'success' : 'danger'} Icon={consentForms ? Check : Prohibit} iconPosition={'right'}/>
          </div>
        </div>
      </div>
    )
}