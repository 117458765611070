import { Avatar } from "../avatar/Avatar"
import { PatientInfo, UserInfo } from "../../lib/interfaces/user"
import clsx from "clsx"
import styles from './style.module.css'

export type AvatarGroupSize = 'xs' | 'sm' | 'md'

type User = UserInfo | PatientInfo 
export interface AvatarGroupGeneralProps {
  users: User[]
  size?: AvatarGroupSize
  className?: string
}

export const AvatarGroup = ({
  users,
  size = 'sm',
  className
}: AvatarGroupGeneralProps) => {
  const shownUsers = users.slice(0, 3)

  let shownAvatars

  if (shownUsers.length === 1) {
    shownAvatars = (
      <Avatar
        user={shownUsers[0]}
        size={size} />
    )
  } else if (shownUsers.length === 2) {
    shownAvatars = (
      <>
        <div className={styles.row}>
          <Avatar
            className={clsx(styles.avatar, styles.avatar1of2)}
            user={shownUsers[0]}
            size={size} />
          <Avatar
            className={clsx(styles.avatar, styles.avatar2of2)}
            user={shownUsers[1]}
            size={size} />
        </div>
      </>
    )
  } else {
    shownAvatars = (
      <>
        <div className={styles.row} style={{alignItems: 'center'}}>
          <Avatar
            className={clsx(styles.avatar, styles.avatar1of3)}
            user={shownUsers[0]}
            size={size} />
          <Avatar
            className={clsx(styles.avatar, styles.avatar2of3)}
            user={shownUsers[1]}
            size={size} />
          <Avatar
            className={clsx(styles.avatar, styles.avatar3of3)}
            user={shownUsers[2]}
            size={size} />
          <div style={{paddingLeft: '4px'}}>
            {users.length > 3 ? <div>{[' +', users.length - 3].join('')}</div> : null}
          </div>
          </div>
      </>
    )
  }

  return (
    shownUsers.length === 1 ? (shownAvatars)
      : (
        <div className={clsx(styles.avatarGroup, styles[size], className)}>
          {shownAvatars}
        </div>
      )
  )

}