import styles from './style.module.css'
import clsx from "clsx";

type ToggleOption = {
  name: string,
  value: string
}

export interface ToggleProps {
  options: [ToggleOption, ToggleOption]
  onChange: (value: any) => void
  value: string
}

export const Toggle = ({ options, onChange, value }: ToggleProps) => (
  <div className={styles.toggleContainer}>
    <button
      className={clsx(styles.option, value === options[0].value && styles.selected)}
      onClick={() => onChange(options[0].value)}
    >
      {options[0].name}
    </button>
    <button
      className={clsx(styles.option, value === options[1].value && styles.selected)}
      onClick={() => onChange(options[1].value)}
    >
      {options[1].name}
    </button>
  </div >
)