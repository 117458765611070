import clsx from "clsx";
import { Body } from "../typography/body/Body";
import { AvatarLabelGroup } from "../avatarLabelGroup/AvatarLabelGroup";
import { Button, ButtonGroup } from "../button/Button";
import { Chart } from "../chart/Chart";
import { CalendarPlus, Chat } from "phosphor-react";
import { PatientCardInfo, ProviderInfo } from "../../lib/interfaces/user";
import {
  format,
  isToday,
  startOfDay,
  isWithinInterval,
  startOfToday,
  addDays,
  isTomorrow,
  startOfMonth,
  addWeeks,
  endOfWeek,
} from "date-fns";
import styles from "./style.module.css";
import { Visit } from "../../lib/interfaces/visits";
import { useEffect, useState } from "react";
import { useVisits } from "../../lib/hooks/useVisits";
import { ChartItem, useCarePlan } from "../../lib/hooks/useCarePlan";
import ProviderType from "../../lib/models/providerType";
import { usePatient } from "../../lib/hooks/usePatient";
import { ResponseData as Provider } from "../../lib/apis/provider";

export interface PatientCardProps {
  patient: PatientCardInfo;
  className?: string;
  user: ProviderInfo;
  newMsgLoading: boolean;
  onNewApptClick: (patient: PatientCardInfo) => void;
  onNewMsgClick: (patient: PatientCardInfo, template: boolean) => void;
  updatePatientVisits: (id: string, nextAppt: Visit, lastAppt: Visit) => void;
  updatePatientCareTeam: (id: string, careTeamIds: string[]) => void;
  refreshPatientCard?: boolean;
  resetRefresh?: () => void;
}

export const PatientCard = ({
  patient,
  className,
  user,
  newMsgLoading,
  onNewApptClick,
  onNewMsgClick,
  updatePatientVisits,
  updatePatientCareTeam,
  refreshPatientCard,
  resetRefresh,
}: PatientCardProps) => {
  const { findClosestFutureDateTime, getLastVisit } = useVisits();
  const { formatProgressData } = useCarePlan();
  const { getPatientDetails } = usePatient();

  const controller = new AbortController();

  const [nextAppointment, setNextAppointment] = useState<Visit>();
  const [lastAppointment, setLastAppointment] = useState<Visit>();
  const [careTeam, setCareTeam] = useState<string[]>();
  const [progressData, setProgressData] = useState<ChartItem[]>([]);
  const [chartStartDate, setChartStartDate] = useState<string>("");
  const [chartEndDate, setChartEndDate] = useState<string>("");
  const [visitRes, setVisitRes] = useState<string>();

  const getCardInfo = async ({ id: patientId }: PatientCardInfo) => {
    try {
      const { visits, careteam, progress } = await getPatientDetails(patientId);

      const upcomingVisit = findClosestFutureDateTime(visits.future);
      const lastVisit = getLastVisit(visits.past);

      setNextAppointment(upcomingVisit);
      setLastAppointment(lastVisit);

      const careteamIDs = careteam.map((c: Provider) => c.id);
      careteamIDs && setCareTeam(careteamIDs);

      if (progress) {
        const { progressData: { progress: formatedData } } = formatProgressData(progress);
        setProgressData(formatedData);

        const today = startOfToday();
        const endOfFourthWeek = endOfWeek(addWeeks(startOfMonth(today), 4));
        setChartStartDate(format(today, "MMM dd"));
        setChartEndDate(format(endOfFourthWeek, "MMM dd"));
      }

      setVisitRes("success");
    } catch (err) {
      setVisitRes("error");
    }
  };

  useEffect(() => {
    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (patient) getCardInfo(patient);
  }, [patient]);

  useEffect(() => {
    if (refreshPatientCard) {
      setNextAppointment(undefined);
      setLastAppointment(undefined);
      getCardInfo(patient);
      resetRefresh && resetRefresh();
    }
  }, [refreshPatientCard]);

  useEffect(() => {
    if (nextAppointment && lastAppointment) {
      updatePatientVisits(patient.id, nextAppointment, lastAppointment);
    }
  }, [nextAppointment, lastAppointment]);

  useEffect(() => {
    if (careTeam) updatePatientCareTeam(patient.id, careTeam);
  }, [careTeam]);

  const getAppointmentText = () => {
    if (!nextAppointment && !lastAppointment) {
      return undefined;
    }
    if (visitRes && visitRes === "error") {
      return "Failed to get patient's information";
    }
    const appt =
      nextAppointment &&
      nextAppointment.visitId !== "" &&
      nextAppointment.dateTime !== ""
        ? new Date(nextAppointment.dateTime)
        : undefined;
    //if the appointment is today
    if (appt && isToday(startOfDay(appt))) {
      return `Next Appointment: Today at ${format(appt, "h:mm a")}`;
    }
    //if the appointment is tomorrow
    else if (appt && isTomorrow(startOfDay(appt))) {
      return `Next Appointment: Tomorrow at ${format(appt, "h:mm a")}`;
    }
    //if the appointment is within a week from today
    else if (
      appt &&
      isWithinInterval(startOfDay(appt), {
        start: startOfToday(),
        end: addDays(startOfToday(), 7),
      })
    ) {
      return `Next Appointment: ${format(appt, "M/d/yy")}`;
    }
    //if they have a last appointment and no upcoming appointment within a week
    else if (
      lastAppointment &&
      lastAppointment.visitId !== "" &&
      lastAppointment.dateTime !== ""
    ) {
      return `Last Appointment: ${format(
        new Date(lastAppointment.dateTime),
        "M/d/yy"
      )}`;
    }
    return "noAppointments";
  };

  const appointmentText = getAppointmentText();

  const titleOverride: JSX.Element | undefined = appointmentText ? (
    appointmentText !== "noAppointments" ? (
      <>
        <div style={{ display: "flex" }}>
          {visitRes !== "error" ? (
            <>
              {appointmentText.charAt(0) === "N"
                ? "Next Appointment: "
                : "Last Appointment: "}
              <div className={styles.boldSubText}>
                {" " + appointmentText.substring(17)}
              </div>
            </>
          ) : (
            <>
              {"Last Appointment: "}
              <div className={styles.boldSubText}>{appointmentText}</div>
            </>
          )}
        </div>
      </>
    ) : (
      <>
        <div style={{ display: "flex" }}>
          {"Last Appointment: "}
          {user?.type &&
          (user.type === ProviderType.CareNavigator ||
            user.type === ProviderType.Admin) ? (
            <div className={styles.boldSubText}>
              {"None - "}
              <div
                onClick={() => {
                  onNewMsgClick(patient, true);
                }}
              >
                <Body
                  color="link"
                  size="sm"
                  weight="bold"
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  Offer Support
                </Body>
              </div>
            </div>
          ) : (
            <div className={styles.boldSubText}>{"None"}</div>
          )}
        </div>
      </>
    )
  ) : (
    <>...</>
  );

  return (
    <div className={clsx(styles.patientCard, className)}>
      <div className={styles.col}>
        <AvatarLabelGroup
          data-dd-privacy="mask" 
          users={[patient]}
          titleOverride={titleOverride}
          linkUser={true}
          openNewTab={false}
        />
        <div className={styles.buttonsAndTag}>
          <ButtonGroup className={styles.buttons}>
            <Button
              onClick={() => {
                onNewApptClick(patient);
              }}
              label={"New Appointment"}
              Icon={CalendarPlus}
              type="secondary-gray"
              size="small"
            />
            <Button
              onClick={() => {
                onNewMsgClick(patient, false);
              }}
              loading={newMsgLoading}
              label={"Message"}
              Icon={Chat}
              type="secondary-gray"
              size="small"
            />
          </ButtonGroup>
        </div>
      </div>
      <div className={styles.chart}>
        {progressData && progressData.length > 0 && (
          <Chart
            compact
            label="Overall Progress"
            boldLabel={false}
            labelColor={"link"}
            labelMargin={"6px"}
            caption={chartStartDate + "-" + chartEndDate}
            height={56}
            scrollY={false}
            data={progressData}
            animated={false}
          />
        )}
      </div>
    </div>
  );
};
