import { useMemo, useRef, useState } from 'react'
import clsx from 'clsx'
import { CaretRight, Minus, Plus } from 'phosphor-react'
import { Button } from '../button/Button'
import { Heading } from '../typography/heading/Heading'
import { Tag, TagProps } from '../tag/Tag'
import { v4 as uuidv4 } from 'uuid';
import { Body } from '../typography/body/Body'
import styles from './style.module.css'

export interface ActionCellProps {
  label: string,
  onClick: () => void
  linkOnly?: boolean
}

export interface WithCaptionProps {
  mainText: string,
  caption?: string
}

export type CellProps = string | ActionCellProps | TagProps | WithCaptionProps

export interface TableProps {
  columns: string[]
  data: CellProps[][]
  title?: string
  friendlyTitle?: string
  fullWidth?: boolean
}

export const Table = ({
  columns,
  data,
  title,
  friendlyTitle,
  fullWidth = true,
}: TableProps) => {
  const [expanded, setExpanded] = useState(false)
  const tableRef = useRef<HTMLTableElement>(null)

  const displayedData = useMemo(() => {
    if (expanded) return data
    else return data.slice(0, 5)
  }, [expanded, data])

  const matchedColumns = useMemo(() => {
    if(data.length > 0){
      if (data[0].length > columns.length) {
        return columns.concat(Array(data[0].length - columns.length).fill(''))
      } else return columns
    }
    else return columns
  }, [columns, data])

  return (
    <div className={clsx(styles.table, fullWidth && styles.fullWidth)}>

      {title && (
        <div className={styles.header}>
          <Heading type='03'>{title}</Heading>
        </div>
      )}

      <div data-dd-privacy="mask"  className={clsx(styles.tableContent, expanded && styles.expanded)}>
        <table ref={tableRef}>
          <tbody>
            <tr className={styles.labelRow}>
              {matchedColumns.map(column => (
                <th key={uuidv4()} className={styles.label}>{column}</th>
              ))}
            </tr>
            {displayedData.map(row => (
              <tr key={uuidv4()} className={styles.row}>
                {row.map(item => {
                  if (typeof item === 'string')
                    return (<td key={uuidv4()} className={styles.cell}>{item}</td>)
                  else if ('mainText' in item)
                    return (
                      <td key={uuidv4()} className={styles.cell}>
                        <Body size='sm'>{item.mainText}</Body>
                        <Body size='xs' color='secondary'>{item.caption}</Body>
                      </td>
                    )
                  else if ('content' in item)
                    return (<td key={uuidv4()} className={styles.cell}><Tag content={item.content} type={item.type} /></td>)
                  else if ('label' in item)
                    return (
                      <td key={uuidv4()} className={styles.cell}>
                        <div className={clsx(!item.linkOnly ? styles.actionContainer : styles.linkContainer)} onClick={item.onClick}>
                          <Body color='link' weight='bold' size='sm' style={{cursor: 'pointer'}}>
                            {item.label}
                          </Body>
                          {!item.linkOnly && <CaretRight size={16} style={{cursor: 'pointer'}}/>}
                        </div>
                      </td>
                    )
                })}
              </tr>
            ))}
          </tbody>
        </table>
        {displayedData.length === 0 && friendlyTitle &&
            <Body className={styles.noDataMessage} size='md' color='disabled'>No {friendlyTitle} to display</Body>
        }
        {displayedData.length === 0 && !friendlyTitle &&
          <Body className={styles.noDataMessage} size='md' color='disabled'>No data to display</Body>
        }
      </div>

      {data.length > 5 && (
        <div className={styles.footer}>
          <Button
            type='secondary-gray'
            size='small'
            onClick={() => setExpanded(!expanded)}
            label={expanded ? 'Collapse' : 'Expand'}
            Icon={expanded ? Minus : Plus}
          />
        </div>
      )}

    </div>
  )
}