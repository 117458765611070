import clsx from 'clsx'
import styles from './style.module.css'

export interface SwitchProps {
  active: boolean
  onToggle: () => void
  label?: string
  disabled?: boolean
  className?: string
}

export const Switch = ({
  active,
  onToggle,
  disabled = false,
  label,
  className
}: SwitchProps) => (
  <label className={clsx(styles.switchContainer, className)}>
    <div
      tabIndex={!disabled ? 0 : undefined}
      className={clsx(
        styles.switch,
        active && styles.active,
        disabled && styles.disabled
      )}>
      <div className={styles.switchIndicator} />
    </div>
    <input
      tabIndex={undefined}
      className={styles.switchInput}
      disabled={disabled}
      type='checkbox'
      checked={active}
      onChange={onToggle}
    />
    {label}
  </label>
)
