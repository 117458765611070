import _ from "lodash";
import { UserClaims } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { Client } from "@twilio/conversations";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Sidebar } from "../../components/sidebar/Sidebar";
import { AlertContext, BaseContext } from "../../lib/context/context";
import { Spinner } from "../../components/spinner/Spinner";
import { Messages, SelectedChat } from "../../pages/messages/Messages";
import { ChatView } from "../messaging/chatView/ChatView";
import { AvailabilityManagement } from "../../pages/availabilityManagement/AvailabilityManagement";
import { PatientMessages } from "../../pages/patientMessages/PatientMessages";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { useChats } from "../../lib/hooks/useChats";
import { Patients } from "../../pages/patient/Patient";
import { Notifications } from "../../pages/notifications/Notifications";
import { Schedule } from "../../pages/schedule/Schedule";
import { VideoCall } from "../../pages/videoChat/VideoCall";
import styles from "./style.module.css";
import { useAlerts } from "../../lib/hooks/useAlerts";
import { AlertBanner } from "../../components/alertBanner/AlertBanner";
import { PatientInfo, ProviderInfo } from "../../lib/interfaces/user";
import { useProvider } from "../../lib/hooks/useProvider";
import { usePatient } from "../../lib/hooks/usePatient";

import chatAPI from "../../lib/apis/chat";
import { useVisits } from "../../lib/hooks/useVisits";
import ProviderType from "../../lib/models/providerType";

const USER_TYPE = "provider";

export const Base = () => {
  const { oktaAuth } = useOktaAuth();
  const { getAllChats } = useChats();

  const { getProvider, getAllProviders } = useProvider();
  const { getAllPatients } = usePatient();
  const { alerts, pushAlert, closeAlert, clearAlerts } = useAlerts();

  const [popoutChat, setPopoutChat] = useState<SelectedChat[]>([]);
  const [user, setUser] = useState<UserClaims>();
  const [userInfo, setUserInfo] = useState<ProviderInfo>();
  const [unreadChats, setUnreadChats] = useState<string[]>([]);
  const [allPatients, setAllPatients] = useState<PatientInfo[]>();
  const [allProviders, setAllProviders] = useState<ProviderInfo[]>();
  const [twilio, setTwilio] = useState<Client>();
  const { getAllVisits } = useVisits();

  const { path } = useRouteMatch();
  const popoutChatRef = useRef<SelectedChat[]>([]);
  const selectedChatRef = useRef<SelectedChat>();
  const unreadChatsRef = useRef<string[]>([]);
  const { pathname } = useLocation();

  useEffect(() => {
    oktaAuth.getUser().then((_user) => {
      setUser(_user);
    });
    getAllProviders().then(setAllProviders);
  }, []);

  useEffect(() => {
    if (user) {
      getProvider(user.sub).then((_userInfo) => {
        setUserInfo(_userInfo);
      });
      initializeTwilio();
    }
  }, [user]);



  useEffect(() => {
    if (userInfo) {
      if (userInfo?.type === ProviderType.OutsideSpecialist) {
        getAllPatientsAssignedWithSpecialist(userInfo.id as string)
          .then(setPatientsListWithoutDuplicates)
          .catch((error) =>
            console.error("Error setting patients list for specialist:", error)
          );
      } else {
        getAllPatients()
          .then(setPatientsListWithoutDuplicates)
          .catch((error) =>
            console.error(
              "Error setting patients list for non-specialist:",
              error
            )
          );
      }
    }
  }, [userInfo]);

  const getAllPatientsAssignedWithSpecialist = async (providerId: string) => {
    const allPatients = await getAllPatients();

    const visitsWithProvider = await getAllVisits("", "", providerId);

    if (visitsWithProvider) {
      return allPatients.filter((patient) =>
        visitsWithProvider.find((visit) => visit.userId === patient.id)
      );
    }
    return [];
  };

  useEffect(() => {
    if (userInfo) {
      if (userInfo?.type === ProviderType.OutsideSpecialist) {
        getAllPatientsAssignedWithSpecialist(userInfo.id as string).then(
          setPatientsListWithoutDuplicates
        );
      } else {
        getAllPatients().then(setPatientsListWithoutDuplicates);
      }
    }
  }, [userInfo]);

  const setPatientsListWithoutDuplicates = (list: PatientInfo[]) => {
    setAllPatients(
      list.filter(
        (patient, index) =>
          index === list.findIndex((other) => patient.id === other.id)
      )
    );
  };

  useEffect(() => {
    clearAlerts();
  }, [pathname]);

  useEffect(() => {
    if (user && allPatients && allProviders) {
      getAllChats(user.sub, USER_TYPE).then((chats) => {
        if (chats) {
          const unreadChats = chats
            .filter((chat) => chat.unread)
            .map((chat) => chat.id);
          setUnreadChats(unreadChats);
          unreadChatsRef.current = unreadChats;
        }
      });
    }
  }, [user, allPatients, allProviders]);

  useEffect(() => {
    twilio?.on("conversationUpdated", ({ conversation, updateReasons }) => {
      //conversation.sid !== selectedChatRef.current?.id means if we just received a new message from a different chat
      //unreadChatsRef.current.includes(selectedChatRef.current?.id) means if we are reading an unread message from a different chat
      if (
        _.union(updateReasons, ["lastReadMessageIndex", "lastMessage"]) &&
        (conversation.sid !== selectedChatRef.current?.id ||
          unreadChatsRef.current.includes(selectedChatRef.current?.id))
      ) {
        if (
          conversation.lastReadMessageIndex !==
            conversation.lastMessage?.index &&
          !unreadChats.includes(conversation.sid)
        ) {
          setUnreadChats((currentUnreadChats) => [
            ...currentUnreadChats,
            conversation.sid,
          ]);
          unreadChatsRef.current = [
            ...unreadChatsRef.current,
            conversation.sid,
          ];
        } else if (
          conversation.lastReadMessageIndex === conversation.lastMessage?.index
        ) {
          setUnreadChats((currentUnreadChats) =>
            currentUnreadChats.filter((chatId) => chatId !== conversation.sid)
          );
          unreadChatsRef.current = unreadChatsRef.current.filter(
            (chatId) => chatId !== conversation.sid
          );
        }
      }
    });
  }, [twilio]);

  const initializeTwilio = async () => {
    chatAPI.fetchToken("chat", "").then((res) => {
      const token = res.token;
      if (token) {
        setTwilio(new Client(token));
      }
    });
  };

  const onChatSelect = (chat: SelectedChat) => {
    selectedChatRef.current = chat;
  };

  const openPopoutChat = (chat: SelectedChat, template?: string) => {
    if (!popoutChatRef.current.map((chat) => chat.id).includes(chat.id)) {
      popoutChatRef.current = [
        ...popoutChatRef.current,
        {
          ...chat,
          template: template ? template : "",
        },
      ];
      // setPopoutChat(popoutChat.concat({
      //   ...chat,
      //   template: template ? template : ''
      // }))
      setPopoutChat(popoutChatRef.current);
    }
  };

  const closePopoutChat = (chat: SelectedChat) => {
    if (popoutChat.map((chat) => chat.id).includes(chat.id)) {
      popoutChatRef.current = popoutChatRef.current.filter(
        (_chat) => _chat.id !== chat.id
      );
    }
    // setPopoutChat(popoutChat.filter(_chat => _chat.id !== chat.id))
    setPopoutChat(popoutChatRef.current);
  };

  const singlePatient = useCallback(
    (patientId: string) => {
      return allPatients?.find((patient) => patient.id === patientId) ?? null;
    },
    [allPatients]
  );

  const contextValue: BaseContext = {
    user,
    userInfo,
    allPatients,
    allProviders,
    openPopoutChat,
    closePopoutChat,
    popoutChat,
    twilio,
    singlePatient,
  };

  const alertContextValue = {
    pushAlert,
    clearAlerts,
  };

  return user ? (
    <BaseContext.Provider value={contextValue}>
      <AlertContext.Provider value={alertContextValue}>
        <div id="base" className={styles.base}>
          <div className={styles.sideBarContainer}>
            <Sidebar user={userInfo} messageBadge={unreadChats.length > 0} />
          </div>
          <div className={styles.pageContainer}>
            {alerts.length > 0 && (
              <div className={styles.alertContainer}>
                {alerts.map((alert) => (
                  <AlertBanner
                    type={alert.type}
                    message={alert.message}
                    key={alert.id}
                    onClose={
                      typeof alert.closeable === "undefined" || alert.closeable
                        ? () => closeAlert(alert.id)
                        : undefined
                    }
                    withIcon={alert.withIcon}
                  />
                ))}
              </div>
            )}
            <Switch>
              {/* <Route path={`${path}messages`} component={Messages} /> */}
              <Route
                path={`${path}messages`}
                render={() => <Messages onChatSelect={onChatSelect} />}
              />
              <Route
                path={`${path}patients/messages`}
                component={PatientMessages}
              />
              <Route
                path={`${path}availability`}
                component={AvailabilityManagement}
              />
              <Route path={`${path}patient`} component={Patients} />
              <Route path={`${path}notifications`} component={Notifications} />
              <Route path={`${path}scheduling`} component={Schedule} />
              <Route path={`${path}video`} component={VideoCall} />
              <Redirect path={path} to={`${path}scheduling`} />
            </Switch>
          </div>
          {popoutChat.length > 0 && (
            <div className={styles.popoutChats}>
              {popoutChat.map((chat) => (
                <div key={chat.id} className={styles.popoutChatContainer}>
                  <ChatView
                    chat={chat}
                    key={chat.id}
                    popout
                    message={chat.template}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </AlertContext.Provider>
    </BaseContext.Provider>
  ) : (
    <Spinner size={96} />
  );
};

export const useBaseContext = () => {
  return useContext(BaseContext);
};
