import { createContext } from "react"
import { UserClaims } from "@okta/okta-auth-js"
import { SelectedChat } from "../../pages/messages/Messages"
import { NotificationType } from "../../components/alertBanner/AlertBanner"
import { PatientInfo, ProviderInfo } from "../interfaces/user"
import { Client } from "@twilio/conversations"

export interface BaseContext {
    twilio: Client | undefined,
    user: UserClaims | undefined,
    userInfo: ProviderInfo | undefined,
    allPatients: PatientInfo[] | undefined,
    singlePatient: (patientId: string) => PatientInfo | null,
    allProviders: ProviderInfo[] | undefined,
    openPopoutChat?: (chat: SelectedChat, template?: string) => void
    closePopoutChat?: (chat: SelectedChat) => void
    popoutChat?: SelectedChat[]
}

export const BaseContext = createContext<BaseContext>({
    twilio: undefined,
    user: undefined,
    userInfo: undefined,
    allPatients: undefined,
    allProviders: undefined,
    openPopoutChat: undefined,
    closePopoutChat: undefined,
    popoutChat: [],
    singlePatient: () => { return null }
})

export interface AlertContext {
    pushAlert: (message: string, type?: NotificationType, closeable?: boolean, withIcon?: boolean) => void
    clearAlerts: () => void
}

export const AlertContext = createContext<AlertContext>({
    pushAlert: () => undefined,
    clearAlerts: () => undefined
})