import { useMemo, useState } from 'react'
import { FeaturedIcon } from '../../../components/featuredIcon/FeaturedIcon'
import { Heading } from '../../../components/typography/heading/Heading'
import { Body } from '../../../components/typography/body/Body'
import { Chart } from '../../../components/chart/Chart'
import { Toggle } from '../../../components/toggle/Toggle'
import { ChartBar } from 'phosphor-react'
import styles from './style.module.css'
import { ChartItem } from '../../../lib/hooks/useCarePlan'
import { getMonth } from 'date-fns'

type DataInterval = 'week' | 'month'

interface ProgressActionPlanProps {
  progress: ChartItem[]
}

export const ProgressActionPlan = ({ progress }: ProgressActionPlanProps) => {
  const [interval, setInterval] = useState<DataInterval>('week')

  const weeklyData = useMemo(() => progress || [], [progress])
  const monthlyData = useMemo(() => {
    const filteredProgressData: ChartItem[] = []

    progress.forEach((item, i) => {
      if (filteredProgressData.length === 0)
        filteredProgressData.push(item)
      else if (getMonth(progress[i + 1]?.date) !== getMonth(item.date))
        filteredProgressData.push(item)
    })
    return filteredProgressData
  }, [progress])

  const data = useMemo(() => {
    if (interval === 'week') return weeklyData
    else return monthlyData
  }, [, weeklyData, monthlyData, interval])

  return (
    <div className={styles.progressSection}>
      <div className={styles.header}>
        <FeaturedIcon Icon={ChartBar} type='purple' iconColor='#734EDA' />
        <div>
          <Heading type='02'>Progress</Heading>
          <Body className={styles.caption} size='md' weight='medium' color='secondary'>Patient progress on check-ins</Body>
        </div>
      </div>
      <div className={styles.content}>
        {progress.length > 0 ? (
          <>
            <div className={styles.title}>
              <Heading type='03'>Progress</Heading>
              <Toggle
                value={interval}
                options={[
                  { name: 'W', value: 'week' },
                  { name: 'M', value: 'month' },
                ]}
                onChange={setInterval} />
            </div>
            <Chart data={data} isWeekly={interval === 'week'} />
          </>) : (
          <Body size='md' color='secondary'>Data will show here once Care Plan is published</Body>
        )}
      </div>
    </div>
  )
}