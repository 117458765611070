const config = {
	auth: {
		baseUrl: process.env.REACT_APP_OKTA_BASE_URL,
		clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
		issuer: process.env.REACT_APP_OKTA_ISSUER, 
		redirectUri: `${window.location.origin}/login/callback`,
		scopes: ['openid', 'profile', 'email', 'offline_access'],
		pkce: true,
	}
};

export default config