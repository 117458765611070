import { useEffect, useState } from "react";
import Video from "twilio-video";
import { RemoteParticipant, Track } from "twilio-video";
import Participant from "../participant/Participant";
import styles from "./style.module.css";
import { Avatar } from "../../../../components/avatar/Avatar";
import { FeaturedIcon } from "../../../../components/featuredIcon/FeaturedIcon";
import { Spinner } from "../../../../components/spinner/Spinner";
import { Body } from "../../../../components/typography/body/Body";
import { Heading } from "../../../../components/typography/heading/Heading";
import { Modal } from "../../../../components/modal/Modal";
import { Button, ButtonGroup } from "../../../../components/button/Button";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { PhoneDisconnect, Microphone, MicrophoneSlash, VideoCamera, VideoCameraSlash } from "phosphor-react";
import { BaseContext } from "../../../../lib/context/context";
import { PatientInfo } from "../../../../lib/interfaces/user";

interface RoomProps {
  roomName: string,
  room: Video.Room,
  handleLogout: (roomToLeave?: Video.Room) => void,
  permissionError: boolean,
  patient: PatientInfo,
  noCarePlan: boolean,
  showStats: () => void
}

const Room = ({
  roomName,
  room,
  handleLogout,
  permissionError,
  patient,
  noCarePlan,
  showStats
}: any) => {
  const [participant, setParticipant] = useState<RemoteParticipant | null>(null);
  const [audioMuted, setAudioMuted] = useState<boolean>(false);
  const [videoMuted, setVideoMuted] = useState<boolean>(false);
  const [leaveCall, setLeaveCall] = useState<boolean>(false);
  const [reconnecting, setReconnecting] = useState<boolean>(false);
  const [participantVideo, setParticipantVideo] = useState<boolean>(true); //switch to check first
  const [participantAudio, setParticipantAudio] = useState<boolean>(true); //switch to check first
  const [prevPath, setPrevPath] = useState<string>('');
  //THIS IS ADDING THE NAV BAR- HOW TO REMOVE?
  const { user, userInfo } = useContext(BaseContext);

  const history = useHistory();
  const localParticipant = room.localParticipant;
  const onCancel = () => {
    setLeaveCall(false);
  };

  const onConfirmLeave = () => {
    setLeaveCall(true);
  };

  useEffect(() => {
    if (typeof history.location.state === 'object' && history.location.state !== null) {
      const state = history.location.state as { from: string };
      setPrevPath(state.from);
    }
  }, []);

  const handleLogoutExit = () => {
    handleLogout();
    showStats(true);
    const visitId = roomName;
    // redirect to the path the video call was joined from
    const redirectionPath = prevPath === '/patient' ? `/patient/${patient.userId}/careplan` : 'scheduling';
    history.push(redirectionPath,
      { visitId, patient, noCarePlan, postCall: true });
    // history.goBack();
    //open appt detail drawer after appt
  };

  const handleCancel = () => {
    //go back to pre-appt detail drawer screen.
    history.goBack();
  };

  useEffect(() => () => handleLogout(room), [])


  useEffect(() => {
    const participantConnected = (participant: RemoteParticipant) => {
      const patientParticipant = 'patient';
      if(participant.identity.includes(patientParticipant)){
        setParticipant(participant);
      }

      participant.on("trackSubscribed", (track) => {

        if (track.kind === "video") {
          setParticipantVideo(true)
        }
      })

      participant.on("trackUnsubscribed", (track) => {

        if (track.kind === "video") {
          setParticipantVideo(false)
        }
      })

      participant.on("trackDisabled", (track) => {
        if (track.kind === "audio") {
          setParticipantAudio(false);
        }
      });
      participant.on("trackEnabled", (track) => {
        if (track.kind === "audio") {
          setParticipantAudio(true);
        }
      });

    };
    const participantDisconnected = (participant: any) => {
      setParticipant(null);
    };

    //CONNECTION ISSUES
    const participantReconnecting = (participant: any) => {
      setReconnecting(true);
    };
    const participantReconnected = (participant: any) => {
      setReconnecting(false);
      setParticipant(participant);
      reconnectLocalParticipant();
    };

    room.on("participantConnected", participantConnected);
    room.on("participantDisconnected", participantDisconnected);
    room.on("participantReconnecting", participantReconnecting);
    room.on("participantReconnected", participantReconnected);

    room.participants.forEach(participantConnected);
    return () => {
      room.off("participantConnected", participantConnected);
      room.off("participantDisconnected", participantDisconnected);
    };
  }, [room]);

  // Function to reconnect the local participant
  async function reconnectLocalParticipant() {
    try {
      // Unpublish the local tracks from the room
      localParticipant.tracks.forEach((trackPublication:any) => {
        room.localParticipant.unpublishTrack(trackPublication.track);
      });

      // Publish the local video and audio tracks back to the room
      const localVideoTrack = Array.from(localParticipant.videoTracks.values())[0];
      const localAudioTrack = Array.from(localParticipant.audioTracks.values())[0];
      await room.localParticipant.publishTrack(localVideoTrack);
      await room.localParticipant.publishTrack(localAudioTrack);
    } catch (error) {
      console.error('Error reconnecting local participant:', error);
    }
  }

  useEffect(()=>{
    localParticipant.on('reconnecting', (participant: any) => {
      setReconnecting(true);
    });

    localParticipant.on('reconnected', (participant: any) => {
      reconnectLocalParticipant();
      setReconnecting(false);
    });

    localParticipant.on('disconnected', async (participant: any) => {
      await new Promise(resolve => setTimeout(resolve, 2000));
      if (participant.state === 'disconnected') {
       
        handleLogout(room);
      }
    });
    
  },[room])

  //AUDIO SETTINGS
  const toggleAudio = async () => {
    if (audioMuted) {
      await Video.createLocalAudioTrack().then(async function (localTrack) {
        await room.localParticipant.publishTrack(localTrack);
      });
      setAudioMuted(false);
    } else {
      room.localParticipant.audioTracks.forEach(
        (publication: any) => {
          publication.track.stop();
          publication.unpublish();
        }
      );
      setAudioMuted(true);
    }
  };

  //VIDEO SETTINGS
  const toggleVideo = async () => {
    if (videoMuted) {
      await Video.createLocalVideoTrack().then(async function (localTrack) {
        await room.localParticipant.publishTrack(localTrack);
      });
      setVideoMuted(false);
    } else {
      room.localParticipant.videoTracks.forEach(
        (publication: any) => {
          publication.track.stop();
          publication.unpublish();
        }
      );
      setVideoMuted(true);
    }
  };

  return (
    <div className={styles.room}>
      <div className={styles.greyBox}>
        {/* Patient Video */}
        {participant &&
          <>
            <div className={styles.patientVidContainer}>
              <div className={styles.patientVideoBox}>
                <Participant data-dd-privacy="mask" videoOn={participantVideo} participant={participant} />
              </div>
              
              { participantAudio ? (
                <PatientLabelAudioOn  data-dd-privacy="mask" patient={patient} />
              ) : (
                <PatientLabelAudioMuted  data-dd-privacy="mask" patient={patient} />
              )}
            </div>

          </>
        }
        {/* Provider Video */}
        {participant ? (
          videoMuted ? (
            <div
              className={styles.providerVideo}
              style={{ background: "#766F6B" }}
            >
              <Avatar size="xxl" user={userInfo} />
              <Body
                data-dd-privacy="mask" 
                weight="regular"
                size="sm"
                color="white"
                className={styles.providerName}
              >
                {user && user.given_name + " " + user.family_name}
              </Body>
            </div>
          ) : (
            <div className={styles.providerVideo}>
              <Participant  data-dd-privacy="mask"  videoOn={!videoMuted} participant={room.localParticipant} mirrored={true} />
                <Body
                  data-dd-privacy="mask" 
                  weight="regular"
                  size="sm"
                  color="white"
                  className={styles.providerName}
                >
                {user && user.given_name + " " + user.family_name}
              </Body>
            </div>
          )
        ) : videoMuted ? (
          <div
            className={styles.providerVideo}
            style={{ background: "#766F6B" }}
          >
            <Avatar size="xxl" user={userInfo} />
            <Body
              data-dd-privacy="mask" 
              weight="regular"
              size="sm"
              color="white"
              className={styles.providerName}
            >
              {user && user.given_name + " " + user.family_name}
            </Body>
          </div>
        ) : (
          <div className={styles.providerVideo}>
            <Participant  data-dd-privacy="mask" videoOn={!videoMuted} participant={room.localParticipant} mirrored={true} />
              <Body
                data-dd-privacy="mask" 
                weight="regular"
                size="sm"
                color="white"
                className={styles.providerName}
              >
              {user && user.given_name + " " + user.family_name}
            </Body>
          </div>
        )}
      </div>

      <div className={styles.buttonRow}>
        <div onClick={toggleAudio}>
          {audioMuted ? (
            <FeaturedIcon
              Icon={MicrophoneSlash}
              size="lg"
              type="gray"
              iconColor="white"
              backgroundColor="#D92D20"
            />
          ) : (
            <FeaturedIcon
              Icon={Microphone}
              size="lg"
              type="gray"
              iconColor="white"
              backgroundColor="#514C48"
            />
          )}
        </div>
        <div onClick={toggleVideo}>
          {videoMuted ? (
            <FeaturedIcon
              Icon={VideoCameraSlash}
              size="lg"
              type="gray"
              iconColor="white"
              backgroundColor="#D92D20"
            />
          ) : (
            <FeaturedIcon
              Icon={VideoCamera}
              size="lg"
              type="gray"
              iconColor="white"
              backgroundColor="#514C48"
            />
          )}
        </div>

        <div onClick={onConfirmLeave}>
          <FeaturedIcon
            Icon={PhoneDisconnect}
            size="lgOval"
            type="gray"
            iconColor="white"
            backgroundColor="#D92D20"
          />
        </div>
        {process.env.REACT_APP_SENTRY_ENV !== "production" && <Button onClick={() => showStats()} type="secondary-gray" label="Room Monitior" />}
      </div>

      <Modal
        onCloseModal={() => { }}
        dismissable={false}
        title="End Visit"
        visible={leaveCall}
      >
        <div style={{ overflow: "auto" }}>
          <Body>Are you sure you want to finish your visit?</Body>
          <div style={{ height: 32 }} />
          <ButtonGroup align="end">
            <Button onClick={onCancel} type="secondary-gray" label="Cancel" />
            <Button onClick={handleLogoutExit} label="Yes, end visit" />
          </ButtonGroup>
        </div>
      </Modal>

      <Modal onCloseModal={() => { }} dismissable={false} visible={reconnecting}>
        <Heading
          style={{ textAlign: "center", paddingBottom: "16px" }}
          type="02"
        >
          Reconnecting...
        </Heading>
        <Body size="md">
          You lost your network connection. Trying to reconnect.
        </Body>
        <Spinner size={88} type="thin" />
        <div
          style={{
            height: 160,
          }}
        />
      </Modal>

      <Modal
        onCloseModal={() => { }}
        dismissable={false}
        visible={permissionError}
      >
        <Heading style={{ paddingBottom: "16px" }} type="02">
          Camera and microphone are blocked
        </Heading>
        <Body size="md">
          Hoag Compass requires access to your camera and microphone. Check your browser settings to allow access.
        </Body>
        <div style={{ height: 32 }} />
        <ButtonGroup align="end">
          <Button onClick={() => window.location.reload()} type="secondary-gray" label="Dismiss" />
        </ButtonGroup>
      </Modal>
    </div>
  );
};

export default Room;

interface PatientLabelAudioMutedProps {
  patient: PatientInfo
}

const PatientLabelAudioMuted = ({ patient }: PatientLabelAudioMutedProps) => {
  return (
    <div className={styles.patientLabel}>
      <FeaturedIcon
        Icon={MicrophoneSlash}
        type="transparent"
        iconColor="white"
        size="xl"
      />
      <div>
        <Heading  data-dd-privacy="mask" type="03" style={{ color: "white" }}>
          {patient.name}
        </Heading>
        <Body
          weight="regular"
          size="sm"
          color="white"
          style={{ opacity: "0.6" }}
        >
          PATIENT
        </Body>
      </div>
    </div>
  );
};

interface PatientLabelAudioOnProps {
  patient: PatientInfo
}

const PatientLabelAudioOn = ({ patient }: PatientLabelAudioOnProps) => {
  return (
    <div className={styles.patientLabel}>
      <Heading  data-dd-privacy="mask" type="03" style={{ color: "white" }}>
        {patient.name}
      </Heading>
      <Body weight="regular" size="sm" color="white" style={{ opacity: "0.6" }}>
        PATIENT
      </Body>
    </div>
  );
};
