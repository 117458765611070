import { useOktaAuth } from "@okta/okta-react";
import { fetchPatientTests, Test, Comment } from "../apis/tests";
import { CellProps } from "../../components/table/Table";
import { AlertContext } from "../context/context";
import { useContext } from "react";

export const useTests = () => {
    const { oktaAuth } = useOktaAuth()
    const { pushAlert } = useContext(AlertContext)
    const accessToken = oktaAuth.getAccessToken()

    const getTests = async (userId: string) => {
        if (accessToken) {
            const _tests = await fetchPatientTests(userId)
            if(!_tests){
              pushAlert("Failed to get patient's test results", 'danger')
              return []
            }
            return _tests
        } else
            return []
    }

    const getResultValuesTableData = async (userId: string, orderId: string) => {
      const resultTableData: CellProps[][] = []
      if (accessToken) {
          const _tests = await getTests(userId)
          _tests.map((test: Test) => {
              if(test.id === orderId){
                  test?.components && test.components.map((result) => {
                    const row: CellProps[] = []
                    row.push(result.name)
                    row.push(result.unit)
                    row.push(result.value)
                    row.push(result.range)
                    resultTableData.push(row)
                  })
              }
          })
          return resultTableData
      }
      else return []
    }

    const getTestResultNotes = async (userId: string, orderId: string) => {
        if (accessToken) {
            const _tests = await getTests(userId)
            _tests.map((test: Test) => {
                if(test.id === orderId && test?.comments){
                  return test.comments
                }
            })
        }
        else return ""
    }

    const getTestResultData = async (userId: string, orderId: string) => {
        if (accessToken) {
            const _tests = await getTests(userId)
            //extract notes
            let notes: Comment[] = []
           _tests.map((test: Test) => {
                if(test.id === orderId && test?.comments){
                  if(typeof test.comments !== 'string') notes = test.comments
                }
            })
            //extract table data 
            const tableData: CellProps[][] = []
            _tests.map((test: Test) => {
                if(test.id === orderId){
                    test?.components && test.components.map((result) => {
                      const row: CellProps[] = []
                      row.push(result.name)
                      row.push(result.unit)
                      row.push(result.value)
                      row.push(result.range)
                      tableData.push(row)
                    })
                }
            })
            return {
              tableData: tableData,
              notes: notes
            }
        }
        else return  {
          tableData: [],
          notes: []
        }
    }

    return { getTests, getTestResultData }
}