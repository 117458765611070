import clsx from "clsx"
import { useState, useMemo } from "react"
import { Tag } from "../tag/Tag"
import { Body } from "../typography/body/Body"
import { Heading } from "../typography/heading/Heading"
import { AvatarLabelGroup } from "../avatarLabelGroup/AvatarLabelGroup"
import { Switch } from "../switch/Switch"
import { PatientInfo, ProviderInfo } from "../../lib/interfaces/user"
import { Modal } from "../modal/Modal"
import { UserDetails } from "../userDetails/UserDetails"
import styles from './style.module.css'

export interface NewAppointmentCardProps {
  patient: PatientInfo,
  date: string
  provider: ProviderInfo
  appointmentType: string
  visitType: string
  // atmanMember: boolean
  hasCarePlan?: boolean
  className?: string
  inPersonOnly: boolean
  handleVirtualVisitToggle: (toggle: boolean) => void,
}

export const NewAppointmentCard = ({
  patient,
  date,
  provider,
  appointmentType,
  visitType,
  className,
  // atmanMember,
  hasCarePlan,
  inPersonOnly,
  handleVirtualVisitToggle,
}: NewAppointmentCardProps) => {
  const [toggle, setToggle] = useState(false)
  const [showProviderModal, setShowProviderModal] = useState(false)

  const titleOverride = useMemo(() => (
    <>
      <div style={{ display: "flex" }}>
        <Body size='sm' color='secondary'>{appointmentType} with </Body>
        <div onClick={() => { setShowProviderModal(true) }} style={{ cursor: 'pointer' }}>
          <Body size='sm' color='link'>{provider.name}</Body>
        </div>
      </div>
      <Body size='sm' weight='bold' color='secondary'>{visitType} Visit</Body>
    </>
  ), [provider,appointmentType,visitType])

  const onToggle = () => {
    handleVirtualVisitToggle(!toggle)
    setToggle(!toggle)
  }

  return (
    <div>
      <div className={clsx(styles.base, className)}>
        <div className={styles.row}>
          <Heading type='03' style={{ width: 417, height: 22, paddingRight: 14 }}>{date}</Heading>
          <Switch active={visitType === "Virtual"} onToggle={() => { onToggle() }} label='Virtual Visit' disabled={inPersonOnly} />
        </div>
        <div className={styles.row}>
          <AvatarLabelGroup data-dd-privacy="mask" users={[patient]} titleOverride={titleOverride} linkUser={true} openNewTab={true} />
          <div className={styles.tags}>
            {/* <div className={styles.tag}>{ atmanMember && <Tag content='Atman Cohort Member'/> }</div> */}
            {!hasCarePlan && <Tag content='No Care Plan' />}
          </div>
        </div>
      </div>
      <Modal
        onCloseModal={() => setShowProviderModal(false)}
        visible={showProviderModal}
        title={'Provider Detail'}
        centerTitle={true}
        className={styles.modal}
      >
        <UserDetails provider={provider} />
      </Modal>
    </div>
  )
}