import axios, { AxiosResponse } from 'axios'

interface Recipient {
  type: string,
  id: string,
  name: string
}

export interface ResponseData {
  id: string,
  subjectType: string,
  subjectId: string,
  type: string,
  resourceId: string,
  action: string,
  name: string,
  displayName: string,
  title: string,
  description: string,
  creatorType: string,
  creatorId: string,
  createdOn: string,
  recipients: Recipient[]
}

interface NotificationsResponse extends AxiosResponse {
  data: ResponseData[]
}

export const fetchAllProviderNotifs = async (recipientId: string, minDate: Date, controller?: AbortController) => {
  const result = await axios.get<NotificationsResponse, NotificationsResponse>(
    '/notifications',
    {
      params: { recipientId, minDate },
      signal:  controller ? controller.signal : undefined
    }).catch(err => { 
      if(axios.isCancel(err)) return {data: 'cancelled'}
      else return err
    })
  return result.data
}

export const fetchAllNotifs = async (minDate: Date, controller?: AbortController) => {
  const result = await axios.get<NotificationsResponse, NotificationsResponse>(
    '/notifications',
    {
      params: { minDate },
      signal:  controller ? controller.signal : undefined
    }).catch(err => { 
      if(axios.isCancel(err)) return {data: 'cancelled'}
      else return err
    })
  return result.data
}