import { useState } from 'react'
import { Eye, EyeSlash } from 'phosphor-react';
import { useAlerts } from '../../lib/hooks/useAlerts';
import { AlertBanner } from '../../components/alertBanner/AlertBanner';
import { TextInput } from '../../components/textInput/TextInput';
import { Button } from '../../components/button/Button';
import { useOktaAuth } from '@okta/okta-react';
import image from '../../lib/images/logo.png'
import styles from './style.module.css'
import { logError } from '../../lib/util/logger';

export default function Login() {
  const { oktaAuth } = useOktaAuth()
  const { alerts, pushAlert } = useAlerts()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [passwordInputType, setPasswordInputType] = useState('password')
  const [sessionToken, setSessionToken] = useState(null);

  const onUsernameChange = (e) => setUsername(e)
  const onPasswordChange = (e) => setPassword(e)
  const onSubmit = async () => {

    await oktaAuth
      .signInWithCredentials({ username, password })
      .then(res => {
        const sessionToken = res.sessionToken
        setSessionToken(sessionToken)

        oktaAuth.signInWithRedirect({ sessionToken })
      })
      .catch(err => {
        logError('Login - oktaAuth', { userName: username}, err);
        pushAlert('That email and password combination is incorrect.', 'danger')
      });
  }

  const handleKeyPress = (e) => {
    if(e.key === 'Enter'){
      onSubmit()
    }
  }

  return (sessionToken ? null :
    <div>
      {alerts.length > 0 && (
        <div>
          {alerts.map(alert => (<AlertBanner type={alert.type} message={alert.message} withIcon={false} key={alert.id} />))}
        </div>
      )}
      <div className={styles.loginPage}>
        <img className={styles.logo} src={image} alt="Hoag Compass Logo"></img>
        <div>
          <TextInput className={styles.username} label='Username' type='text' onChange={onUsernameChange} onKeyPress={handleKeyPress}/>
          <br />
          <TextInput 
            className={styles.password} 
            label='Password' 
            type={passwordInputType}
            onChange={onPasswordChange} 
            onKeyPress={handleKeyPress} 
            RightIcon={passwordInputType === 'password' ? Eye : EyeSlash} 
            onIconClick={() => passwordInputType === 'password' ? setPasswordInputType('text') : setPasswordInputType('password')}
          />
          <br />
          <Button className={styles.button} label='Log In' onClick={onSubmit} disabled={username === '' || password === ''} />
        </div>
      </div>
    </div>
  );
}