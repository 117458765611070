import _ from "lodash";
import { useOktaAuth } from "@okta/okta-react";
import chatAPI, { Chat, GetUserChatsArgs, Member } from "../apis/chat";
import { ChatPreview, Message, Participant } from "../interfaces/messaging";
import {
  Conversation,
  JSONObject,
  Message as TwilioMessage,
  Participant as TwilioParticipant,
} from "@twilio/conversations";
import { useContext } from "react";
import { AlertContext, BaseContext } from "../context/context";
import { useResources } from "./useResources";
import { Resource } from "../interfaces/resource";
import { types, typeInterface } from "./useProvider";
import { logError, logWarn } from "../util/logger";

export interface ChatsResponse {
  pageSize: number;
  currentPage: number;
  totalPages: number;
  nextPage: number | null;
  nextPageToken: string | null;
  chats: ChatPreview[];
}

// This all depends on Twilio availability. if the top before a 429 is 1000 per some time, then probably is
//worthy to make the calculation of GET_ALL_CHATS_PAGE_SIZE * MAX_CONCURRENT_REQUESTS < TWILIO_ALLOWED_REQ?
const GET_ALL_CHATS_PAGE_SIZE = 20;
const MAX_CONCURRENT_REQUESTS = 100;
const DEFAULT_CHATS_RESPONSE = {
  chats: [],
  currentPage: 0,
  pageSize: 0,
  totalPages: 0,
  nextPage: null,
  nextPageToken: null,
};

export const useChats = () => {
  const { oktaAuth } = useOktaAuth();
  const { getMetadata, getCustomResourceUrl } = useResources();
  const { user, allPatients, allProviders, twilio } = useContext(BaseContext);
  const { pushAlert } = useContext(AlertContext);
  const accessToken = oktaAuth.getAccessToken();

  const getParticipantsFromApiReponse = async (members: Member[]) => {
    let regarding: Participant | undefined;
    const participants: (Participant | false)[] = await Promise.all(
      members.map(async (member) => {
        if (member.identity.startsWith("patient_")) {
          const id = member.identity.replace("patient_", "");
          const _member = _.find(allPatients, (patient) => {
            return patient.id === id;
          });
          return {
            id: id,
            firstName: _member ? _member.firstName : "",
            lastName: _member ? _member.lastName : "",
            name: _member
              ? [_member.firstName, _member.lastName].join(" ")
              : "",
            title: "Patient",
            participantId: member.id,
            lastReadMessageIndex: member.lastReadMessageIndex,
          };
        } else if (member.identity.startsWith("provider_")) {
          const _member = _.find(allProviders, (provider) => {
            const providerId = member.identity.replace("provider_", "");
            return provider.id === providerId;
          });
          return {
            id: member.identity.replace("provider_", ""),
            firstName: _member ? _member.firstName : "",
            lastName: _member ? _member.lastName : "",
            name: _member
              ? [_member.firstName, _member.lastName].join(" ")
              : "",
            photo: _member ? _member.photoURL : "",
            // title: _member && _member.primarySpecialty.length > 0 ? _member.primarySpecialty : 'Placeholder',
            title:
              _member && _member.type
                ? types[_member.type as keyof typeInterface]
                : "Provider",
            participantId: member.id,
            lastReadMessageIndex: member.lastReadMessageIndex,
          };
        } else if (member.identity.startsWith("regarding_")) {
          const _member = _.find(allPatients, (patient) => {
            return patient.id === member.identity.slice(10);
          });
          regarding = {
            ..._member,
            id: member.identity.slice(10),
            firstName: _member ? _member.firstName : "",
            lastName: _member ? _member.lastName : "",
            title: "Patient",
            name: _member
              ? [_member.firstName, _member.lastName].join(" ")
              : "",
            participantId: member.id,
            lastReadMessageIndex: member.lastReadMessageIndex,
          };
          return false;
        } else return false;
      })
    );

    return {
      participants: participants.filter(Boolean) as Participant[],
      regarding,
    };
  };

  const formatChat = async (chat: Chat, id?: string) => {
    const userId = user?.sub || id;

    if (!chat) return undefined;

    const { participants, regarding } = await getParticipantsFromApiReponse(
      chat.members
    );
    const userAsMember = participants.filter(
      (participant) => participant.id === userId
    )[0];
    const _chat: ChatPreview = {
      id: chat.id,
      name: chat.name,
      latestMessage: chat.latestMessage,
      createdDate: chat.createdDate,
      updatedDate: chat.updatedDate,
      participants,
      unread: Boolean(
        chat.latestMessage &&
          !_.isEmpty(chat.latestMessage) &&
          userAsMember?.lastReadMessageIndex !== chat.latestMessage.index
      ),
      regarding,
    };

    return _chat;
  };

  const getAllChats = async (
    userId: string,
    type?: string,
    controller?: AbortController
  ): Promise<ChatPreview[]> => {
    let chatsAccumulator: ChatPreview[] = [];
    const firstPagePaginationInfo = {
      page: 1,
      pageSize: GET_ALL_CHATS_PAGE_SIZE,
    };

    const firstPageChats: ChatsResponse = await getUserChatsPerPage({
      userId: userId,
      type: type,
      controller: controller,
      paginationInfo: firstPagePaginationInfo,
    });

    chatsAccumulator = chatsAccumulator.concat(firstPageChats.chats);

    if (firstPageChats.totalPages > 1) {
      const chatRequests: Promise<ChatsResponse>[] = [];
      for (let i = 2; i <= firstPageChats.totalPages; i++) {
        const paginationInfo = {
          page: i,
          pageSize: GET_ALL_CHATS_PAGE_SIZE,
        };
        const requestPromise = getUserChatsPerPage({
          userId: userId,
          type: type,
          controller: controller,
          paginationInfo: paginationInfo,
        });
        chatRequests.push(requestPromise);
      }

      while (chatRequests.length) {
        const currentBatch: Promise<ChatsResponse>[] = chatRequests.splice(
          0,
          MAX_CONCURRENT_REQUESTS
        );
        const batchResults = await Promise.allSettled(currentBatch);

        batchResults.forEach((result) => {
          if (result.status === "fulfilled") {
            chatsAccumulator = chatsAccumulator.concat(result.value.chats);
          } else {
            pushAlert("Failed to get messages", "danger");
            return DEFAULT_CHATS_RESPONSE;
          }
        });
        // If really needed a delay can be introduced here
      }
    }
    return chatsAccumulator;
  };

  const getUserChatsPerPage = async (
    args: GetUserChatsArgs
  ): Promise<ChatsResponse> => {
    const newArgs: GetUserChatsArgs = {
      ...args,
      userId: args?.userId
    };

    if (newArgs.userId && accessToken) {
      const res = await chatAPI.fetchAllByPage(newArgs);
      if (!res) {
        pushAlert(
          "Failed to get messages. Please try refreshing the page.",
          "danger"
        );
        return DEFAULT_CHATS_RESPONSE;
      }
      if (!res || res.statusText === "cancelled") return DEFAULT_CHATS_RESPONSE;
      const allChats = res.chats;
      const chats: (ChatPreview | undefined)[] = await Promise.all(
        allChats?.map((chat: Chat) => formatChat(chat, newArgs.userId))
      );
      return {
        ...res,
        chats: chats.filter(Boolean) as ChatPreview[],
      };
    } else return DEFAULT_CHATS_RESPONSE;
  };

  const getUserChat = async (
    userId: string,
    type: string,
    chatId: string,
    controller?: AbortController
  ) => {
    if (userId && accessToken) {
      const res = await chatAPI.fetchOne(
        `${type}_${userId}`,
        chatId,
        accessToken,
        controller
      );
      if (!res) {
        pushAlert(
          "Failed to get messages. Please try refreshing the page.",
          "danger"
        );
      }
      if (!res || res === "cancelled") return [];
      const allChats = res.chats;
      const chats: (ChatPreview | undefined)[] = await Promise.all(
        allChats?.map((chat: Chat) => formatChat(chat, userId))
      );
      return chats.filter(Boolean) as ChatPreview[];
    } else return undefined;
  };

  const formatResourceMessage = async (message: Message) => {
    if (message.metadata?.linkPreviewUrl) {
      const metadata = await getMetadata(message.metadata.linkPreviewUrl);
      message.resource = metadata;
      // if (metadata.type === 'custom.articles') {
      if (metadata.type.includes("custom.")) {
        const urlArray = metadata.url.split("/");
        message.resource.id = urlArray[urlArray.length - 1];
      } else {
        message.resource.id = "";
      }
    }
    return message;
  };

  const getChatMessages = async (
    chatId: string,
    lastReadIndex?: number,
    controller?: AbortController
  ) => {
    if (accessToken) {
      const res = await chatAPI.fetch(chatId, lastReadIndex, controller);
      if (!res) {
        pushAlert("Failed to get chat messages", "danger");
      }
      if (!res || res === "cancelled") return [];
      const messages = res;
      return await Promise.all(messages.map(formatResourceMessage));
    } else {
      console.error("Twilio failed to fetch messages");
      return [];
    }
  };

  const updateLastReadMessageIndex = (
    chatId: string,
    participantId: string,
    messageIndex: number
  ) => {
    if (participantId && accessToken)
      chatAPI.updateLatestReadMessageIndex(chatId, participantId, messageIndex);
  };

  const createChat = async (
    regarding?: string,
    patient?: string,
    providers?: string[]
  ): Promise<ChatPreview | undefined> => {
    const userId = user?.sub;
    const patientMember =
      patient && !regarding ? { id: patient, role: "patient" } : undefined;
    const regardingMember = regarding
      ? { id: regarding, role: "regarding" }
      : undefined;
    const providerMembers = providers
      ? providers.map((provider) => ({ id: provider, role: "provider" }))
      : [];
    const members = [patientMember, regardingMember, ...providerMembers] // it's given that the userId (logged in Provider) is a member

      .filter(Boolean)
      .map((participant) => participant);

    if (accessToken && members.length > 0 && userId) {
      const chatData = { members };
      const newChat = await chatAPI.create(chatData);
      return await formatChat(newChat.data);
    }
  };

  const sendMessage = async (
    chatId: string,
    body: string,
    resources: Resource[]
  ) => {
    const userId = user?.sub;

    if (userId && accessToken) {
      let res;
      if (resources.length > 0) {
        const customUrlBase = await getCustomResourceUrl();
        if (customUrlBase)
          for (const resource of resources) {
            const _body = `${customUrlBase}${resource.id}`;
            res = await chatAPI.send(chatId, userId, _body);
            if (!(res.status >= 200 && res.status < 300)) break;
          }
      }

      if (
        (!res || (res.status >= 200 && res.status < 300)) &&
        body.trim().length > 0
      ) {
        res = await chatAPI.send(chatId, userId, body.trim());
      }

      return res;
    }
  };

  const formatTwilioMessage = async (
    message: TwilioMessage
  ): Promise<Message> => {
    //@ts-ignore
    const { linkPreviewUrl } = message.attributes;
    let resource;

    if (linkPreviewUrl) {
      const metadata = await getMetadata(linkPreviewUrl);
      resource = metadata;
    }

    return {
      id: message.sid,
      chatId: message.conversation.sid,
      index: message.index,
      author: message.author!,
      body: message.body!,
      resource,
      createdDate: message.dateCreated!.toString(),
      metadata: {
        linkPreviewUrl: (message.attributes as JSONObject)[
          "linkPreviewUrl"
        ] as string,
      },
    };
  };

  const formatTwilioMessages = async (messages: TwilioMessage[]) => {
    return await Promise.all(messages.map(formatTwilioMessage));
  };

  const formatTwilioConversation = async (
    conversation: Conversation
  ): Promise<ChatPreview> => {
    const { participants, regarding } = await getTwilioParticipants(
      await conversation.getParticipants()
    );
    return {
      id: conversation.sid,
      name: conversation.friendlyName!,
      createdDate: conversation.dateCreated?.toString() ?? "",
      updatedDate: conversation.dateUpdated?.toString() ?? "",
      participants,
      regarding,
    };
  };

  const getTwilioParticipants = async (participants: TwilioParticipant[]) => {
    let regarding: Participant | undefined;

    const _participants = await Promise.all(
      participants.map(async (participant) => {
        if (participant.identity!.startsWith("patient_")) {
          const _participant = _.find(allPatients, (patient) => {
            return patient.id === participant.identity!.slice(8);
          });
          if (!_participant) return undefined;
          return {
            firstName: _participant.firstName,
            lastName: _participant.lastName,
            name: _participant.name,
            title: "Patient",
            id: _participant.id,
            participantId: participant.sid,
            lastReadMessageIndex: participant.lastReadMessageIndex || -1,
          };
        } else if (participant.identity!.startsWith("provider_")) {
          const _participant = _.find(allProviders, (provider) => {
            return provider.id === participant.identity!.slice(9);
          });
          if (!_participant) return undefined;
          return {
            firstName: _participant.firstName,
            lastName: _participant.lastName,
            name: _participant.name,
            title:
              _participant && _participant.type
                ? types[_participant.type as keyof typeInterface]
                : "Provider",
            userId: _participant.id,
            id: _participant.id,
            participantId: participant.sid,
            lastReadMessageIndex: participant.lastReadMessageIndex || -1,
          };
        } else if (participant.identity!.startsWith("regarding_")) {
          const _participant = _.find(allPatients, (patient) => {
            return patient.id === participant.identity!.slice(10);
          });
          regarding = {
            ..._participant,
            id: participant.identity!.slice(10),
            firstName: _participant ? _participant.firstName : "",
            lastName: _participant ? _participant.lastName : "",
            name: _participant
              ? [_participant.firstName, _participant.lastName].join(" ")
              : "",
            title: "Patient",
            participantId: participant.sid,
            lastReadMessageIndex: participant.lastReadMessageIndex,
          };
          return false;
        } else return false;
      })
    );

    return {
      participants: _participants.filter(Boolean) as Participant[],
      regarding,
    };
  };

  interface ChatWithPatient {
    chat: ChatPreview | undefined;
    newChat?: boolean;
  }

  const getChatWithUsers = async (
    patientId?: string,
    providerIds?: string[],
    regardingId?: string
  ): Promise<ChatWithPatient | undefined> => {
    const userId = user?.sub;

    if (userId && accessToken) {
      let targetParticipantIds: string[] = [];
      if (patientId && !providerIds)
        targetParticipantIds = [patientId, userId].sort();
      else if (!patientId && providerIds)
        targetParticipantIds = [...providerIds, userId].sort();
      else if (patientId && providerIds)
        targetParticipantIds = [...providerIds, userId, patientId].sort();
      else if (!patientId && !providerIds) return undefined;
      const chats = await getAllChats(userId);
      if (chats) {
        if (regardingId) {
          for (let i = 0; i < chats.length; i++) {
            const participantIds = chats[i].participants
              .map((participant) => participant.id)
              .sort();
            if (
              _.isEqual(participantIds, targetParticipantIds) &&
              chats[i]?.regarding?.id === regardingId
            ) {
              return { chat: chats[i] };
            }
          }
        } else {
          for (let i = 0; i < chats.length; i++) {
            const participantIds = chats[i].participants
              .map((participant) => participant.id)
              .sort();
            if (
              _.isEqual(participantIds, targetParticipantIds) &&
              typeof chats[i].regarding === "undefined"
            ) {
              return { chat: chats[i] };
            }
          }
        }

        const createdChat = await createChat(
          regardingId,
          patientId,
          providerIds
        );
        return { chat: createdChat, newChat: true };
      } else return undefined;
    }
  };

  const getChatById = async (
    chatId: string
  ): Promise<ChatPreview | undefined> => {
    const conversation = await getConversationById(chatId);
    if (conversation) {
      const chat = await formatTwilioConversation(conversation);
      return chat;
    }
    return undefined;
  };

  const getConversationById = async (
    chatId: string
  ): Promise<Conversation | undefined> => {
    try {
      const conversation = await twilio?.getConversationBySid(chatId);
      return conversation;
    } catch (e) {
      logWarn('useChats - getConversationById: ', {chatId: chatId, error: e})
    }
  };

  return {
    twilio,
    user,
    formatChat,
    getAllChats,
    getUserChatsPerPage,
    getUserChat,
    getChatById,
    getChatMessages,
    sendMessage,
    formatTwilioMessage,
    formatTwilioMessages,
    updateLastReadMessageIndex,
    createChat,
    getChatWithUsers,
    formatTwilioConversation,
    getConversationById,
  };
};
