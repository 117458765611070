export const getLocalDateTime = (dateString?: string) => {
    if (dateString) {
        const dateArray = dateString.split(' ').join('T').split(/\D/);
        return Date.UTC(
            Number(dateArray[0]),
            Number(dateArray[1]) - 1,
            Number(dateArray[2]),
            Number(dateArray[3]),
            Number(dateArray[4]),
            Number(dateArray[5]));
    }
    else return Date.UTC(0, 0, 0, 0, 0, 0)
}