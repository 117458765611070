import axios from "axios";
import { Hours } from "../../lib/interfaces/availbilityManagement";
import { Option } from "../../lib/interfaces/input";

export interface AvailabilityExceptionItem {
  date: string;
  available: boolean;
  times: Hours[];
}

export interface AvailabilityMasterItem {
  dayOfWeek: string;
  available: boolean;
  times: Hours[];
}

export interface Availability {
  availabilityMaster: {
    providerId: string;
    modifiedBy?: string;
    modifiedOn?: string;
    availability: AvailabilityMasterItem[];
  };
  availabilityExceptions: {
    providerId: string;
    availability: AvailabilityExceptionItem[];
  };
}

export interface AvailabilityResponse {
  data: Availability;
}

export interface AvailabilityPayload {
  availabilityMaster: {
    providerId: string;
    availability: { dayOfWeek: number; available: boolean; times: Hours[] }[];
  };
  availabilityExceptions: {
    providerId: string;
    availability: AvailabilityExceptionItem[];
  };
}

export interface DeletePayload {
  providerId: string;
  date: string;
  from?: Option | null;
}

export const fetchAvailability = async (
  providerId: string,
  controller?: AbortController
) => {
  const result = await axios
    .get<AvailabilityResponse, AvailabilityResponse>("/availability", {
      params: { providerId },
      signal: controller ? controller.signal : undefined,
    })
    .catch((err) => {
      if (axios.isCancel(err)) return { data: "cancelled" };
      else return err;
    });
  return result.data;
};

export const postWeeklyHours = async (
  userId: string,
  payload: AvailabilityPayload
) => {
  await axios({
    method: "post",
    url: `/availability?providerId=${userId}`,
    data: payload,
  })
    .catch((err) => err)
    .then((res) => res);
  return "success";
};

export const postDateOverrides = async (
  userId: string,
  payload: AvailabilityPayload
) => {
  await axios({
    method: "post",
    url: `/availability?providerId=${userId}`,
    data: payload,
  })
    .catch((err) => err)
    .then((res) => res);
  return "success";
};

export const deleteDateOverrides = async (
  userId: string,
  payload: DeletePayload
) => {
  await axios({
    method: "delete",
    url: `/availability?providerId=${userId}`,
    data: payload,
  })
    .catch((err) => err)
    .then((res) => res);
  return "success";
};
