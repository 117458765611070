import { useMemo } from "react";
import { Avatar } from "../avatar/Avatar";
import { PatientInfo, UserInfo } from "../../lib/interfaces/user";
import clsx from "clsx";
import styles from './style.module.css'

export type AvatarGroupSize = 'lg' | 'xl'
export interface AvatarGroupProps {
  users: (UserInfo | PatientInfo)[]
  size?: AvatarGroupSize
  className?: string
}

export const AvatarGroupCircle = ({
  users,
  size = 'xl',
  className
}: AvatarGroupProps) => {
  const shownUsers = useMemo(() => users.slice(0, 3), [users])

  return (
    shownUsers.length === 1 ? (
      <Avatar
        user={shownUsers[0]}
        size={size} />
    ) : (
      <div className={clsx(styles.avatarGroup, styles[size], className)}>
        {shownUsers.length === 2 ? (<>
          <Avatar
            className={clsx(styles.avatar, styles.avatar1of2)}
            user={shownUsers[0]}
            size='xs' />
          <Avatar
            className={clsx(styles.avatar, styles.avatar2of2)}
            user={shownUsers[1]}
            size='xs' />
        </>) : (<>
          <Avatar
            className={clsx(styles.avatar, styles.avatar1of3)}
            user={shownUsers[0]}
            size='xxs' />
          <Avatar
            className={clsx(styles.avatar, styles.avatar2of3)}
            user={shownUsers[1]}
            size='xxs' />
          <Avatar
            className={clsx(styles.avatar, styles.avatar3of3)}
            user={shownUsers[2]}
            size='xxs' />
        </>)}
      </div>
    )
  )
}