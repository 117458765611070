import clsx from 'clsx'
import { CSSProperties, ReactChild } from 'react'
import { BodyColor } from '../body/Body'
import styles from './style.module.css'



type HeadingType = '01' | '02' | '03'
interface HeadingProps {
  children?: ReactChild | ReactChild[]
  type?: HeadingType
  style?: CSSProperties
  className?: string
  color?: BodyColor
}

export const Heading = ({
  children,
  type = '01',
  style,
  color,
  className
}: HeadingProps) => {
  switch (type) {
    case '01': return (<h1 className={clsx(styles.base, styles.heading1, color && styles[color], className)} style={style}> {children} </h1>)
    case '02': return (<h2 className={clsx(styles.base, styles.heading2, color && styles[color], className)} style={style}> {children} </h2>)
    case '03': return (<h3 className={clsx(styles.base, styles.heading3, color && styles[color], className)} style={style}> {children} </h3>)
  }
}