import { useEffect, useMemo, useRef } from 'react';
import _ from 'lodash';
import { curveBumpX } from 'd3'
import { addWeeks, format, addMonths } from 'date-fns';
import { Area, CartesianGrid, ComposedChart, Line, YAxis } from 'recharts';
import { Body, BodyColor } from '../typography/body/Body';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';
import styles from './style.module.css'

export interface ChartProps {
  data: any[]
  label?: string
  boldLabel?: boolean
  labelColor?: BodyColor
  labelMargin?: string
  caption?: string
  height?: number
  scrollY?: boolean
  reducedLabelMargin?: boolean
  isWeekly?: boolean
  animated?: boolean
  compact?: boolean
}

export const Chart = ({ data, compact, label, boldLabel = true, labelColor, labelMargin = '48px', caption, height = 120, scrollY = true, reducedLabelMargin, isWeekly = true, animated = false }: ChartProps) => {
  const chartRef = useRef<HTMLDivElement>(null)
  const finalTickRef = useRef<HTMLDivElement>(null)

  const yData = useMemo(() => {
    return data.map((entry, i) => {
      if (i === data.length - 1) entry.final = true
      return { ...entry }
    })
  }, [data])

  const xData = useMemo(() => {
    const dates = data.map((value, i) => {
      if (i === data.length - 1)
        value.final = true
      return {
        ...value,
        fullDate: value.date,
        month: format(value.date, 'MMM'),
        date: format(value.date, 'd')
      }
    })

    if (isWeekly) {
      for (let i = 0; i < 7; i++) {
        const newDate = addWeeks(new Date(dates[dates.length - 1].fullDate), 1)
        dates.push({
          fullDate: newDate,
          month: format(newDate, 'MMM'),
          date: format(newDate, 'd')
        })
      }
    } else {
      for (let i = 0; i < 7; i++) {
        const newDate = addMonths(new Date(dates[dates.length - 1].fullDate), 1)
        dates.push({
          fullDate: newDate,
          month: format(newDate, 'MMM'),
          date: format(newDate, 'd')
        })
      }
    }

    return dates
  }, [data])

  useEffect(() => {
    if (chartRef.current && finalTickRef.current) {
      const scrollBy = finalTickRef.current.offsetLeft
      chartRef.current.scroll({ top: 0, left: scrollBy })
    }
  }, [data])

  return (
    <div className={clsx(styles.chartContainer, label && styles.withLabel, scrollY ? styles.withScrollY : styles.withoutScrollY)} ref={chartRef}>
      <div className={styles.title}>
        {label && (
          <Body
            className={clsx(styles.label, reducedLabelMargin && styles.reducedLabelMargin)}
            style={{ marginBottom: labelMargin }}
            size='sm'
            weight={boldLabel ? 'bold' : 'regular'}
            color={labelColor ? labelColor : 'primary'}>
            {label}
          </Body>
        )}
        {caption && (
          <Body
            className={clsx(styles.label, reducedLabelMargin && styles.reducedLabelMargin)}
            size='sm'
            color='secondary'>
            {caption}
          </Body>
        )}
      </div>
      <div className={styles.scrollWrapper}>
        <ComposedChart
          id='chartComponent'
          data={yData}
          width={(compact ? 66 : 88) * (data.length - 1)}
          height={height}
          className={styles.chart}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#DDEDF4" stopOpacity={1} />
              <stop offset="95%" stopColor="#DDEDF4" stopOpacity={0} />
            </linearGradient>
          </defs>
          <YAxis
            width={0}
            type='number'
            display='none'
            tickLine={false}
            axisLine={false}
            dataKey='y'
            domain={[0, 5]} />
          <CartesianGrid horizontal={false} strokeLinecap='round' stroke='#DDEDF4' />
          <Area animationDuration={2000} isAnimationActive={animated} animationEasing='ease-out' animationBegin={100} type={curveBumpX} dataKey='y' strokeWidth={0} fillOpacity={1} fill="url(#colorUv)" />
          <Line animationDuration={2000} isAnimationActive={animated} animationEasing='ease-out' type={curveBumpX} dataKey='y' strokeWidth={2} stroke='#1A6D93' dot={<CustomizedDot />} />
        </ComposedChart>

        <div className={styles.xAxisContainer}>
          <svg height={3} width='100%' className={styles.xAxisBorder}>
            <line stroke="#BEDEE9" strokeDasharray="0,11" strokeWidth="3" strokeLinecap="round" fill="none" x1="0" y1="1.5" x2={(compact ? 33 : 44) + (compact ? 66 : 88) * (xData.length - 1)} y2="1.5"></line>
          </svg>
          <div className={clsx(styles.ticks,compact && styles.compact)}>
            {xData.map(el => (
              <div key={uuid()} className={styles.tickContainer} ref={el.final ? finalTickRef : undefined}>
                <div className={styles.tick}>
                  <Body size='xs' weight='bold' color='link'>{el.label || el.month}</Body>
                  {isWeekly && < Body size='xs' color='link'>{el.sub || el.date}</Body>}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div >
  )
}

const CustomizedDot = (props: any) => {
  const { cx, cy, stroke, payload } = props;
  if (payload.final) {
    return (
      <svg
        x={cx}
        y={cy}
        width={10}
        height={10}
        stroke={stroke}
        viewBox="0 0 20 20"
        style={{ overflow: 'visible' }}>
        <circle
          className={styles.dot}
          fill={stroke}
          r="10" />
      </svg>
    )
  }
  else return <></>
};