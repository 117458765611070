import clsx from 'clsx'
import { Circle } from 'phosphor-react'
import styles from './style.module.css'

export interface RadioProps {
  label?: string
  selected: boolean
  disabled?: boolean
  onSelect?: () => void
  className?: string
}

export const Radio = ({
  label,
  selected,
  disabled = false,
  onSelect,
  className
}: RadioProps) => (
  <label className={clsx(styles.radioContainer, disabled && styles.disabled, className)} onClick={e => e.stopPropagation()}>
    <div
      tabIndex={!disabled ? 0 : undefined}
      className={clsx(
        styles.radio,
        disabled && styles.disabled,
        selected && styles.selected
      )}>
      {selected && <Circle size={16} weight='fill' />}
    </div>
    <input
      tabIndex={undefined}
      className={styles.radioInput}
      disabled={disabled}
      type='radio'
      checked={selected}
      onChange={onSelect}
    />
    {label}
  </label>
)
