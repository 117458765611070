import { useEffect, useState } from "react"
import { FeaturedIcon } from "../../../components/featuredIcon/FeaturedIcon"
import { Body } from "../../../components/typography/body/Body"
import { Heading } from "../../../components/typography/heading/Heading"
import { Button } from "../../../components/button/Button"
import { Medication } from "../../../lib/interfaces/medications"
import { MedicationIcon } from "../../../components/customIcons/CustonIcons"
import { useOktaAuth } from "@okta/okta-react"
import styles from './style.module.css'
import { useBaseContext } from "../../_base/Base"
import { useMedications } from "../../../lib/hooks/useMedications"

export interface MedicationsActionPlanProps {
  patientId: string
}

export const MedicationsActionPlan = ({ patientId }: MedicationsActionPlanProps) => {
  const [activeMedications, setActiveMedications] = useState<Medication[]>([])
  const { getActiveMedications } = useMedications()

  useEffect(() => {
    getActiveMedications(patientId).then(meds => [
      setActiveMedications(meds)
    ])
  }, [])

  return (
    <div className={styles.activeMedicationsSection}>

      <div className={styles.header}>
        <div className={styles.headerTitle}>
          <FeaturedIcon Icon={MedicationIcon} type='gray' />
          <div>
            <Heading type='02'>Medications</Heading>
            <Body size='md' weight='medium' color='secondary'>Prescribed medications</Body>
          </div>
        </div>
        {activeMedications.length > 0 ? (
          <Button label='View Past Medications' onClick={() => { }} type='secondary-gray' />
        ) : (
          <Body size='md' color='disabled'>{'Medications will show here if a patient was prescribed any'}</Body>
        )}
      </div>

      {activeMedications.length > 0 && (
        <div className={styles.medications}>
          {activeMedications.map(medication => (
            <div className={styles.medication} key={medication.id}>
              <Heading type='03' className={styles.medicationName}>{medication.friendlyName}</Heading>
              <Body size='md' weight='regular' className={styles.medicationInstructions}>{medication.instructions}</Body>
            </div>
          ))}
        </div>
      )}

    </div>
  )
}