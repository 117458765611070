import clsx from "clsx";
import { MouseEventHandler, useContext, useMemo } from "react";
import { BaseContext } from "../../lib/context/context";
import { UserInfo } from "../../lib/interfaces/user";
import { AvatarGroupCircle } from "../avatarGroupCircle/AvatarGroupCircle";
import { Badge } from "../badge/Badge";
import { Body } from "../typography/body/Body";
import styles from "./style.module.css";

export type ButtonType =
  | "primary"
  | "secondary"
  | "secondary-gray"
  | "tertiary";
export type ButtonSize = "medium" | "small";

export interface ChatPreviewProps {
  onClick?: MouseEventHandler;
  participants: UserInfo[];
  name?: string;
  time: string;
  preview: string;
  unread?: boolean;
  selected?: boolean;
  regarding?: string;
  className?: string;
  truncatePreviewOneLine?: boolean;
}

export const ChatPreview = ({
  onClick,
  participants,
  name,
  preview,
  time,
  selected = false,
  unread = false,
  regarding,
  className,
  truncatePreviewOneLine,
}: ChatPreviewProps) => {
  const { user } = useContext(BaseContext);

  const subText = useMemo(
    () => participants.map((user) => user.title).join(", "),
    [participants]
  );

  const allNames = useMemo(() => {
    const participantsUserNotIncluded = participants.filter(
      (p) => p.id !== user?.sub
    );
    return participantsUserNotIncluded
      .map((participant) =>
        [participant.firstName, participant.lastName].join(" ")
      )
      .join(", ");
  }, [participants]);

  const chatName = useMemo(() => {
    const participantsUserNotIncluded = participants.filter(
      (p) => p.id !== user?.sub
    );
    return participantsUserNotIncluded
      .map((participant) =>
        [participant.firstName, participant.lastName].join(" ")
      )
      .join(", ");
  }, [participants]);

  return (
    <div
      onClick={onClick}
      className={clsx(styles.base, selected && styles.selected, className)}
    >
      <div className={styles.avatarContainer}>
        <AvatarGroupCircle users={participants} size="lg" />
      </div>

      <div className={styles.chatPreviewContainer}>
        <div data-dd-privacy="mask" title={allNames} className={styles.nameTimeContainer}>
          <div className={styles.nameContainer}>
            <Body
              className={styles.names}
              size="sm"
              weight="bold"
              color="secondary"
              data-dd-privacy="mask" 
            >
              {chatName}
            </Body>
            {/* display number of extra participants +3 */}
            {(!name || name.length === 0) && participants.length > 3 && (
              <Body size="sm" weight="bold" color="secondary" data-dd-privacy="mask" >
                {["+", participants.length - 3].join("")}
              </Body>
            )}
          </div>
          <Body
            className={styles.time}
            size="xs"
            weight="regular"
            color="secondary"
          >
            {time}
          </Body>
        </div>

        <Body
          className={styles.subText}
          size={"sm"}
          weight="regular"
          color="secondary"
          data-dd-privacy="mask" 
        >
          {subText}
        </Body>
        {regarding && (
          <Body
            className={styles.subText}
            size={"sm"}
            weight="regular"
            color="secondary"
            data-dd-privacy="mask" 
          >{`Discussing ${regarding} (patient)`}</Body>
        )}

        <div className={styles.previewContainer}>
          <Body
            className={clsx(
              styles.preview,
              truncatePreviewOneLine && styles.truncate,
              preview === "noLatestMessage" && styles.italic
            )}
            size="sm"
            weight={unread ? "bold" : "regular"}
            color="primary"
            data-dd-privacy="mask" 
          >
            {preview === "noLatestMessage" ? "Draft" : preview}
          </Body>
        </div>
      </div>

      {unread ? <Badge type="neutral" top="50%" right={16} /> : null}
    </div>
  );
};
