export interface PhoneNumber {
  Type: string,
  Number: string
}

export interface EmergencyContact {
  name: string,
  relation: string,
  legalGuardian: string,
  phones: PhoneNumber[],
}

export interface Insurance {
  beneficiary: {
    name: string,
    patientID: string
  },
  payor: {
      id: string,
      name: string,
  },
  periodStart: string,
  relationship: string, 
  status: string,
  subscriber: {
      id: string,
      name: string,
      patientID: string
  }
}

export interface Settings {
  General?: {
    Membership?: string
    IdentityVerified?: string
  }
  Consent?: {
    Privacy?: string
    Terms?: string
    HIPAA?: string
    Telehealth?: string
    Membership?: string
  }
}

export const MembershipPremium = 'Premium'
export const MembershipFree = 'Free'