import { Tag } from "../tag/Tag";
import { Body } from "../typography/body/Body";
import { Heading } from "../typography/heading/Heading";
import { CaretRight, Check, Prohibit } from "phosphor-react";
import { Button } from "../button/Button";
import { AlertBanner } from "../alertBanner/AlertBanner";
import { PatientInfo, ProviderInfo } from "../../lib/interfaces/user";
import { useEffect, useState } from "react";
import { Modal } from "../modal/Modal";
import { UserDetails } from "../userDetails/UserDetails";
import { AvatarLabelGroup } from "../avatarLabelGroup/AvatarLabelGroup";
import styles from "./style.module.css";
import { useCarePlan } from "../../lib/hooks/useCarePlan";
import clsx from "clsx";
import ProviderType from "../../lib/models/providerType";
import { getAppointmentTypeDisplayString } from "../../lib/apis/types/appointmentType";

interface CheckingInWith {
  name: string;
  id: string;
}

export interface ScheduleAppointmentCardProps {
  id: string;
  user: ProviderInfo;
  time: string;
  appointmentType: string;
  provider: ProviderInfo | undefined;
  patient: PatientInfo | undefined;
  careCoordinator: ProviderInfo;
  visitType: string;
  atmanMember: boolean;
  checkedIn: boolean;
  careStatusNeeded?: boolean;
  // hasCarePlan: boolean
  preVisitInfoComplete: boolean;
  reason?: string;
  onAppointmentClick: () => void;
  onCareCoordinatorClick: (
    visitId: string,
    currentCareCoordinatorId?: string
  ) => Promise<CheckingInWith | undefined>;
}

export const ScheduleAppointmentCard = ({
  id,
  user,
  time,
  appointmentType,
  patient,
  provider,
  careCoordinator,
  visitType,
  atmanMember,
  checkedIn,
  careStatusNeeded,
  // hasCarePlan,
  preVisitInfoComplete,
  reason,
  onAppointmentClick,
  onCareCoordinatorClick,
}: ScheduleAppointmentCardProps) => {
  const { getPublishedCarePlan } = useCarePlan();

  const controller = new AbortController();

  const [checkingInWith, setCheckingInWith] = useState({
    name: careCoordinator.name,
    id: careCoordinator.id,
  });
  const [updatingCheckingInWith, setUpdatingCheckingInWith] = useState(false);
  const [showProviderModal, setShowProviderModal] = useState<boolean>(false);
  const [hasCarePlan, setHasCarePlan] = useState<boolean>();

  const handleClickCheckingInWith = async () => {
    setUpdatingCheckingInWith(true);
    const newCheckingInWith = await onCareCoordinatorClick(
      id,
      checkingInWith.id
    );
    if (newCheckingInWith) setCheckingInWith(newCheckingInWith);
    else setUpdatingCheckingInWith(false);
  };

  useEffect(() => {
    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (careCoordinator) {
      setCheckingInWith({
        name: careCoordinator.name,
        id: careCoordinator.id,
      });
    }
  }, [careCoordinator]);

  useEffect(() => setUpdatingCheckingInWith(false), [checkingInWith]);

  useEffect(() => {
    if (patient && patient?.settings?.General?.Membership === "Premium") {
      getPublishedCarePlan(patient.id, controller).then((carePlanRes) => {
        setHasCarePlan(
          carePlanRes && carePlanRes.id && carePlanRes.id !== "" ? true : false
        );
      });
    }
  }, [patient]);

  const titleOverride: JSX.Element = (
    <>
      <div style={{ display: "flex", width: "400px" }}>
        <Body size="sm" color="secondary">
          {visitType} with{" "}
        </Body>
        <div
          onClick={() => {
            setShowProviderModal(true);
          }}
          style={{ cursor: "pointer" }}
        >
          <Body size="sm" color="link">
            {provider ? provider.name : ""}
          </Body>
        </div>
      </div>
      <Body size="sm" weight="bold" color="secondary">
        {getAppointmentTypeDisplayString(appointmentType)} Visit
      </Body>
    </>
  );

  return (
    <div className={styles.appointmentCard}>
      <div className={styles.appointmentInfoContainer}>
        <div className={styles.time}>
          <Heading
            type="03"
            style={{ width: 417, height: 22, paddingRight: 14 }}
          >
            {time}
          </Heading>
        </div>
        <div className={styles.column}>
          <AvatarLabelGroup
            data-dd-privacy="mask"
            users={patient ? [patient] : []}
            titleOverride={titleOverride}
            linkUser={true}
          />
          <div
            className={clsx(
              patient?.settings?.General?.Membership === "Premium" &&
                !hasCarePlan &&
                typeof hasCarePlan !== "undefined" &&
                styles.noCarePlan
            )}
          >
            {patient?.settings?.General?.Membership === "Premium" &&
              !hasCarePlan &&
              typeof hasCarePlan !== "undefined" && (
                <Tag content="No Care Plan" />
              )}
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.apptButton}>
            <Button
              size="small"
              type="secondary-gray"
              label="Appointment"
              Icon={CaretRight}
              iconPosition="right"
              onClick={onAppointmentClick}
            />
          </div>
          <div className={styles.apptTags}>
            <div className={styles.tag}>
              <Tag
                content={"Checked In"}
                type={checkedIn ? "success" : "danger"}
                Icon={checkedIn ? Check : Prohibit}
                iconPosition={"right"}
              />
            </div>
            <Tag
              content={"Pre Visit Info Complete"}
              type={preVisitInfoComplete ? "success" : "danger"}
              Icon={preVisitInfoComplete ? Check : Prohibit}
              iconPosition={"right"}
            />
          </div>
        </div>
      </div>
      {checkingInWith.id && (
        <div className={styles.checkingInWith}>
          {updatingCheckingInWith ? (
            <Body size={"sm"} color={"secondary"}>
              ...
            </Body>
          ) : (
            <>
              <Body size={"sm"} color={"secondary"}>{`Checking in with `}</Body>
              <Body size={"sm"} color={"secondary"} weight={"bold"}>
                {checkingInWith.name}
              </Body>
              {(user.type === ProviderType.CareNavigator ||
                user.type === ProviderType.Admin) && (
                <div onClick={handleClickCheckingInWith}>
                  <Body
                    className={styles.updateCareCoordinatorButton}
                    size={"sm"}
                    color={"link"}
                  >
                    {user.id === checkingInWith.id ? " Set Default" : " Claim"}
                  </Body>
                </div>
              )}
            </>
          )}
        </div>
      )}
      {careStatusNeeded && (
        <AlertBanner
          message="Indicate if care was given for this appointment"
          type="info"
        />
      )}

      <Modal
        data-dd-privacy="mask" 
        onCloseModal={() => setShowProviderModal(false)}
        visible={showProviderModal}
        title={"Provider Detail"}
        centerTitle={true}
        className={styles.modal}
      >
        <UserDetails provider={provider} />
      </Modal>
    </div>
  );
};
