import _ from "lodash";
import { v4 as uuid } from 'uuid';
import { Plus, Trash } from "phosphor-react";
import { useEffect, useMemo, useState } from "react";
import { AvatarLabelGroup } from "../../../components/avatarLabelGroup/AvatarLabelGroup";
import { Button } from "../../../components/button/Button";
import { NourishIcon, MoveIcon, CalmIcon } from "../../../components/customIcons/CustonIcons";
import { FeaturedIcon } from "../../../components/featuredIcon/FeaturedIcon";
import { SearchSelect } from "../../../components/searchSelect/SearchSelect";
import { Select } from "../../../components/select/Select";
import { TextInput } from "../../../components/textInput/TextInput";
import { Body } from "../../../components/typography/body/Body";
import { Heading } from "../../../components/typography/heading/Heading";
import { Option } from "../../../lib/interfaces/input";
import { UserInfo } from "../../../lib/interfaces/user";
import { Action } from "../../../lib/interfaces/carePlan";
import { categoryNames, pillarDict } from "../pillarsActionPlan/PillarsActionPlan";
import styles from './style.module.css'

const options = {
  move: {
    category: [
      { name: 'Cardiovascular', value: 'cardio' },
      { name: 'Strength', value: 'strength' },
      { name: 'Mobility', value: 'mobility' },
    ] as MoveCategoryOption[],
    type: {
      cardio: [
        { name: 'Walking', value: 'walking' },
        { name: 'Jogging', value: 'jogging' },
        { name: 'Running', value: 'running' },
        { name: 'Jumping rope', value: 'jumpingRope' },
        { name: 'Stationary bike', value: 'stationaryBike' },
        { name: 'Bicycle', value: 'bicycle' },
        { name: 'Rowing', value: 'rowing' },
        { name: 'Interval training', value: 'intervalTraining' },
        { name: 'Sports activities', value: 'sportsActivites' },
        { name: 'Swimming', value: 'swimming' },
        { name: 'Dancing', value: 'dancing' },
        { name: 'Water aerobics', value: 'waterAerobics' },
        { name: 'Group class', value: 'groupClass' },
      ] as MoveTypeOption[],
      strength: [
        { name: 'Free weights', value: 'freeWeights' },
        { name: 'Weight machines', value: 'weightMachines' },
        { name: 'Body weight training', value: 'bodyWeightTraining' },
        { name: 'Resistance bands', value: 'resistanceBands' },
        { name: 'Suspension', value: 'suspension' },
        { name: 'Pilates', value: 'pilates' },
        { name: 'Group class', value: 'groupClass' },
      ] as MoveTypeOption[],
      mobility: [
        { name: 'Stretching', value: 'stretching' },
        { name: 'Yoga', value: 'yoga' },
        { name: 'Foam rolling', value: 'foamRolling' },
        { name: 'Group class', value: 'groupClass' },
      ] as MoveTypeOption[]
    },
    unit: [
      { name: 'Minutes', value: 'minutes' },
      // { name: 'Hours', value: 'hours' },
    ] as MoveUnitOption[],
    interval: [
      { name: 'Day', value: 'day' },
      { name: 'Week', value: 'week' },
      { name: 'Month', value: 'month' },
    ] as MoveIntervalOption[],
    Icon: MoveIcon
  },
  nourish: {
    category: [
      { name: 'Eating Plan', value: 'diet' },
      { name: 'Hydration', value: 'hydration' },
      { name: 'Foods to Add', value: 'foodsToAdd' },
      { name: 'Foods to Limit', value: 'foodsToAvoid' },
    ] as NourishCategoryOption[],
    type: {
      diet: [
        { name: 'Plate method', value: 'plateMethod' },
        { name: 'Mediterranean', value: 'mediterranean' },
        { name: 'Plant-Based', value: 'plantBased' },
        { name: 'Low Sodium', value: 'lowSodium' },
        { name: 'Low Carbohydrate', value: 'lowCarbohydrate' }
      ] as NourishTypeOption[],
      hydration: [
        { name: 'Water', value: 'water' },
        { name: 'Sparkling water', value: 'sparklingWater' },
        { name: 'Tea', value: 'tea' },
        { name: 'Iced tea', value: 'icedTea' },
        { name: 'Milk', value: 'milk' },
        { name: 'Non-dairy milk', value: 'nonDairyMilk' },
      ] as NourishTypeOption[],
      foodsToAdd: [
        { name: 'Vegetables', value: 'vegetables' },
        { name: 'Fruits', value: 'fruits' },
        { name: 'Protein', value: 'protein' },
        { name: 'Calcium', value: 'calcium' },
        { name: 'Fiber', value: 'fiber' },
        { name: 'Probiotic rich, healthy fats', value: 'probioticRichHealthyFats' },
      ] as NourishTypeOption[],
      foodsToAvoid: [
        { name: 'Processed Food', value: 'processedFood' },
        { name: 'High Sodium', value: 'highSodium' },
        { name: 'Saturated Fats', value: 'saturatedFats' },
        { name: 'Alcohol', value: 'alcohol' },
        { name: 'Sweetened Drinks', value: 'sweetenedDrinks' },
      ] as NourishTypeOption[],
    },
    unit: [
      { name: 'Servings', value: 'servings' },
      { name: 'Ounces', value: 'ounces' },
      { name: 'Cups', value: 'cups' },
    ] as NourishUnitOption[],
    interval: [
      { name: 'Day', value: 'day' },
      { name: 'Week', value: 'week' },
      { name: 'Month', value: 'month' },
    ] as NourishIntervalOption[],
    Icon: NourishIcon
  },
  calm: {
    category: [
      { name: 'Wellness Promotion', value: 'wellnessPromotion' },
      { name: 'Stress Management', value: 'stressManagement' },
    ] as CalmCategoryOption[],
    type: {
      wellnessPromotion: [
        { value: 'mindfulness', name: 'Mindfulness' },
        { value: 'meditation', name: 'Meditation' },
        { value: 'breathing', name: 'Breathing' },
        { value: 'prayer', name: 'Prayer' },
        { value: 'ecotherapy', name: 'Ecotherapy' },
        { value: 'bodyScanning', name: 'Body Scanning' },
        { value: 'imagery', name: 'Imagery' },
        { value: 'journaling', name: 'Journaling' }
      ] as CalmTypeOption[],
      stressManagement: [
        { value: 'mindfulness', name: 'Mindfulness' },
        { value: 'meditation', name: 'Meditation' },
        { value: 'exercise', name: 'Exercise' },
        { value: 'breathing', name: 'Breathing' },
        { value: 'prayer', name: 'Prayer' },
        { value: 'ecotherapy', name: 'Ecotherapy' },
        { value: 'bodyScanning', name: 'Body Scanning' },
        { value: 'imagery', name: 'Imagery' },
        { value: 'journaling', name: 'Journaling' }
      ] as CalmTypeOption[],
    },
    unit: [
      { name: 'Minutes', value: 'minutes' },
      // { name: 'Hours', value: 'hours' },
    ] as MoveUnitOption[],
    interval: [
      { name: 'Day', value: 'day' },
      { name: 'Week', value: 'week' },
      { name: 'Month', value: 'month' },
    ] as MoveIntervalOption[],
    Icon: CalmIcon
  },
}

type MoveCategory = 'cardio' | 'strength' | 'mobility'
type StrengthType = 'freeWeights' | 'weightMachines' | 'bodyWeightTraining' | 'resistanceBands' | 'suspension' | 'pilates' | 'groupClass'
type CardioType = 'walking' | 'jogging' | 'running' | 'jumpingRope' | 'stationaryBike' | 'bicycle' | 'rowing' | 'intervalTraining' | 'sportsActivites' | 'swimming' | 'groupClass' | 'dancing' | 'waterAerobics'
type MobilityType = 'stretching' | 'yoga' | 'foamRolling' | 'groupClass'
type MoveUnits = 'minutes' | 'hours'
type MoveInterval = 'day' | 'month' | 'week'

interface MoveCategoryOption extends Option { value: MoveCategory | '' }
interface MoveTypeOption extends Option { value: CardioType | StrengthType | MobilityType }
interface MoveUnitOption extends Option { value: MoveUnits | '' }
interface MoveIntervalOption extends Option { value: MoveInterval | '' }

type NourishCategory = 'diet' | 'hydration' | 'foodsToAdd' | 'foodsToAvoid'
type DietType = 'plateMethod' | 'mediterranean' | 'plantBased' | 'lowSodium' | 'lowCarbohydrate'
type HydrationType = 'water' | 'sparklingWater' | 'tea' | 'icedTea' | 'milk' | 'nonDairyMilk'
type FoodsToAddType = 'vegetables' | 'fruits' | 'protein' | 'calcium' | 'fiber' | 'probioticRichHealthyFats'
type FoodsToAvoidType = 'processedFood' | 'highSodium' | 'saturatedFats' | 'alcohol' | 'sweetenedDrinks'
type NourishUnits = 'servings' | 'ounces'
type NourishInterval = 'day' | 'month' | 'week'

interface NourishCategoryOption extends Option { value: NourishCategory | '' }
interface NourishTypeOption extends Option { value: DietType | HydrationType | FoodsToAddType | FoodsToAvoidType }
interface NourishUnitOption extends Option { value: NourishUnits | '' }
interface NourishIntervalOption extends Option { value: NourishInterval | '' }

type CalmCategory = 'stressManagement' | 'wellnessPromotion'
type WellnessPromotionType = 'mindfulness' | 'meditation' | 'breathing' | 'prayer' | 'ecotherapy' | 'bodyScanning' | 'imagery' | 'journaling'
type StressManagementType = 'mindfulness' | 'exercise' | 'meditation' | 'breathing' | 'prayer' | 'ecotherapy' | 'bodyScanning' | 'imagery' | 'journaling'
type CalmUnits = 'minutes' | 'hours'
type CalmInterval = 'day' | 'month' | 'week'

interface CalmCategoryOption extends Option { value: CalmCategory | '' }
interface CalmTypeOption extends Option { value: WellnessPromotionType | StressManagementType }
interface CalmUnitOption extends Option { value: CalmUnits | '' }
interface CalmIntervalOption extends Option { value: CalmInterval | '' }

type PillarType = 'move' | 'calm' | 'nourish'
type CategoryOption = CalmCategoryOption | MoveCategoryOption | NourishCategoryOption
type TypeOption = CalmTypeOption | MoveTypeOption | NourishTypeOption
type UnitOption = CalmUnitOption | MoveUnitOption | NourishUnitOption
type IntervalOption = CalmIntervalOption | MoveIntervalOption | NourishIntervalOption

export interface EditedActions extends Omit<Action, 'actionId'> {
  tempId?: string
  actionId?: string
}

export interface ActionsEditFormProps {
  pillar: PillarType
  actions: Action[]
  patient: UserInfo
  onSubmit: (editedActions: EditedActions[]) => void
}

export const ActionsEditForm = ({ pillar, actions, patient, onSubmit }: ActionsEditFormProps) => {
  const [editedActions, setEditedActions] = useState<(EditedActions)[]>(actions)
  const [actionCategory, setActionCategory] = useState<CategoryOption>({ name: '', value: '' })
  const [actionType, setActionType] = useState<TypeOption[]>([])
  const [actionQuantity, setActionQuantity] = useState<string>('')
  const [actionUnit, setActionUnit] = useState<UnitOption>({ name: '', value: '' })
  const [actionFrequency, setActionFrequency] = useState<string>('')
  const [actionInterval, setActionInterval] = useState<IntervalOption>({ name: '', value: '' })
  const [actionsChanged, setActionsChanged] = useState(false)

  useEffect(() => () => { if (!actionsChanged) setActionsChanged(true) }, [editedActions])

  const hasActions = useMemo(() => (editedActions.length > 0), [editedActions])

  const createdActionValid = useMemo(() => (
    actionCategory.value !== '' &&
    actionType.length > 0 &&
    Number(actionFrequency) > 0 &&
    actionInterval.value !== ''
  ), [actionCategory, actionType, actionFrequency, actionInterval])

  const categoryOptions = useMemo(() => options[pillar].category, [])
  const unitOptions = useMemo(() => options[pillar].unit, [])
  const intervalOptions = useMemo(() => options[pillar].interval, [])
  const typeOptions = useMemo(() => {
    if (actionCategory.value !== '') {
      switch (pillar) {
        case 'move':
          switch (actionCategory.value) {
            case 'cardio': return options.move.type.cardio
            case 'strength': return options.move.type.strength
            case 'mobility': return options.move.type.mobility
            default: return []
          }
        case 'nourish':
          switch (actionCategory.value) {
            case 'diet': return options.nourish.type.diet
            case 'hydration': return options.nourish.type.hydration
            case 'foodsToAdd': return options.nourish.type.foodsToAdd
            case 'foodsToAvoid': return options.nourish.type.foodsToAvoid
            default: return []
          }
        case 'calm':
          switch (actionCategory.value) {
            case 'stressManagement': return options.calm.type.stressManagement
            case 'wellnessPromotion': return options.calm.type.wellnessPromotion
            default: return []
          }
        default: return []
      }
    }
  }, [actionCategory])

  const formatActionFrequency = (action: EditedActions) => {
    let actionTracking = ''
    if (action.quantity > 0 && action.units.trim().length > 0) {
      actionTracking += `${action.quantity?.toString()} ${action.units || ''}, `
    }
    actionTracking += `${action.frequency}x per ${action.per}`
    return actionTracking.trim()
  }

  const handleCategoryChange = (newCategory: CategoryOption) => {
    if (newCategory.value !== actionCategory.value) {
      setActionType([])
      setActionQuantity('')
      setActionUnit({ name: '', value: '' })
      setActionFrequency('')
      setActionInterval({ name: '', value: '' })
    }
    setActionCategory(newCategory)
  }
  const handleTypeChange = (newActivities: TypeOption[]) => setActionType(newActivities)
  const handleQuantityChange = (newQuantity: string) => setActionQuantity(newQuantity)
  const handleUnitChange = (newUnit: UnitOption) => setActionUnit(newUnit)
  const handleFrequencyChange = (newFrequency: string) => setActionFrequency(newFrequency)
  const handleIntervalChange = (newInterval: IntervalOption) => setActionInterval(newInterval)
  const handleSaveActions = () => onSubmit(editedActions)
  const handleAddAction = () => {
    if (actionCategory.value !== '' && actionType.length > 0 && Number(actionFrequency) > 0 && actionInterval.value !== '') {
      const newAction: EditedActions = {
        actionId: uuid(),
        category: actionCategory.name,
        // category: actionCategory.value,
        activities: actionType.map(typeOption => typeOption.name),
        quantity: Number(actionQuantity) || 0,
        units: actionUnit.value,
        frequency: Number(actionFrequency),
        per: actionInterval.value,
      }
      setEditedActions(editedActions.concat(newAction))
      clearForm()
    }
  }
  const handleDeleteAction = (id: string) => {
    setEditedActions(editedActions.filter(action => action.actionId !== id && action.tempId !== id))
  }

  const Actions = () => {
    const actions: JSX.Element[] = []
    const categories = _(editedActions).groupBy(action => action.category).value()
    
    categoryNames.forEach(category => {
      if (categories[category])
        actions.push(
          <div className={styles.actionCategory}>
            <Heading type='03'>{category}</Heading>
            {categories[category].map(action => (
              <div className={styles.action} key={action.actionId}>
                <button
                  className={styles.deleteButton}
                  onClick={() => handleDeleteAction(action.actionId || action.tempId as string)}>
                  <FeaturedIcon size='sm' Icon={Trash} type='danger' />
                </button>
                <div className={styles.actionInfo}>
                  <Body weight='bold'>{action.activities?.join(', ')}</Body>
                  <Body>{formatActionFrequency(action)}</Body>
                </div>
              </div>
            ))}
          </div>
        )
    })
    return actions
  }

  const clearForm = () => {
    setActionCategory({ name: '', value: '' })
    setActionType([])
    setActionQuantity('')
    setActionUnit({ name: '', value: '' })
    setActionFrequency('')
    setActionInterval({ name: '', value: '' })
  }

  return (
    <div className={styles.actionsEditForm}>
      <div className={styles.actionAddFormContainer}>
        <div className={styles.actionAddForm}>
          <div className={styles.patientInfoContainer}>
            <Heading type='03'>Add actions for:</Heading>
            <AvatarLabelGroup size='md' users={[patient]} />
            <Body size='sm'>Customize actions for this patient. These will be shown within their app.</Body>
          </div>
          <div className={styles.optionsContainer}>
            <Heading type='03'>Action</Heading>
            <Select
              label='Category'
              value={actionCategory}
              options={categoryOptions}
              placeholder='Select'
              onChange={handleCategoryChange} />

            {actionCategory.value !== '' && (
              <SearchSelect
                label='Type'
                value={actionType}
                options={typeOptions}
                placeholder='Select'
                onChange={handleTypeChange} />
            )}

            {actionType.length > 0 && (
              <>
                <Heading type='03'>Tracking</Heading>
                <TextInput
                  type='number'
                  clearable
                  label='Quantity (optional)'
                  value={actionQuantity}
                  onChange={handleQuantityChange} />
                <Select
                  value={actionUnit}
                  optional
                  label='Units (optional)'
                  options={unitOptions}
                  onChange={handleUnitChange} />
                <TextInput
                  type='number'
                  label='Frequency'
                  value={actionFrequency}
                  onChange={handleFrequencyChange} />
                <Select
                  value={actionInterval}
                  label='Interval'
                  options={intervalOptions}
                  onChange={handleIntervalChange} />
              </>
            )}
          </div>
          <Button Icon={Plus} disabled={!createdActionValid} label='Add Action' onClick={handleAddAction} />
        </div>
      </div>

      <div className={styles.actionsContainer}>
        {!hasActions && !actionsChanged ? (
          <div className={styles.noActions}>
            <FeaturedIcon Icon={options[pillar].Icon} type={pillarDict[pillar].type} size='xxl' />
            <Body size='md' color='secondary'> You haven't added any {pillar} actions yet</Body>
          </div>
        ) : (
          <>
            {Actions()}
            <div className={styles.saveButtonContainer}>
              {actionsChanged && (
                <Button label='Save Actions' onClick={handleSaveActions} />
              )}
            </div>
          </>
        )}

      </div>
    </div>
  )

}

