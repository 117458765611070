import { useState, useEffect } from "react"
import { Button, ButtonGroup } from "../../../components/button/Button"
import { SearchSelect } from "../../../components/searchSelect/SearchSelect"
import { Select } from "../../../components/select/Select"
import { Switch } from "../../../components/switch/Switch"
import { Option } from "../../../lib/interfaces/input"
import { FilterOptions } from "../../../lib/interfaces/listOfPatients"
import { useProvider } from "../../../lib/hooks/useProvider"
import { ProviderInfo } from "../../../lib/interfaces/user"
import styles from './style.module.css'
import ProviderType from "../../../lib/models/providerType"

const sortingOptions: Option[] = [
  { name: 'Patient Last Name - A to Z', value: 'patient-alph' },
  { name: 'Patient Last Name - Z to A', value: 'patient-alph-rev' }
]

export interface FiltersFormProps {
  signedInUser: ProviderInfo
  assignedToMe: boolean
  appointmentsWithMe: boolean
  handleAppointmentsWithMeToggle: () => void
  handleAssignedToMeToggle: () => void
  handleFilterApply: () => void
  handleFilterClearAll: () => void
}

export const FiltersForm = ({
  signedInUser,
  assignedToMe,
  appointmentsWithMe,
  handleAppointmentsWithMeToggle,
  handleAssignedToMeToggle,
  handleFilterApply,
  handleFilterClearAll
}: FiltersFormProps) => {

  return (
    <div>
        <Switch
          active={assignedToMe}
          label={"Assigned To Me"}
          disabled={signedInUser.type !== ProviderType.CareNavigator}
          //add back in above line when testing complete
          onToggle={() => {handleAssignedToMeToggle()}}
        />
        <div style={{ height: "16px" }}></div>
        <Switch
          active={appointmentsWithMe}
          label={"Appointments With Me"}
          disabled={signedInUser.type === ProviderType.CareNavigator || signedInUser.type === ProviderType.OutsideSpecialist}
          //add back in above line when testing complete
          onToggle={() => {handleAppointmentsWithMeToggle()}}
        />
        <div style={{ height: "448px" }}></div>
        <ButtonGroup>
          <Button
            type="secondary-gray"
            disabled={false}
            onClick={() => {handleFilterClearAll()}}
            label="Clear All"
          />
          <Button
            type="primary"
            disabled={false}
            onClick={() => {handleFilterApply()}}
            //should add indicator fcn to show re-render of list
            label="Apply Filters"
          />
        </ButtonGroup>
    </div>
  )
}
