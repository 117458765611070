import * as Sentry from "@sentry/react";

type CustomErrorData = {
  [key: string]: any
}

export function setUser(id: string, email: string) {
  Sentry.setUser({ id });
  Sentry.setTag('email', email);
}

export function unsetUser() {
  Sentry.setUser(null);
  Sentry.setTag('email', undefined);
}

export function sentryMessage(message: string, extra?: CustomErrorData) {
  Sentry.withScope(scope => {
    if(extra) {
      for(const key in extra) {
        scope.setExtra(key, extra[key])
      }
    }
    Sentry.captureMessage(message, "info")
  })
}

export function sentryException(err: any, extra?: CustomErrorData) {
  Sentry.withScope(scope => {
    if(extra) {
      for(const key in extra) {
        scope.setExtra(key, extra[key])
      }
    }
    if(err?.isAxiosError) {
      scope.setExtra('type', 'API_REQUEST_ERROR')
      scope.setExtra('error', err?.response.data || {})
    }
    Sentry.captureException(err)
  })
}