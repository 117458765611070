import axios, { AxiosResponse } from 'axios'

export interface VisitReason {
  id: string,
  active: boolean,
  appointmentType: string[],
  description: string,
  epicVisitType: string,
  gender: string[],
  image: string,
  insuranceType: string[],
  insuranceVisitType: string,
  maximumAge: string,
  membershipType: string[],
  minimumAge: string,
  title: string,
  visitLength: string,
  displayTitle: string
}

interface VisitReasonsResponse extends AxiosResponse {
  data: VisitReason[]
}

export const fetchVisitReasons = async () => {
  const result = await axios
    .get<VisitReasonsResponse, VisitReasonsResponse>("/visit/reasons")
    .catch((err) => err)
  return result.data
}

export const fetchVisitReasonsByGender = async (gender: string[]) => {
  const result = await axios
    .get<VisitReasonsResponse, VisitReasonsResponse>(
      `/visit/reasons?gender=${gender}`
    )
    .catch((err) => err)
  return result.data
}

export const fetchVisitReasonById = async (id: number | string) => {
  const result = await axios
    .get<VisitReasonsResponse, VisitReasonsResponse>(`/visit/reasons?id=${id}`)
    .catch((err) => err)
  return result.data
}

