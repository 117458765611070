import axios, { AxiosResponse } from 'axios'

export interface ResponseData {
  authoredOn: string,
  encounter: string,
  id: string,
  orderId: string,
  patient: string,
  reason: string[],
  requesterId: string,
  requesterName: string,
  status: string,
  type: string
}

interface OrdersResponse extends AxiosResponse {
  data: ResponseData[]
}

export const fetchOrders = async (userId: string, controller?: AbortController) => {
  const result = await axios.get<OrdersResponse, OrdersResponse>(
    `/patient/orders?userId=${userId}`,
    {
      signal:  controller ? controller.signal : undefined
    })
    .catch(err => { 
      if(axios.isCancel(err)) return {data: 'cancelled'}
      else return err
    })
  return result.data
}