import { EmergencyContact, Insurance, Settings } from "./patientInfo";
import { ChartItem } from "../../lib/hooks/useCarePlan";
import { Visit } from "./visits";

export interface UserInfo {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  photo?: string;
  title: string;
}

export interface PatientInfo extends UserInfo {
  identifiers?: {
    wprinternal?: string;
    epic?: string;
  };
  dateOfBirth?: string;
  first_name?: string;
  last_name?: string;
  addressLine1?: string;
  addressLine2?: string;
  emergencyContact?: EmergencyContact;
  coverage?: Insurance[];
  assignedSex?: string;
  city?: string;
  state?: string;
  zip?: string;
  mobile?: string;
  email?: string;
  settings?: Settings;
  checkedIn?: boolean;
  premiumMember?: boolean;
  atmanMember?: boolean;
  hasCarePlan?: boolean;
  hasCreditCard?: boolean;
  alreadyScheduledAppointments?: boolean;
}

export interface PatientCardInfo extends UserInfo {
  nextAppointment?: Visit;
  lastAppointment?: Visit;
  progressData?: ChartItem[];
  chartStartDate?: string;
  chartEndDate?: string;
  settings?: Settings;
  careTeam?: string[]; //list of care team id's
  alreadyScheduledAppointments?: boolean;
}

export interface ProviderInfo extends UserInfo {
  identity?: string;
  photoURL?: string;
  primarySpecialty: string;
  metadata?: any;
  ehr?: string;
  ehrId?: string;
  hoagProviderId?: string;
  bio?: string;
  type?: string;
}

export const defaultPatient: PatientInfo = {
  id: "",
  name: "",
  firstName: "",
  lastName: "",
  title: "Patient",
};

export const defaultProvider: ProviderInfo = {
  id: "",
  name: "",
  firstName: "",
  lastName: "",
  title: "",
  primarySpecialty: "",
};
