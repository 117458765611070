import { AvatarLabelGroup } from "../../../components/avatarLabelGroup/AvatarLabelGroup"
import { UserInfo } from '../../../lib/interfaces/user'
import { Button } from "../../../components/button/Button"
import { SignOut } from 'phosphor-react'
import styles from './style.module.css'

export interface Header {
    users: UserInfo[],
    handleClickLogout: () => void,
}

export const Header = ({users, handleClickLogout}: Header) => {
  return (
    <div>
      <div className={styles.header}>
        <AvatarLabelGroup className={styles.avatarLabelGroup} users={users} size='xl' />
        <Button onClick={() => {handleClickLogout()}} type='secondary-gray' size='small' Icon={SignOut} label='Log Out' className={styles.logoutButton}/>
      </div>
    </div>   
  )
}
