import { useState, useEffect, useContext, useMemo } from "react";
import { Heading } from "../../../components/typography/heading/Heading";
import { SearchSelect } from "../../../components/searchSelect/SearchSelect";
import { Select } from "../../../components/select/Select";
import {
  PatientSearchOption,
  ProviderSearchOption,
} from "../../../lib/interfaces/schedule";
import { Option } from "../../../lib/interfaces/input";
import { PatientInfo, UserInfo } from "../../../lib/interfaces/user";
import { useVisitReasons } from "../../../lib/hooks/useVisitReasons";
import { MagnifyingGlass } from "phosphor-react";
import styles from "./style.module.css";
import { BaseContext } from "../../../lib/context/context";
import ProviderType from "../../../lib/models/providerType";

export interface VisitDetailsProps {
  selectedPatient: PatientSearchOption;
  selectedPatientInfo?: PatientInfo;
  handleSelectedPatientChange: (selectedPatientOption: Option | string) => void;
  visitType: Option;
  handleSelectedVisitTypeChange: (selectedOption: Option) => void;
  selectedProvider: ProviderSearchOption;
  handleSelectedProviderChange: (
    selectedProviderOption: Option | string
  ) => void;
  disablePatientSelect: boolean;
  disableVisitField: boolean;
}

export const VisitDetails = ({
  selectedPatient,
  selectedPatientInfo,
  handleSelectedPatientChange,
  visitType,
  handleSelectedVisitTypeChange,
  selectedProvider,
  handleSelectedProviderChange,
  disablePatientSelect,
  disableVisitField,
}: VisitDetailsProps) => {
  const [allVisitReasons, setAllVisitReasons] = useState<Option[]>([]);
  const [patientsLoading, setPatientsLoading] = useState<boolean>(true);
  const [providersLoading, setProvidersLoading] = useState<boolean>(true);
  const [visitsLoading, setVisitsLoading] = useState<boolean>(true);

  const { allPatients, allProviders } = useContext(BaseContext);
  const { getVisitReasons, getVisitReasonsByGender } = useVisitReasons();

  useEffect(() => {
    if (allProviders && allProviders.length > 0) {
      setProvidersLoading(false);
    }
  }, [allProviders]);

  useEffect(() => {
    if (allPatients && allPatients.length > 0) {
      setPatientsLoading(false);
    }
  }, [allPatients]);

  useEffect(() => {
    if (allVisitReasons.length > 0) {
      setVisitsLoading(false);
    }
  }, [allVisitReasons]);

  useEffect(() => {
    if (selectedPatient) setVisitsLoading(true);
  }, [selectedPatient]);

  useEffect(() => {
    //once we have the patient's information, get the visit types based on their gender
    if (selectedPatientInfo && selectedPatientInfo.assignedSex) {
      getVisitReasonsByGender([selectedPatientInfo.assignedSex]).then((res) =>
        setAllVisitReasons(res)
      );
    }
    //if no gender is specified, then get all visit reasons
    else if (selectedPatientInfo && !selectedPatientInfo.assignedSex) {
      getVisitReasons().then((res) => setAllVisitReasons(res));
    }
  }, [selectedPatientInfo]);

  const sortByLastName = (providerA: UserInfo, providerB: UserInfo): number => {
    return providerA.lastName.localeCompare(providerB.lastName);
  };

  const filteredProviderList = useMemo(() => {
    if (allVisitReasons && visitType.value.id !== "" && allProviders) {
      let providers = [];
      switch (
        typeof visitType.value.id === "number"
          ? visitType.value.id.toString()
          : visitType.value.id
      ) {
        //specialist consult
        case process.env.REACT_APP_SPECIALIST_CONSULT_ID:
          providers = allProviders?.filter(
            (provider) =>
              provider.type === ProviderType.InsideSpecialist ||
              provider.type === ProviderType.OutsideSpecialist
          );
          return providers;
        //health coach
        case process.env.REACT_APP_HEALTH_COACH_ID:
          providers = allProviders?.filter(
            (provider) => provider.type === ProviderType.Coach
          );
          return providers;
        //well women's exam, medicare annual wellness, general consult, annual physical
        default:
          providers = allProviders?.filter(
            (provider) => 
              provider.type === ProviderType.Physician ||
              provider.type === ProviderType.NursePractitioner
          );
          return providers;
      }
    } else return [];
  }, [visitType.value.id, allVisitReasons, allProviders]);

  return (
    <div>
      <Heading type="03">Visit Details</Heading>
      <div className={styles.visitDetails}>
        <SearchSelect
          label="Patient"
          placeholder="Search"
          LeftIcon={MagnifyingGlass}
          width={310}
          value={selectedPatient.patient}
          onChange={handleSelectedPatientChange}
          options={
            allPatients
              ? allPatients.sort(sortByLastName).map((patient) => ({
                  avatarUrl: patient.photo || "",
                  name: patient.firstName + " " + patient.lastName,
                  value: patient.id,
                  premiumMember:
                    patient?.settings?.General?.Membership === "Premium",
                }))
              : []
          }
          disabled={disablePatientSelect}
          className={styles.search}
          loading={patientsLoading}
        />
        <Select
          label="Visit Type"
          placeholder="Select visit type"
          width={310}
          value={visitType}
          options={allVisitReasons}
          onChange={handleSelectedVisitTypeChange}
          className={styles.search}
          loading={visitsLoading}
          disabled={selectedPatient.patient.value === "" || disableVisitField}
        />
        <SearchSelect
          label="Provider"
          placeholder="Search"
          LeftIcon={MagnifyingGlass}
          width={310}
          value={selectedProvider.provider}
          onChange={handleSelectedProviderChange}
          options={filteredProviderList
            .sort(sortByLastName)
            .map((provider) => ({
              avatarUrl: provider.photo || "",
              name: provider.firstName + " " + provider.lastName,
              value: provider.id,
              secondaryText: provider.title,
            }))}
          loading={providersLoading}
          disabled={
            selectedPatient.patient.value === "" ||
            visitType.value.id === "" ||
            disableVisitField
          }
        />
      </div>
    </div>
  );
};
