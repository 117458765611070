import { useEffect, useMemo, useState } from "react"
import { Pencil } from "phosphor-react"
import { FeaturedIcon, FeaturedIconType } from "../../../components/featuredIcon/FeaturedIcon"
import { Body } from "../../../components/typography/body/Body"
import { Heading } from "../../../components/typography/heading/Heading"
import { Button, ButtonGroup } from "../../../components/button/Button"
import { MoveIcon, NourishIcon, CalmIcon } from "../../../components/customIcons/CustonIcons"
import { Resource } from "../../../lib/interfaces/resource"
import { ResourceCard } from "../../../components/resourceCard/ResourceCard"
import { Drawer } from "../../../components/drawer/Drawer"
import { ActionsEditForm, EditedActions } from "../actionsEditForm/ActionsEditForm"
import { ResourcesSelectionList } from "../resourcesSelectionList/ResourcesSelectionList"
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { ResourceDetails } from '../resourceDetails/ResourceDetails'
import { Chart } from '../../../components/chart/Chart'
import { Toggle } from '../../../components/toggle/Toggle'
import _ from 'lodash'
import { Action, ActionPlan } from "../../../lib/interfaces/carePlan"
import { PillarActionPlan } from "../../../pages/carePlan/CarePlan"
import { TextInput } from "../../../components/textInput/TextInput"
import { useBaseContext } from "../../_base/Base"
import { useResources } from "../../../lib/hooks/useResources"
import { UserInfo } from "../../../lib/interfaces/user"
import { ChartItem } from "../../../lib/hooks/useCarePlan"
import { getMonth } from "date-fns"
import styles from './style.module.css'

const mountingClassnames = {
  enter: styles.enter,
  exit: styles.exit,
  enterActive: styles.enterActive,
  exitActive: styles.exitActive,
  enterDone: styles.enterDone
}

interface pillarDictProps {
  [key: string]: {
    name: string,
    Icon: any,
    description: string,
    type: FeaturedIconType
  }
}

export const pillarDict: pillarDictProps = {
  'move': {
    name: 'Move',
    Icon: MoveIcon,
    description: 'Fitness activities',
    type: 'primary'
  },
  'nourish': {
    name: 'Nourish',
    Icon: NourishIcon,
    description: 'Healthy Eating & Hydration',
    type: 'gray'
  },
  'calm': {
    name: 'Calm',
    Icon: CalmIcon,
    description: 'Mental Health and Mindfulness',
    type: 'green'
  },
}

export const categoryNames = [
  'Cardiovascular',
  'Strength',
  'Mobility',
  'Eating Plan',
  'Hydration',
  'Foods to Add',
  'Foods to Limit',
  'Wellness Promotion',
  'Stress Management',
]

export interface ActionPlanSectionProps {
  actionPlan?: ActionPlan
  pillar: 'move' | 'calm' | 'nourish'
  patient: UserInfo
  progress: ChartItem[]
  onChange: (actionPlan: PillarActionPlan) => Promise<boolean>
}

const emptyActionPlan: ActionPlan = {
  resourceIds: [],
  comment: undefined,
  actions: []
}

export const ActionPlanSection = ({ actionPlan = emptyActionPlan, pillar, patient, progress, onChange }: ActionPlanSectionProps) => {
  const [editingComment, setEditingComment] = useState(false)
  const [resources, setResources] = useState<Resource[]>([])
  const [draftComment, setDraftComment] = useState(actionPlan.comment?.comment || '')
  const [actionsEditDrawerVisible, setActionsEditDrawerVisible] = useState(false)
  const [resourcesEditDrawerVisible, setResourcesEditDrawerVisible] = useState(false)
  const [resourceDetailsDrawerVisible, setResourceDetailsDrawerVisible] = useState(false)
  const [expandedResource, setExpandedResource] = useState<Resource>()
  const [progressInterval, setProgressInterval] = useState<'week' | 'month'>('week')
  const [deleteResourceLoading, setDeleteResourceLoading] = useState<string[]>([])
  const { user } = useBaseContext()
  const { getResource } = useResources()

  const controller = new AbortController();

  const hasActions = useMemo(() => (actionPlan?.actions && actionPlan.actions.length > 0), [actionPlan])
  const hasResources = useMemo(() => (resources.length > 0), [resources])

  useEffect(() => {
    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (actionPlan.resourceIds) {
      Promise.all(actionPlan.resourceIds.map(async (id) => {
        return await getResource(id, controller)
      })).then(setResources)
    }
  }, [actionPlan])

  useEffect(() => {
    if (expandedResource)
      setResourceDetailsDrawerVisible(true)
    else
      setResourceDetailsDrawerVisible(false)
  }, [expandedResource])

  const formatActionFrequency = (action: Action) => {
    let actionTracking = ''
    if (action.quantity || action.units) {
      actionTracking += `${action.quantity?.toString()} ${action.units || ''}, `
    }
    actionTracking += `${action.frequency}x per ${action.per}`
    return actionTracking.trim()
  }

  const weeklyProgressData = useMemo(() => progress || [], [progress])
  const monthlyProgressData = useMemo(() => {
    const filteredProgressData: ChartItem[] = []

    progress.forEach((item, i) => {
      if (filteredProgressData.length === 0)
        filteredProgressData.push(item)
      else if (getMonth(progress[i + 1]?.date) !== getMonth(item.date))
        filteredProgressData.push(item)
    })
    return filteredProgressData
  }, [progress])

  const progressData = useMemo(() => {
    if (progressInterval === 'week') return weeklyProgressData
    else return monthlyProgressData
  }, [, weeklyProgressData, monthlyProgressData, progressInterval])

  const Actions = useMemo(() => {
    const actions: JSX.Element[] = []
    const categories = _(actionPlan?.actions).groupBy(action => action.category).value()
    
    categoryNames.forEach(category => {
      if (categories[category])
        actions.push(
          <div className={styles.actionCategory} key={category}>
            <Heading type='03'>{category}</Heading>
            {categories[category].map(action => (
              <div className={styles.action} key={action.actionId}>
                <Body weight='bold'>{action.activities?.join(', ')}</Body>
                <Body>{formatActionFrequency(action)}</Body>
              </div>
            ))}
          </div>
        )
    })
    return actions
  }, [actionPlan])

  const handleCommentChange = (value: string) => {
    setDraftComment(value)
  }

  const handleCommentCancel = () => {
    setEditingComment(false)
    setDraftComment(actionPlan.comment?.comment || '')
  }

  const handleCommentSave = async () => {
    const result = await onChange({
      [pillar]: {
        ...actionPlan,
        comment: {
          comment: draftComment,
          modifiedBy: user?.sub,
        }
      }
    })
    if (result)
      setEditingComment(false)
  }

  const handleActionsEdit = async (actions: EditedActions[]) => {
    const result = await onChange({
      [pillar]: {
        ...actionPlan,
        actions: [...actions]
      }
    })
    if (result)
      setActionsEditDrawerVisible(false)
  }

  const handleRemoveResource = async (id: string) => {
    if (actionPlan) {
      setDeleteResourceLoading(currentDeleteResourceLoading => [...currentDeleteResourceLoading, id])
      const result = await onChange({
        [pillar]: {
          ...actionPlan,
          resourceIds: actionPlan.resourceIds?.filter(_id => id !== _id),
        }
      })
      setDeleteResourceLoading(currentDeleteResourceLoading => (currentDeleteResourceLoading.filter(resourceId => resourceId !== id)))
      if (result)
        setResources(resources => resources.filter(resource => resource.id !== id))
    }
  }

  const handleResourcesEdit = async (resources: Resource[]) => {
    if (actionPlan) {
      const result = await onChange({
        [pillar]: {
          ...actionPlan,
          resourceIds: resources.map(res => res.id)
        }
      })
      if (result) {
        setResources(resources)
        setResourcesEditDrawerVisible(false)
      }
    }
  }

  return (
    <div className={styles.pillarSection}>
      <div className={styles.header}>
        <div className={styles.headerTitle}>
          <FeaturedIcon Icon={pillarDict[pillar].Icon} type={pillarDict[pillar].type} />
          <div>
            <Heading type='02'>{pillarDict[pillar].name}</Heading>
            <Body size='md' weight='medium' color='secondary'>{pillarDict[pillar].description}</Body>
          </div>
        </div>
      </div>

      <div className={styles.contents}>

        <div className={styles.contentContainer}>
          <Heading type='03' className={styles.contentTitle}>Comment</Heading>
          <div className={styles.content}>
            {!editingComment ? (
              <>
                {actionPlan.comment && actionPlan.comment?.comment?.length > 0 ? (
                  <Body size='md'>{actionPlan.comment?.comment}</Body>
                ) : (
                  <Body size='md' color='secondary'>Add a comment for your patient to see in their app</Body>
                )}
              </>
            ) : (
              <>
                <TextInput placeholder='Enter your message' value={draftComment} onChange={handleCommentChange} multiline minRows={5} maxRows={5} />
                <ButtonGroup align='end'>
                  <Button label='Cancel' onClick={handleCommentCancel} type='secondary-gray' />
                  <Button label='Save' onClick={handleCommentSave} />
                </ButtonGroup>
              </>
            )}
          </div>
          {!editingComment && (<Button Icon={Pencil} onClick={() => setEditingComment(true)} size='small' type='secondary' />)}
        </div>

        <div className={styles.contentSeparator} />

        <div className={styles.contentContainer}>
          <Heading type='03' className={styles.contentTitle}>Actions</Heading>
          <div className={styles.content}>
            {!hasActions ? (
              <Body size='md' color='secondary'>Add actions for your patient</Body>
            ) : (
              <>{Actions}</>
            )}
          </div>
          <Button Icon={Pencil} onClick={() => setActionsEditDrawerVisible(true)} size='small' type='secondary' />
        </div>

        <div className={styles.contentSeparator} />

        <div className={styles.contentContainer}>
          <Heading type='03' className={styles.contentTitle}>Progress</Heading>
          <div className={styles.progress}>
            {progress.length > 0 ? (
              <>
                <Chart
                  label={progressInterval === 'week' ? 'Weekly Trends' : 'Monthly Trends'}
                  isWeekly={progressInterval === 'week'}
                  data={progressData} />
                <Toggle
                  value={progressInterval}
                  options={[
                    { name: 'W', value: 'week' },
                    { name: 'M', value: 'month' },
                  ]}
                  onChange={setProgressInterval} />
              </>
            ) : (
              <Body size='md' color='secondary'>Data will show here once Care Plan is published</Body>
            )}
          </div>
        </div>

        <div className={styles.contentSeparator} />

        <div className={styles.contentContainer}>
          <Heading type='03' className={styles.contentTitle}>Resource</Heading>
          <div className={styles.content}>
            {hasResources ? (
              <TransitionGroup className={styles.resources} component='div'>
                {resources?.map(resource => (
                  <CSSTransition timeout={200} classNames={mountingClassnames} key={resource.image}>
                    <div className={styles.resourceCardContainer}>
                      <ResourceCard
                        title={resource.title}
                        caption={resource.author}
                        photo={resource.image}
                        isVideo={(resource.type || 'article').toLowerCase() === 'video'}
                        onClick={() => setExpandedResource(resource)}
                        onRemove={() => handleRemoveResource(resource.id)}
                        deleteLoading={deleteResourceLoading.includes(resource.id)}
                      />
                    </div>
                  </CSSTransition>
                ))}
              </TransitionGroup>
            ) : (
              <Body size='md' color='secondary'>Add resources for your patient</Body>
            )}
          </div>
          <Button Icon={Pencil} onClick={() => setResourcesEditDrawerVisible(true)} size='small' type='secondary' />
        </div>
      </div>

      <Drawer title={`${pillarDict[pillar].name} Actions`} visible={actionsEditDrawerVisible} onClose={() => setActionsEditDrawerVisible(false)}>
        <ActionsEditForm pillar={pillar} actions={actionPlan?.actions || []} patient={patient} onSubmit={handleActionsEdit} />
      </Drawer>

      {actionPlan && (
        <Drawer
          title='Resources'
          visible={resourcesEditDrawerVisible}
          onClose={() => setResourcesEditDrawerVisible(false)}
          initialRoute='resource-list'
          componentMap={{
            'resource-list': {
              title: 'Resources',
              route: 'resource-list',
              component: ResourcesSelectionList,
              props: {
                defaultResources: actionPlan.resourceIds || [],
                preSelectedResources: resources,
                onSubmit: handleResourcesEdit
              }
            },
            'resource': {
              title: 'Resources',
              route: 'resource',
              component: ResourceDetails,
            }
          }}
        />
      )}

      <Drawer title='Resource' visible={resourceDetailsDrawerVisible} onClose={() => setExpandedResource(undefined)}>
        <ResourceDetails resource={expandedResource} />
      </Drawer>
    </div>
  )
}
