enum ProviderType {
  OutsideSpecialist = "outsideSpecialist",
  InsideSpecialist = "insideSpecialist",
  CareNavigator = "careNavigator",
  Physician = "physician",
  Coach = "coach",
  Triage = "triage",
  MedAssistant = "medAssistant",
  Admin = "admin",
  coachPool = "coachPool",
  NursePractitioner = "nursePractitioner"
}

export default ProviderType