import { format } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AlertContext } from "../../lib/context/context";
import { useChats } from "../../lib/hooks/useChats";
import { PatientInfo, ProviderInfo } from "../../lib/interfaces/user";
import { Avatar } from "../avatar/Avatar";
import { Button, ButtonGroup } from "../button/Button";
import { Spinner } from "../spinner/Spinner";
import { Body } from "../typography/body/Body";
import { Heading } from "../typography/heading/Heading";
import styles from "./style.module.css";

interface UserDetailsProps {
  provider?: ProviderInfo;
  patient?: PatientInfo;
  closeModal?: () => void;
}

export const UserDetails = ({
  provider,
  patient,
  closeModal,
}: UserDetailsProps) => {
  const history = useHistory();
  const { getChatWithUsers } = useChats();
  const { pushAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState(true);
  const [sendMessageLoading, setSendMessageLoading] = useState(false);

  useEffect(() => {
    if (provider || patient) {
      setLoading(false);
    }
  }, [provider, patient]);

  const sendMessage = () => {
    if (patient) {
      setSendMessageLoading(true);
      getChatWithUsers(patient.id).then((chat) => {
        if (chat) {
          history.replace({
            pathname: `messages`,
            state: { selectedChatId: chat.chat?.id, newChat: chat.newChat },
          });
          setSendMessageLoading(false);
          closeModal && closeModal();
        } else {
          pushAlert("Could not get or create chat with patient", "danger");
          closeModal && closeModal();
        }
      });
    }
  };

  return (
    <div>
      {!loading ? (
        <div className={styles.userDetails}>
          <Avatar
            className={styles.avatar}
            user={patient || provider}
            size="xxl"
          />
          <Heading data-dd-privacy="mask" className={styles.name} type="02">
            {(patient || provider)?.name}
          </Heading>

          {provider ? (
            <Body data-dd-privacy="mask" className={styles.title} color="secondary">
              {provider.title}
            </Body>
          ) : patient ? (
            <>
              <Body className={styles.title} color="secondary">
                Patient
              </Body>
              {patient?.assignedSex && patient?.dateOfBirth && (
                <Body data-dd-privacy="mask" className={styles.patientInfo} color="secondary">
                  {`${patient.assignedSex} ${
                    patient.dateOfBirth
                      ? `• Born ${format(
                          new Date(patient.dateOfBirth),
                          "MMMM d, yyyy"
                        )}`
                      : ""
                  }`}
                </Body>
              )}
            </>
          ) : (
            <></>
          )}

          {provider && provider?.bio ? (
            <>
              <Heading type="03" className={styles.bioHeading}>
                About {provider.name}
              </Heading>
              <Body className={styles.bio}>{provider.bio}</Body>
            </>
          ) : patient ? (
            <ButtonGroup className={styles.buttons}>
              <Button
                type="secondary-gray"
                label="View Profile"
                onClick={() => history.push(`/patient/${patient.id}/careplan`)}
                disabled={patient.id === ""}
              />
              <Button
                type="secondary-gray"
                label="Send Message"
                onClick={() => {
                  sendMessage();
                }}
                loading={sendMessageLoading}
                disabled={patient.id === ""}
              />
            </ButtonGroup>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div style={{ height: "389px" }}>
          {" "}
          <Spinner />{" "}
        </div>
      )}
    </div>
  );
};
