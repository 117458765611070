import clsx from "clsx";
import { Body } from "../typography/body/Body";
import { IconProps } from 'phosphor-react'
import { Minus } from 'phosphor-react';
import { FeaturedIcon } from '../../components/featuredIcon/FeaturedIcon';
import { CustomIcon } from "../customIcons/CustonIcons";
import styles from './style.module.css'

export interface VitalCardProps {
  Icon?: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>> | CustomIcon
  label: string
  data?: string
  caption?: string
  className?: string
}

export const VitalCard = ({
  Icon = Minus,
  label,
  data,
  caption,
  className
  }: VitalCardProps) => {
    return (
      <div className={clsx(styles.vitalCard, className)}>
        {Icon && <FeaturedIcon size='xl' Icon={Icon} />}
        {data ? 
          <Body data-dd-privacy="mask" className={styles.data} size='md'  weight='bold' color='link'>{data}</Body> :
          <Body className={styles.data} size='md'  weight='bold' color='link'>  </Body>
        }
        <Body className={styles.label}  data-dd-privacy="mask" size='sm' color='primary'>{label}</Body>
        {caption && <Body data-dd-privacy="mask" size='xs' color='secondary'>{caption}</Body>}
      </div>
    )
}