import { useOktaAuth } from "@okta/okta-react";
import {
  fetchVisits,
  fetchAllVisits,
  postAppointment,
  deleteAppointment,
  updateAppointment,
  rescheduleAppointment,
} from "../apis/visits"
import { Appointment, Visit } from "../interfaces/visits";
import { CellProps } from "../../components/table/Table";
import { format, isValid, parse } from "date-fns";
import { AlertContext } from "../context/context";
import { useContext } from "react";
import { usePatient } from "./usePatient";
import { useProvider } from "./useProvider";

export const useVisits = () => {
  const { oktaAuth } = useOktaAuth()
  const { getPatientApptInfo } = usePatient()
  const { getProvider } = useProvider()
  const { pushAlert } = useContext(AlertContext)
  const accessToken = oktaAuth.getAccessToken()

  const getFutureAndPastVisits = async (
    userId?: string,
    controller?: AbortController
  ) => {
    if (userId && accessToken) {
      const _visits = await fetchVisits(userId, controller)
      if (!_visits) {
        pushAlert("Failed to get future and past patients' visit info.", "danger")
      }
      if (!_visits || _visits === "cancelled")
        return {
          futureVisits: [],
          pastVisits: [],
        }
      //futureVists may contain visits that are today but at a past time, will filter those
      //out and add them to pastVisits
      const currentDateTime = format(new Date(), "yyyy-MM-dd HH:mm:ss")
      const pastVisitsToday: Visit[] = []
      const futureVisits: Visit[] = []
      if (_visits.future.length > 0) {
        for (let i = 0; i < _visits.future.length; i++) {
          const formattedVisit = {
            ..._visits.future[i],
            id: _visits.future[i]?.visitId
              ? _visits.future[i].visitId
              : _visits.future[i]?.visitIdEHR
              ? _visits.future[i].visitIdEHR
              : "",
            providerId: _visits.future[i]?.providerId
              ? _visits.future[i].providerId
              : _visits.future[i]?.providerIdEHR
              ? _visits.future[i].providerIdEHR
              : "",
            dateTime: _visits.future[i]?.dateTime?.split(" ").join("T") || "",
          }
          if (_visits.future[i].dateTime < currentDateTime)
            pastVisitsToday.push(formattedVisit)
          else futureVisits.push(formattedVisit)
        }
      }
      const pastVisits: Visit[] = _visits.past.map((visit: Visit) => ({
        ...visit,
        id: visit?.visitId
          ? visit.visitId
          : visit?.visitIdEHR
          ? visit.visitIdEHR
          : "",
        providerId: visit?.providerId
          ? visit.providerId
          : visit?.providerIdEHR
          ? visit.providerIdEHR
          : "",
        dateTime: visit?.dateTime?.split(" ").join("T") || "",
      }))
      return {
        futureVisits: futureVisits,
        pastVisits: pastVisitsToday.concat(pastVisits),
      }
    } else
      return {
        futureVisits: [],
        pastVisits: [],
      }
  }

  const getUpcomingAndLastVisit = async (
    userId: string,
    controller?: AbortController
  ) => {
    if (userId && accessToken) {
      const _visits = await fetchVisits(userId, controller)
      if (!_visits || _visits === "cancelled") {
        return {
          upcomingVisit: {
            visitId: "",
            visitType: "",
            visitTypeId: "",
            visitIdEHR: "",
            userId: "",
            providerId: "",
            providerIdEHR: "",
            providerName: "",
            dateTime: "",
            duration: "",
          },
          pastVisit: {
            visitId: "",
            visitType: "",
            visitTypeId: "",
            visitIdEHR: "",
            userId: "",
            providerId: "",
            providerIdEHR: "",
            providerName: "",
            dateTime: "",
            duration: "",
          },
          status: "error",
        }
      }

      const upcomingVisit: Visit = findClosestFutureDateTime(_visits.future)
      const pastVisit: Visit =
        _visits.past.length > 0
          ? {
              ..._visits.past[0],
              id: _visits.past[0]?.visitId
                ? _visits.past[0].visitId
                : _visits.past[0]?.visitIdEHR
                ? _visits.past[0].visitIdEHR
                : "",
              providerId: _visits.past[0]?.providerId
                ? _visits.past[0].providerId
                : _visits.past[0]?.providerIdEHR
                ? _visits.past[0].providerIdEHR
                : "",
              dateTime: _visits.past[0].dateTime
                ? _visits.past[0].dateTime.split(" ").join("T")
                : "",
            }
          : {
              visitId: "",
              visitType: "",
              visitTypeId: "",
              visitIdEHR: "",
              userId: "",
              providerId: "",
              providerIdEHR: "",
              providerName: "",
              dateTime: "",
              duration: "",
            }
      return {
        upcomingVisit: upcomingVisit,
        pastVisit: pastVisit,
        status: "success",
      }
    } else
      return {
        upcomingVisit: {
          visitId: "",
          visitType: "",
          visitTypeId: "",
          visitIdEHR: "",
          userId: "",
          providerId: "",
          providerIdEHR: "",
          providerName: "",
          dateTime: "",
          duration: "",
        },
        pastVisit: {
          visitId: "",
          visitType: "",
          visitTypeId: "",
          visitIdEHR: "",
          userId: "",
          providerId: "",
          providerIdEHR: "",
          providerName: "",
          dateTime: "",
          duration: "",
        },
        status: "error",
      }
  }

  const getUpcomingVisit = (futureVisits: Visit[]) => {
    const currentDateTime = new Date()
    const emptyUpcomingVisit: Visit = {
      visitType: "",
      visitTypeId: "",
      duration: "",
      visitId: "",
      visitIdEHR: "",
      userId: "",
      providerId: "",
      providerIdEHR: "",
      providerName: "",
      dateTime: "",
    }
    if (futureVisits.length > 0) {
      const nextVisit = futureVisits.find((visit) => {
        const visitTime = parse(
          visit.dateTime.split("T").join(" "),
          "yyyy-MM-dd HH:mm:ss",
          new Date()
        )
        return visitTime.getTime() > currentDateTime.getTime()
      })
      if (nextVisit) return nextVisit
    }
    return emptyUpcomingVisit
  }

  const getFriendlyDate = (unfriendlyDate: string) => {
    const browserFriendlyDate =
      unfriendlyDate.substring(0, 10) + "T" + unfriendlyDate.substring(10 + 1)
    if (!isValid(new Date(browserFriendlyDate))) {
      return ""
    }
    const date = new Date(browserFriendlyDate)
    return format(date, "MM/d/yyyy") + " at " + format(date, "h:mm a")
  }

  const initUpcomingVisits = (
    data: Visit[],
    handleViewVisitClick: (visitId: string) => void
  ) => {
    const updatedUpcomingVisits: CellProps[][] = []
    data.map((visit) => {
      const row: CellProps[] = []
      row.push(visit.dateTime !== "" ? getFriendlyDate(visit.dateTime) : "")
      row.push(visit.visitType ?? "")
      row.push(visit.providerName)
      visit?.visitId
        ? //@ts-ignore
          row.push({
            label: "View Visit",
            onClick: () => {
              handleViewVisitClick(visit.visitId ?? "")
            },
          })
        : row.push("")
      updatedUpcomingVisits.push(row)
    })
    return updatedUpcomingVisits
  }

  const initPastVisits = (data: Visit[]) => {
    const updatedPastVisits: CellProps[][] = []
    data.map((visit) => {
      const row: CellProps[] = []
      row.push(visit.dateTime !== "" ? getFriendlyDate(visit.dateTime) : "")
      row.push(visit.visitType ?? "")
      row.push(visit.providerName)
      row.push("")
      updatedPastVisits.push(row)
    })
    return updatedPastVisits
  }

  const getFutureAndPastVisitsTableData = async (
    futureVisits: Visit[],
    pastVisits: Visit[],
    handleViewVisitClick: (visitId: string) => void,
    userId?: string
  ) => {
    let futureVisitsTableData: CellProps[][] = []
    let pastVisitsTableData: CellProps[][] = []
    if (accessToken && userId) {
      futureVisitsTableData = initUpcomingVisits(
        futureVisits,
        handleViewVisitClick
      )
      pastVisitsTableData = initPastVisits(pastVisits)
      return {
        futureVisitsTableData: futureVisitsTableData,
        pastVisitsTableData: pastVisitsTableData,
      }
    } else
      return {
        futureVisitsTableData: [],
        pastVisitsTableData: [],
      }
  }

  const getAppointmentInfoFromVisit = async (visit: Visit) => {
    //get patient information for that visit
    const [patient, provider, careCoordinator] = await Promise.all([
      getPatientApptInfo(visit.userId),
      getProvider(visit.providerId),
      visit.checkingInWith ? getProvider(visit.checkingInWith) : undefined,
    ])
    const v: Appointment = {
      ...visit,
      visitId: visit.visitId || visit.visitIdEHR || "",
      userId: visit.userId || "",
      providerId: visit.providerId || "",
      providerName: visit.providerName || "",
      visitType: visit.visitType,
      duration: visit.duration,
      appointmentType: visit.appointmentType || "",
      dateTime: visit.dateTime.split(" ").join("T") || "",
      patient,
      provider,
      careCoordinator,
    }
    return v
  }

  const getAllAppointments = async (startDate: string, endDate: string) => {
    if (accessToken) {
      const _visits = await fetchAllVisits(startDate, endDate)
      if (!_visits) {
        pushAlert("Failed to get patient's appoiments info", "danger")
        return []
      }
      const futureAppointments: Appointment[] = await Promise.all(
        _visits.future.map(getAppointmentInfoFromVisit)
      )
      const pastAppointments: Appointment[] = await Promise.all(
        _visits.past.map(getAppointmentInfoFromVisit)
      )

      const appointments: Appointment[] = pastAppointments.concat(
        futureAppointments
      )
      return appointments
    } else return []
  }

  const getAllVisits = async (
    startDate: string,
    endDate: string,
    providerId?: string
  ) => {
    if (accessToken) {
      const _visits = await fetchAllVisits(startDate, endDate, providerId)
      if (!_visits) {
        pushAlert("Failed to get all patients' visit info.", "danger")
        return []
      }
      const futureVisits: Visit[] = _visits.future.map((visit: Visit) => ({
        ...visit,
        id: visit?.visitId
          ? visit.visitId
          : visit?.visitIdEHR
          ? visit.visitIdEHR
          : "",
        providerId: visit?.providerId
          ? visit.providerId
          : visit?.providerIdEHR
          ? visit.providerIdEHR
          : "",
        //add a 'T' to comply with safari dateTime requirements
        dateTime: visit?.dateTime?.split(" ").join("T") || "",
      }))
      const pastVisits: Visit[] = _visits.past.map((visit: Visit) => ({
        ...visit,
        id: visit?.visitId
          ? visit.visitId
          : visit?.visitIdEHR
          ? visit.visitIdEHR
          : "",
        providerId: visit?.providerId
          ? visit.providerId
          : visit?.providerIdEHR
          ? visit.providerIdEHR
          : "",
        dateTime: visit?.dateTime?.split(" ").join("T") || "",
      }))
      const appointments: Visit[] = pastVisits.concat(futureVisits)
      return appointments
    } else return []
  }

  const getOneVisitData = async (userId: string, clickedVisitId?: string) => {
    if (accessToken) {
      const _oneVisit = await fetchVisits(userId)
      if (!_oneVisit) {
        pushAlert("Failed to get patient's visit info", "danger")
        return {
          id: "",
          visitId: "",
          visitIdEHR: "",
          userId: "",
          providerId: "",
          providerIdEHR: "",
          providerName: "",
          dateTime: "",
        }
      }
      const allVisits = _oneVisit.past.concat(_oneVisit.future)

      for (let i = 0; i < allVisits.length; i++) {
        if (allVisits[i].visitId === clickedVisitId) {
          return allVisits[i]
        }
      }
      return {
        id: "",
        visitId: "",
        visitIdEHR: "",
        userId: "",
        providerId: "",
        providerIdEHR: "",
        providerName: "",
        dateTime: "",
      }
    }
    return {
      id: "",
      visitId: "",
      visitIdEHR: "",
      userId: "",
      providerId: "",
      providerIdEHR: "",
      providerName: "",
      dateTime: "",
    }
  }

  //post an appointment when a new appointment is scheduled
  const scheduleAppointment = async (
    appointmentData: any,
    visitId?: string
  ) => {
    if (accessToken) {
      if (!visitId) {
        const res = await postAppointment({ ...appointmentData, visitId })
        return res //res is "success" or "error"
      } else {
        const {
          providerId,
          userId,
          timeSlot,
          apptType,
          visitType,
        } = appointmentData
        const res = await rescheduleAppointment({
          providerId,
          userId,
          timeSlot,
          visitType,
          visitId,
          apptType,
        })
        return res //res is "success" or "error"
      }
    } else console.log("accessToken failed")
  }

  const cancelAppointment = async (visitId: string, userId: string) => {
    if (accessToken) {
      const result = await deleteAppointment(visitId, userId)
      if (result.status === 200) {
        return true
      } else {
        pushAlert("Failed to cancel appointment", "danger")
        return false
      }
    }
    return false
  }

  const updateCareStatus = async (
    visitId: string,
    careGiven?: boolean,
    checkingInWith?: string
  ) => {
    if (accessToken) {
      const result = await updateAppointment(visitId, careGiven, checkingInWith)
      return result as Visit | undefined
      // return result.data as Visit | undefined
    }
    return undefined
  }

  const getLastVisit = (visits: Visit[]) => {
    const emptyVisit: Visit = {
      visitId: "",
      visitType: "",
      visitTypeId: "",
      visitIdEHR: "",
      userId: "",
      providerId: "",
      providerIdEHR: "",
      providerName: "",
      dateTime: "",
      duration: "",
    };

    if (!visits.length) {
      return emptyVisit;
    }

    const lastVisit = visits[0];
    return {
      ...lastVisit,
      id: lastVisit.visitId || lastVisit.visitIdEHR || "",
      providerId: lastVisit?.providerId || lastVisit?.providerIdEHR || "",
      dateTime: lastVisit.dateTime?.split(" ").join("T") || "",
    };
  }

  const findClosestFutureDateTime = (dateArray: Visit[]): Visit => {
    const currentDate = new Date();
    let closestDateTimeObj: Visit | undefined = undefined;
    let closestDifference = Infinity;
    const emptyUpcomingVisit: Visit = {
      visitType: "",
      visitTypeId: "",
      duration: "",
      visitId: "",
      visitIdEHR: "",
      userId: "",
      providerId: "",
      providerIdEHR: "",
      providerName: "",
      dateTime: "",
    }
    if (dateArray.length === 0) {
      return emptyUpcomingVisit;
    }
    dateArray.forEach(({ dateTime, ...otherKeys }) => {
      const parsedDateTime = new Date(dateTime);
      const difference = parsedDateTime.getTime() - currentDate.getTime();  
      if (difference > 0 && difference < closestDifference) {
        closestDateTimeObj = { dateTime, ...otherKeys };
        closestDifference = difference;
      }
    });
    return closestDateTimeObj || emptyUpcomingVisit;
  };

  return {
    getFutureAndPastVisits,
    getAppointmentInfoFromVisit,
    getUpcomingAndLastVisit,
    getUpcomingVisit,
    getFutureAndPastVisitsTableData,
    getAllAppointments,
    getAllVisits,
    getOneVisitData,
    scheduleAppointment,
    cancelAppointment,
    updateCareStatus,
    getLastVisit,
    findClosestFutureDateTime
  }
}
