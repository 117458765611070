import { Heading } from "../../../components/typography/heading/Heading"
import { Button, ButtonGroup } from "../../../components/button/Button"
import { AppointmentDropdown } from "../../../components/appointmentDropdown/AppointmentDropdown"
import { AppointmentSlots } from "../../../lib/interfaces/schedule"
import { Option } from "../../../lib/interfaces/input"
import { CaretLeft, CaretRight } from "phosphor-react"
import { Spinner } from "../../../components/spinner/Spinner";
import styles from './style.module.css'
import { useMemo } from "react"

export interface SelectDateProps {
  loading: boolean
  handlePrevWeekClick: () => void,
  handleThisWeekClick: () => void,
  handleNextWeekClick: () => void,
  selectDateDisabled: boolean,
  availableAppts: AppointmentSlots[],
  selectedTime: Option,
  handleOptionSelect: (option: Option, date: string) => void
}

export const SelectDate = ({loading, handlePrevWeekClick, handleThisWeekClick, handleNextWeekClick, selectDateDisabled, availableAppts, selectedTime, handleOptionSelect} : SelectDateProps) => {
  interface selectedDate {
    dateTime: string,
  }

  const options: Option[][] = useMemo(() => 
    availableAppts.map((day) => 
      day.times.map((time) => {
        const value: selectedDate = {
          dateTime: time.value,
        }
        return {
          id: value.dateTime,
          name: time.name,
          value: value
        }
      })
    )
  ,[availableAppts])
  
  return (
      <div>
        <div className={styles.selectDate}>
          <Heading type='03'>Select a Date</Heading>
          <ButtonGroup>
          <Button onClick={() => {handlePrevWeekClick()}} Icon={CaretLeft} size='small' type='secondary-gray' disabled={selectDateDisabled}></Button>
          <Button onClick={() => {handleThisWeekClick()}} label={'This Week'} size='small' type='secondary-gray' disabled={selectDateDisabled}></Button>
          <Button onClick={() => {handleNextWeekClick()}} Icon={CaretRight} size='small' type='secondary-gray' disabled={selectDateDisabled}></Button>
          </ButtonGroup>
        </div>
        <div className={styles.dropdowns}>
        { !loading ? 
          <div className={styles.appointmentDropdowns}>
            { availableAppts.map((day, i) => {       
              return (
                  <AppointmentDropdown 
                    key={day.date} 
                    value={selectedTime} 
                    onChange={handleOptionSelect} 
                    today={day.today} 
                    dayOfWeek={day.dayOfWeek} 
                    date={day.date} 
                    initialNumberAvailable={day.times.length} 
                    options={options[i]} 
                    disabled={day.times.length === 0 || selectDateDisabled}
                  />
              )
              })
            }
          </div>
        :
          <Spinner />
        }
        </div>
      </div>
    )
}