import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button, ButtonGroup } from "../../components/button/Button";
import { Modal } from "../../components/modal/Modal";
import { PatientInfo } from "../../lib/interfaces/user";
import { Paperclip, File as FileIcon } from "phosphor-react";
import { CellProps, Table } from "../../components/table/Table";
import { Body } from "../../components/typography/body/Body";
import { Tag } from "../../components/tag/Tag";
import { format } from "date-fns";
import { Spinner } from "../../components/spinner/Spinner";
import { useFiles } from "../../lib/hooks/useFiles";
import { UploadFilePayload } from "../../lib/apis/files";
import { File } from "../../lib/interfaces/file";
import { AlertContext, BaseContext } from "../../lib/context/context";
import _ from "lodash";
import clsx from "clsx";
import styles from "./style.module.css";
import { getLocalDateTime } from "../../lib/util/date";

const columns = ["Shared On", "Shared By", "File Name"];

export interface PatientFilesProps {
  patient: PatientInfo;
  setPrimaryAction: (_?: any) => void;
}

export interface AddedFile {
  name: string;
  file: globalThis.File;
}

export const PatientFiles = ({
  patient,
  setPrimaryAction,
}: PatientFilesProps) => {
  const { getFiles, getFile, uploadFile, removeFile } = useFiles();
  const { pushAlert, clearAlerts } = useContext(AlertContext);
  const { allProviders } = useContext(BaseContext);

  const controller = new AbortController();

  const [loading, setLoading] = useState<boolean>(true);
  const [fileUploading, setFileUploading] = useState<boolean>(false);
  const [fileDeleting, setFileDeleting] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>();
  const [addedFiles, setAddedFiles] = useState<AddedFile[]>([]);
  const [viewUploadModal, setViewUploadModal] = useState<boolean>(false);
  const [viewDeleteModal, setViewDeleteModal] = useState<boolean>(false);
  const [fileToDelete, setFileToDelete] = useState<string>();
  const [duplicateFileUpload, setDuplicateFileUpload] = useState<boolean>(
    false
  );
  const filePickerRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setPrimaryAction(() => () => {
      setViewUploadModal(true);
    });

    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (patient && allProviders) {
      getFiles(patient.id, controller).then(setFiles);
    }
  }, [patient, allProviders]);

  useEffect(() => {
    if (files) setLoading(false);
  }, [files]);

  const sortFilesByDate = () => {
    if (files) {
      return files.sort((fileA, fileB) => {
        return new Date(fileA.sharedOn) < new Date(fileB.sharedOn) ? 1 : -1;
      });
    } else return [];
  };

  const filesTableData: CellProps[][] = useMemo(() => {
    const tableData: CellProps[][] = [];
    const sortedFiles = sortFilesByDate();
    if (sortedFiles.length > 0) {
      sortedFiles.map((file) => {
        let provider;
        if (allProviders)
          provider = allProviders.find((p) => p.id === file.sharedBy);

        const row: CellProps[] = [];
        row.push(
          format(new Date(getLocalDateTime(file.sharedOn)), "MM/d/yyyy") +
            " at " +
            format(new Date(getLocalDateTime(file.sharedOn)), "h:mm a")
        );
        row.push(provider ? provider.name : "");
        row.push({
          label: file.name,
          onClick: () => {
            handleFileClick(file.id);
          },
          linkOnly: true,
        });
        row.push({
          label: "Delete",
          onClick: () => {
            handleDeleteClick(file.id);
          },
        });
        tableData.push(row);
      });
      return tableData;
    } else return [];
  }, [files]);

  const handleFileClick = async (fileId: string) => {
    //need to get an updated url since urls expire after 5 min
    pushAlert("Please wait for the file to open.", "neutral", false, false);
    const file = await getFile(patient.id, fileId);
    if (file.length > 0) {
      window.open(file[0].url, "_blank");
      clearAlerts();
    } else pushAlert("Can not open file. File does not have a url.", "danger");
  };

  const handleAddAttachmentClick = () => {
    filePickerRef.current?.click();
  };

  const handleFilePick = async (_files: FileList | null) => {
    const updFiles = files
      ? Object.values(_files || {}).filter(
          (f) => !files.map((file) => file.name).includes(f.name)
        )
      : [];
    if (updFiles.length > 0) {
      if (duplicateFileUpload) setDuplicateFileUpload(false);
      let updAddedFiles: AddedFile[] = _.cloneDeep(addedFiles);
      updAddedFiles = [
        ...updAddedFiles,
        ...updFiles.map((file) => ({ name: file.name, file })),
      ];
      setAddedFiles(updAddedFiles);
    } else setDuplicateFileUpload(true);
  };

  const handleDeleteAddedFile = async (file: globalThis.File) => {
    if (_.find(addedFiles, (_file) => _file.name === file.name))
      setAddedFiles(addedFiles.filter((_file) => _file.name !== file.name));
    if (files) setFiles(files.filter((_file) => _file.name !== file.name)); //NOT SURE IF I NEED THIS, NEED TO TEST IT
  };

  const handleSave = async () => {
    setFileUploading(true);
    const fileUploadResponses = addedFiles.map((_file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async () => {
          if (reader.result) {
            const fileToUpload: UploadFilePayload = {
              userId: patient.id,
              name: _file.name,
              file: reader.result.toString(),
            };
            try {
              const res = await uploadFile(fileToUpload);
              if (res.data) resolve(true);
              else resolve(false);
            } catch (err) {
              reject(err);
            }
          } else resolve(false);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(_file.file);
      });
    });
    const fileUploadRes = await Promise.all(fileUploadResponses);
    setFileUploading(false);
    if (!fileUploadRes.includes(false))
      pushAlert("Files added successfully", "success");
    else pushAlert("Files unable to be uploaded. Please try again.", "danger");
    //refresh the files table
    setLoading(true);
    getFiles(patient.id).then(setFiles);
    setViewUploadModal(false);
    setAddedFiles([]);
  };

  const handleDeleteClick = (fileId: string) => {
    setViewDeleteModal(true);
    setFileToDelete(fileId);
  };

  const handleDeleteFile = async () => {
    setFileDeleting(true);
    const file = files
      ? files.find((_file) => _file.id === fileToDelete)
      : undefined;
    if (file) {
      const res = await removeFile({ userId: file.userId, id: file.id });
      if (res === "success" && files)
        setFiles(files.filter((_file) => _file.id !== fileToDelete));
    }
    setFileDeleting(false);
    setViewDeleteModal(false);
    setFileToDelete(undefined);
  };

  const handleCloseUploadModal = () => {
    setViewUploadModal(false);
    setAddedFiles([]);
    setDuplicateFileUpload(false);
  };

  return (
    <>
      {!loading ? (
        <div className={styles.table}>
          <Table
            data={filesTableData}
            friendlyTitle="records"
            columns={columns}
          />
        </div>
      ) : (
        <Spinner />
      )}
      <Modal
        onCloseModal={() => {
          handleCloseUploadModal();
        }}
        title="New File"
        visible={viewUploadModal}
      >
        <>
          {addedFiles.length > 0 ? (
            <div className={styles.attachments}>
              {addedFiles.map((addedFile) => (
                <Tag
                  key={addedFile.name}
                  content={addedFile.name}
                  type="black"
                  size="lg"
                  Icon={FileIcon}
                  onDelete={() => handleDeleteAddedFile(addedFile.file)}
                />
              ))}
            </div>
          ) : (
            <Body
              color="secondary"
              size="sm"
              className={clsx(
                !duplicateFileUpload ? styles.hint : styles.duplicateFileError
              )}
            >
              Add files that the patient has requested
            </Body>
          )}
          {duplicateFileUpload && (
            <Body color="secondary" size="sm" style={{ paddingBottom: "32px" }}>
              You have already uploaded a file with that name. Please upload a
              different file or rename your file.
            </Body>
          )}
          <ButtonGroup className={styles.buttons}>
            <Button
              label="Add Attachment"
              type="secondary-gray"
              onClick={handleAddAttachmentClick}
              Icon={Paperclip}
            />
            <input
              ref={filePickerRef}
              type="file"
              accept="image/*,.pdf"
              style={{ display: "none" }}
              onChange={(e) => handleFilePick(e.target.files)}
            />
            <Button
              onClick={handleSave}
              label="Save and Close"
              disabled={addedFiles.length === 0}
              loading={fileUploading}
            />
          </ButtonGroup>
        </>
      </Modal>
      <Modal
        onCloseModal={() => {
          setViewDeleteModal(false);
          setFileToDelete(undefined);
        }}
        title="Confirm Delete"
        dismissable={false}
        visible={viewDeleteModal}
      >
        <Body style={{ paddingBottom: 32 }}>
          Are you sure you want to delete this shared file? The patient will no
          longer have access to it once deleted.
        </Body>
        <ButtonGroup className={styles.buttons}>
          <Button
            label="Cancel"
            type="secondary-gray"
            onClick={() => {
              setViewDeleteModal(false);
              setFileToDelete(undefined);
            }}
          />
          <Button
            label="Delete"
            onClick={() => {
              handleDeleteFile();
            }}
            loading={fileDeleting}
          />
        </ButtonGroup>
      </Modal>
    </>
  );
};
