import { useEffect, useState, useContext } from "react";
import { useInView } from "react-intersection-observer";
import { RequiredApprovals } from '../requiredApprovals/RequiredApprovals';
import { UpcomingAppointment } from "../upcomingAppointment/UpcomingAppointment";
import { PatientInformation } from "../patientInformation/PatientInformation";
import { Vitals } from "../vitals/Vitals";
import { Medications } from "../medications/Medications";
import { Notes } from "../notes/Notes";
import { Orders } from "../orders/Orders";
import { Visits } from "../visits/Visits";
import { MedicalHistory } from "../medicalHistory/MedicalHistory";
import {
  Tabs,
  ActiveRecordTab,
  ActiveCarePlanTab,
} from "../../../components/tabs/Tabs";
import styles from "./style.module.css";
import { PatientInfo } from "../../../lib/interfaces/user";
//@ts-ignore
import smoothscroll from "smoothscroll-polyfill";
import { useVisits } from "../../../lib/hooks/useVisits";
import { Visit } from "../../../lib/interfaces/visits";
import { BaseContext } from "../../../lib/context/context";

smoothscroll.polyfill();

export interface RecordContent {
  patient: PatientInfo;
}

export interface recordSections {
  upcomingAppointment: boolean;
  patientInformation: boolean;
  vitals: boolean;
  visits: boolean;
  notes: boolean;
  medications: boolean;
  orders: boolean;
  medicalHistory: boolean;
}

export const RecordContent = ({ patient }: RecordContent) => {
  const { getFutureAndPastVisits } = useVisits();
  const [futureVisits, setFutureVisits] = useState<Visit[]>();
  const [pastVisits, setPastVisits] = useState<Visit[]>();
  const { userInfo } = useContext(BaseContext);
  const controller = new AbortController();
  const [requiredApprovalsRef, requiredApprovalsInView] = useInView({threshold: 0.5});
  const [upcomingAppointmentRef, upcomingAppointmentInView] = useInView({
    threshold: 0.5,
  });
  const [patientInformationRef, patientInformationInView] = useInView({
    threshold: 0.5,
  });
  const [vitalsRef, vitalsInView] = useInView({ threshold: 0.5 });
  const [visitsRef, visitsInView] = useInView({ threshold: 0.5 });
  const [notesRef, notesInView] = useInView({ threshold: 0.5 });
  const [medicationsRef, medicationsInView] = useInView({ threshold: 0.5 });
  const [ordersRef, ordersInView] = useInView({ threshold: 0.5 });
  const [medicalHistoryRef, medicalHistoryInView] = useInView({
    threshold: 0.5,
  });

  const [activeTab, setActiveTab] = useState<ActiveRecordTab>();

  const rolesForVerificationTab = ["Care Navigator", "Health Coach", "Site Administrator"];
  const showVerificationTab = (): boolean => {
    return !!userInfo?.title && rolesForVerificationTab.includes(userInfo.title);
  }; 

  const getActiveTab = (): ActiveRecordTab => {
    if (showVerificationTab()) {
      if (requiredApprovalsInView) return "requiredApprovals";
    }
    if (upcomingAppointmentInView) return "upcomingAppointment";
    if (patientInformationInView) return "patientInformation";
    if (vitalsInView) return "vitals";
    if (visitsInView) return "visits";
    if (notesInView) return "notes";
    if (medicationsInView) return "medications";
    if (ordersInView) return "orders";
    if (medicalHistoryInView) return "medicalHistory";
    return undefined;
  };

  const handleTabChange = (newTab: ActiveRecordTab | ActiveCarePlanTab) => {
    if (newTab)
      document.getElementById(newTab)?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (patient) {
      getFutureAndPastVisits(patient.id, controller).then((_visits) => {
        setFutureVisits(_visits.futureVisits);
        setPastVisits(_visits.pastVisits);
      });
    }
  }, [patient]);

  useEffect(() => {
    setActiveTab(getActiveTab());
  }, [
    requiredApprovalsInView,
    upcomingAppointmentInView,
    patientInformationInView,
    vitalsInView,
    visitsInView,
    notesInView,
    medicationsInView,
    ordersInView,
    medicalHistoryInView,
  ]);

  return (
    <div className={styles.record}>
      <Tabs
        type={"record"}
        activeTab={activeTab}
        onTabClick={handleTabChange}
        showVerificationTab={showVerificationTab()}
      />
      <div className={styles.recordSections}>
        {showVerificationTab() && <div
              className={styles.topSection}
              id="requiredApprovals"
              ref={requiredApprovalsRef}
            >
          <RequiredApprovals patient={patient} />
        </div>}
        <div
          className={showVerificationTab()? styles.section: styles.topSection}
          id="upcomingAppointment"
          ref={upcomingAppointmentRef}
        >
          <UpcomingAppointment data-dd-privacy="mask" patient={patient} futureVisits={futureVisits} />
        </div>
        <div
          data-dd-privacy="mask" 
          className={styles.section}
          id="patientInformation"
          ref={patientInformationRef}
        >
          <PatientInformation patient={patient} />
        </div>
        <div className={styles.section} id="vitals" ref={vitalsRef}>
          <div>
            <Vitals patient={patient} />
          </div>
        </div>
        <div className={styles.section} id="visits" ref={visitsRef}>
          <Visits
            patient={patient}
            futureVisits={futureVisits}
            pastVisits={pastVisits}
          />
        </div>
        <div className={styles.section} id="notes" ref={notesRef}>
          <Notes data-dd-privacy="mask" patient={patient} />
        </div>
        <div data-dd-privacy="mask"  className={styles.section} id="medications" ref={medicationsRef}>
          <Medications data-dd-privacy="mask" patient={patient} />
        </div>
        <div className={styles.section} id="orders" ref={ordersRef}>
          <Orders data-dd-privacy="mask" patient={patient} />
        </div>
        <div
          data-dd-privacy="mask" 
          className={styles.section}
          id="medicalHistory"
          ref={medicalHistoryRef}
        >
          <MedicalHistory patient={patient} />
        </div>
      </div>
    </div>
  );
};
