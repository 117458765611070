import { useEffect, useState } from "react";
import { Heading } from "../../../components/typography/heading/Heading";
import { Table } from "../../../components/table/Table";
import { Drawer } from "../../../components/drawer/Drawer";
import { ResultDetail } from "../resultDetail/ResultDetail";
import { CellProps } from "../../../components/table/Table";
import { useOrders } from "../../../lib/hooks/useOrders";
import { Result } from "../../../lib/interfaces/orders";
import styles from "./style.module.css";
import { PatientInfo } from "../../../lib/interfaces/user";
import { Spinner } from "../../../components/spinner/Spinner";
import { TagType } from "../../../components/tag/Tag";
import axios from "axios";

export interface OrdersProps {
  patient: PatientInfo;
}

export const Orders = ({ patient }: OrdersProps) => {
  const { getOrdersTableData } = useOrders();

  const controller = new AbortController();

  const [loading, setLoading] = useState<boolean>(true);
  const [orders, setOrders] = useState<CellProps[][]>([]);
  const [viewResultDetail, setViewResultDetail] = useState(false);
  const [resultId, setResultId] = useState("");
  const [orderDetails, setOrderDetails] = useState<Result>({
    id: "",
    type: "",
    tag: "",
    tagType: "neutral",
    resultRequestor: "",
    resultDate: "",
    notes: "",
    orders: [],
    resultSignedBy: "",
  });

  const columns = ["Date", "Type", "Provider", "Status"];

  useEffect(() => {
    //call API here
    getOrdersTableData(patient.id, handleViewResultClick, controller).then(
      (_orders) => {
        setOrders(_orders);
        setLoading(false);
      }
    );
    // let mockedData: CellProps[][] = [
    //     ['03/7/2021', 'General Consult', 'Cardiologist Cupid, MD', {content: 'Draft', type:'neutral'}, {label:'View Order', onClick:()=>{setResultId("result1")}}],
    //     ['03/7/2021', 'Bench Press (regime/therapy)', 'Physician Family Medicine, MD', {content: 'Active', type: 'info'}, {label:'View Order', onClick:()=>{setResultId("result2")}}],
    //     ['03/7/2021', 'Comprehensive Metabolic Panel', 'Physician Family Medicine, MD', {content: 'Suspended', type: 'neutral'}, {label:'View Order', onClick:()=>{setResultId("result3")}}],
    //     ['03/7/2021', 'Comprehensive Metabolic Panel', 'Physician Family Medicine, MD', {content: 'Cancelled', type: 'danger'}, {label:'View Order', onClick:()=>{setResultId("result4")}}]
    // ]
    // setOrders(mockedData)
    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (resultId !== "") {
      setViewResultDetail(true);
    } else {
      setViewResultDetail(false);
    }
  }, [resultId]);

  useEffect(() => {
    if (orderDetails.id.length > 0) {
      setViewResultDetail(true);
    }
  }, [orderDetails]);

  const handleViewDetailCancel = () => {
    setViewResultDetail(false);
    setResultId("");
  };

  const handleViewResultClick = (
    id: string,
    type: string,
    tag: string,
    tagType: TagType,
    resultRequestor: string,
    resultDate: string,
    notes: string,
    orders: CellProps[][],
    resultSignedBy: string
  ) => {
    setOrderDetails({
      id: id,
      type: type,
      tag: tag,
      tagType: tagType,
      resultRequestor: resultRequestor,
      resultDate: resultDate,
      notes: notes,
      orders: orders,
      resultSignedBy: resultSignedBy,
    });
  };

  return (
    <div className={orders.length === 0 ? styles.borderBottom : ""}>
      <Heading type="02" className={styles.header}>
        Orders
      </Heading>
      {!loading ? (
        <Table data={orders} columns={columns} friendlyTitle="orders" />
      ) : (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      )}
      <Drawer
        initialRoute="page1"
        onClose={() => {
          handleViewDetailCancel();
        }}
        visible={viewResultDetail}
        title={orderDetails.type}
        tag={orderDetails.tag}
        tagType={orderDetails.tagType}
      >
        <ResultDetail
          patientId={patient.id}
          orderId={orderDetails.id}
          resultRequestor={
            orderDetails?.resultRequestor ? orderDetails?.resultRequestor : ""
          }
          resultDate={orderDetails?.resultDate ? orderDetails.resultDate : ""}
          orders={orderDetails?.orders ? orderDetails.orders : []}
          resultSignedBy={
            orderDetails?.resultSignedBy ? orderDetails.resultSignedBy : ""
          }
        />
      </Drawer>
    </div>
  );
};
