import axios, { AxiosResponse } from "axios";

export interface Slot {
  timeSlot: string
}

export interface ScheduleDate {
  date: string
  dateDisplay: string
  departmentId?: string
  availableTimes: Slot[]
}

interface ProviderSchedule {
  providerID: string;
  dates: ScheduleDate[];
}

interface ScheduleResponse extends AxiosResponse {
  data: ProviderSchedule[];
}

type FetchProviderParams = {
  providerId: string
  startDate: string
  endDate: string
  visitType: string | number
  callingUserType: string
}

export const fetchProviderSchedule = async (params: FetchProviderParams) => {
  const result = await axios
    .get<ScheduleResponse, ScheduleResponse>("/schedule", {
      params,
    })
    .catch((err) => err)
  return result.data
}