import { useOktaAuth } from "@okta/okta-react";
import { useContext } from "react";
import { fetchVisitReasonById, fetchVisitReasons, fetchVisitReasonsByGender, VisitReason } from "../apis/visitReasons";
import { Option } from "../interfaces/input";
import { AlertContext } from "../context/context";
import _ from "lodash";

// const visitTypeOptions: Option[] = [
//     { name: 'General Consult', secondaryText: 'In-Person or Virtual', value: 'generalConsult' },
//     { name: 'Annual Physical', secondaryText: 'In-Person only', value: 'annualPhysical' },
//     { name: 'Medicare / Annual Wellness', secondaryText: 'In-Person or Virtual', value: 'medicareAnnualWellness' },
//     { name: 'Health Coach', secondaryText: 'In-Person only', value: 'healthCoach' },
//     { name: 'Women\'s Health', secondaryText: 'In-Person or Virtual', value: 'womensHealth' },
//     { name: 'Specialist Consult', secondaryText: 'In-Person or Virtual', value: 'specialistConsult' }
// ]

export const useVisitReasons = () => {
    const { oktaAuth } = useOktaAuth()
    const { pushAlert } = useContext(AlertContext)
    const accessToken = oktaAuth.getAccessToken()

    //get all visit reasons
    const getVisitReasons = async () => {
        if (accessToken) {
            const _visitReasons = await fetchVisitReasons()
            if(!_visitReasons){
              pushAlert("Failed to get all visit reasons", 'danger')
              return []
            }
            const visitReasons: Option[] = _visitReasons.map((reason: VisitReason) => ({
                name: reason.displayTitle,
                title: reason.title,
                secondaryText: reason.appointmentType.length === 2 ? 'In-Person or Virtual' : (reason.appointmentType[0] === 'inperson' ? 'In-Person only' : 'Virtual only'),
                value: {
                  id: reason.id,
                  duration: reason.visitLength
                }
            }))
            return visitReasons
        } else
            return []
    }

    const getVisitReasonsByGender = async (gender: string[]) => {
        if (accessToken) {
            const _visitReasons = await fetchVisitReasonsByGender(gender)
            if(!_visitReasons){
              pushAlert("Failed to get visit reasons", 'danger')
              return []
            }
            const visitReasons: Option[] = _visitReasons.map((reason: VisitReason) => ({
                name: reason.displayTitle,
                title: reason.title,
                secondaryText: reason.appointmentType.length === 2 ? 'In-Person or Virtual' : (reason.appointmentType[0] === 'inperson' ? 'In-Person only' : 'Virtual only'),
                value: {
                  id: reason.id,
                  duration: reason.visitLength
                }
            }))
            return visitReasons
        } else
            return []
    }

    const getVisitReasonById = async (id: number | string) => {
        if (accessToken) {
            const oneVisit = await fetchVisitReasonById(id);
            if(!oneVisit){
              pushAlert("Failed to fetch visit reason", 'danger')
              return {
                name: '',
                secondaryText: '',
                title: '',
                value: {id: '', duration: ''}
              }
            }
            const visitReason: Option = {
                name: oneVisit[0].displayTitle,
                title: oneVisit[0].title,
                secondaryText: oneVisit[0].appointmentType.length === 2 ? 'In-Person or Virtual' : (oneVisit[0].appointmentType[0] === 'inperson' ? 'In-Person only' : 'Virtual only'),
                value: {
                    id: oneVisit[0].id,
                    duration: oneVisit[0].visitLength
                }
            }

            return visitReason
        } else
            return {
                name: '',
                secondaryText: '',
                title: '',
                value: {id: '', duration: ''}
            }
    }

    return {  getVisitReasons, getVisitReasonsByGender, getVisitReasonById }
}