import clsx from "clsx"
import { IconProps } from 'phosphor-react'
import { FeaturedIcon, FeaturedIconType } from "../featuredIcon/FeaturedIcon"
import { AvatarLabelGroup } from "../avatarLabelGroup/AvatarLabelGroup"
import { Button } from "../button/Button"
import { Body } from "../typography/body/Body"
import { PatientInfo, UserInfo } from '../../lib/interfaces/user'
import { X } from 'phosphor-react'
import styles from './style.module.css'

type User = UserInfo | PatientInfo 

export type BackgroundColor = 'gray' | 'success' | 'purple' | 'primary' | 'danger'

export interface NotificationProps {
  id: string,
  backgroundColor: BackgroundColor
  Icon: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>
  iconColor: FeaturedIconType,
  title: string,
  caption?: string,
  creator?: User,
  timestamp: string,
  buttonLabel: string,
  buttonOnClick: () => void
  xOnClick: (notifId: string) => void
  className?: string
}

export const Notification = ({
  id,
  backgroundColor = 'gray',
  Icon,
  iconColor,
  title,
  caption,
  creator,
  timestamp,
  buttonLabel,
  buttonOnClick,
  xOnClick,
  className
}: NotificationProps) => {

  const xColors = {
    primary: 'var(--color-primary-25)',
    success: 'var(--color-success-25)',
    danger: 'var(--color-danger-25)',
    gray: 'var(--color-gray-25)',
    purple: 'var(--color-purple-25)'
  }

  return (
    <div className={clsx(className)}>
      <div className={clsx(styles.notificationContent, styles[backgroundColor + 'Light'])}>
        <div className={styles.iconAndText}>
          <FeaturedIcon Icon={Icon} type={iconColor} size='sm'/>
          <div className={styles.text}>
            <Body weight='bold'>{title}</Body>
            <Body size='sm' className={styles.preview}>{caption}</Body>
          </div>
        </div>     
        <div onClick={() => {xOnClick(id)}} style={{cursor: 'pointer'}}>
          <FeaturedIcon type={'gray'} backgroundColor={xColors[backgroundColor]} Icon={X} size='sm'/>
        </div>
      </div>
      <div className={clsx(styles.notificationTimestamp, styles[backgroundColor + 'Dark'])}>
        <div className={styles.userAndTime}>
          <AvatarLabelGroup users={creator? [creator] : []} size='sm' titleOverride={' '} avatarGroupGeneral={true}  className={styles.avatar}/>
          <Body size='xs' color='secondary'>{timestamp}</Body>
        </div>
        <Button label={buttonLabel} size='small'  type='secondary-gray' onClick={buttonOnClick}/>
      </div>
    </div>
  )}