import { Tag } from "../tag/Tag";
import { Body } from "../typography/body/Body";
import { Heading } from "../typography/heading/Heading";
import { CalendarBlank, CalendarX, Check, Prohibit } from "phosphor-react";
import { Button, ButtonGroup } from "../button/Button";
import { Phone } from "phosphor-react";
import {
  PatientCardInfo,
  PatientInfo,
  ProviderInfo,
} from "../../lib/interfaces/user";
import { useHistory } from "react-router-dom";
import { Drawer } from "../drawer/Drawer";
import { useContext, useEffect, useState } from "react";
import { NewAppointment } from "../../containers/schedule/newAppointment/NewAppointment";
import { useProvider } from "../../lib/hooks/useProvider";
import { Modal } from "../modal/Modal";
import { NotificationType } from "../alertBanner/AlertBanner";
import styles from "./style.module.css";
import { AvatarLabelGroup } from "../avatarLabelGroup/AvatarLabelGroup";
import { UserDetails } from "../userDetails/UserDetails";
import { useCarePlan } from "../../lib/hooks/useCarePlan";
import { Visit } from "../../lib/interfaces/visits";
import { differenceInMinutes, isBefore } from "date-fns";
import { BaseContext } from "../../lib/context/context";
import { getAppointmentTypeDisplayString } from "../../lib/apis/types/appointmentType";

export interface AppointmentDetailCardProps {
  date: string;
  providerName: string;
  providerId: string;
  patient: PatientInfo | PatientCardInfo;
  visitType: string;
  appointmentType: string;
  visitReasonId: string;
  visitReasonDuration: string;
  atmanMember?: boolean;
  // noCarePlan: boolean
  checkedIn?: boolean;
  preVisitInfoComplete?: boolean;
  visit: Visit;
  beforeAppt?: boolean;
  onClickCancelAppointment: () => void;
  onAppointmentUpdated?: (
    close: boolean,
    alertMessage?: string,
    alertType?: NotificationType
  ) => void;
}

export const AppointmentDetailCard = ({
  date,
  providerName,
  providerId,
  visitReasonDuration,
  patient,
  visitType,
  appointmentType,
  visitReasonId,
  atmanMember,
  checkedIn,
  preVisitInfoComplete,
  // noCarePlan,
  visit,
  beforeAppt,
  onAppointmentUpdated,
  onClickCancelAppointment,
}: AppointmentDetailCardProps) => {
  const history = useHistory();
  const { user } = useContext(BaseContext);
  const { getProvider } = useProvider();
  const { getCarePlan } = useCarePlan();
  const [chosenProvider, setChosenProvider] = useState<ProviderInfo>();
  const [viewUpdateApptDrawer, setViewUpdateApptDrawer] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState<boolean>(false);
  const [chosenVisitTypeId, setChosenVisitTypeId] = useState<string | number>();
  const [chosenPatient, setChosenPatient] = useState<
    PatientInfo | PatientCardInfo
  >();
  const [showProviderModal, setShowProviderModal] = useState<boolean>(false);
  const [noCarePlan, setNoCarePlan] = useState<boolean>();
  const [bookingAnother, setBookingAnother] = useState<boolean>(false);
  //bookingAnother is used to handle the case where a user reschedules an appt, then chooses book another, but then closes the drawer before booking another appt
  const visitId = visit.visitId;

  useEffect(() => {
    if (patient && patient?.settings?.General?.Membership === "Premium") {
      getCarePlan(patient.id).then((carePlanRes) => {
        setNoCarePlan(
          carePlanRes && carePlanRes.id && carePlanRes.id !== "" ? false : true
        );
      });
    }
  }, [patient]);

  useEffect(() => {
    if (providerId && providerId !== "")
      getProvider(providerId).then(setChosenProvider);
  }, [providerId]);

  useEffect(() => {
    if (chosenPatient && chosenVisitTypeId && chosenProvider) {
      setViewUpdateApptDrawer(true);
    }
  }, [chosenPatient, chosenVisitTypeId, chosenProvider]);

  const handleUpdateAppointmentExit = () => {
    if (bookingAnother) onAppointmentUpdated && onAppointmentUpdated(true);
    setViewUpdateApptDrawer(false);
    setChosenPatient(undefined);
    setChosenVisitTypeId(undefined);
    setChosenProvider(undefined);
  };

  const handleUpdateAppointmentClick = () => {
    setChosenPatient(patient);
    setChosenVisitTypeId(visitReasonId);
    providerId && getProvider(providerId).then(setChosenProvider);
    // setViewUpdateApptDrawer(true)
  };

  const handleCancelApptClick = () => {
    setCancelModalVisible(true);
  };

  const handleCancelApptConfirm = async () => {
    await onClickCancelAppointment();
    setCancelModalVisible(false);
  };

  const handleAppointmentRescheduled = (bookAnother?: boolean) => {
    bookAnother && setBookingAnother(bookAnother);
    !bookAnother && setViewUpdateApptDrawer(false);
    if (onAppointmentUpdated) {
      !bookAnother &&
        onAppointmentUpdated(
          true,
          "Appointment successfuly rescheduled.",
          "success"
        );
      bookAnother && onAppointmentUpdated(false);
    }
  };

  function addMinutes(date: Date, minutes: number) {
    return new Date(date.getTime() + minutes * 60000);
  }

  const videoButtonDisabled = () => {
    //the total amount of time the video button should be enabled is 15 minutes before the appt and for the duration of the appt
    const now = new Date();
    const visitDateTime = new Date(visit?.dateTime.split(" ").join("T"));
    const visitDuration = parseInt(visitReasonDuration);
    //add 1 minute to visitDuration so that the button will be disabled the minute after the appointment is done
    //i.e. if the endOfVisit time is 11:45, the button will be disabled at 11:46
    const endOfVisitDateTime = addMinutes(visitDateTime, visitDuration + 1);
    const minsFromStartOfAppt =
      visit && differenceInMinutes(visitDateTime, now);
    if (
      //the time now is 15 minutes before the visit start time
      (minsFromStartOfAppt >= 0 && minsFromStartOfAppt <= 15) ||
      //or the time now is after the visit start time but before the visit end time
      (minsFromStartOfAppt < 0 && isBefore(now, endOfVisitDateTime))
    ) {
      return false;
    } else {
      return true;
    }
  };

  const titleOverride: JSX.Element = (
    <>
      <div style={{ display: "flex" }}>
        <Body size="sm" color="secondary">
          {visitType} with{" "}
        </Body>
        <div
          data-dd-privacy="mask"
          onClick={() => {
            setShowProviderModal(true);
          }}
          style={{ cursor: "pointer" }}
        >
          <Body size="sm" color="link">
            {providerName}
          </Body>
        </div>
      </div>
      <Body size="sm" weight="bold" color="secondary">
        {getAppointmentTypeDisplayString(appointmentType)} Visit
      </Body>
    </>
  );

  return (
    <div className={styles.appointmentDetailCard}>
      <div className={styles.row1}>
        <Heading type="03" style={{ width: 417, height: 22, paddingRight: 14 }}>
          {date}
        </Heading>
        <ButtonGroup>
          <Button
            size="small"
            type="secondary-gray"
            disabled={!beforeAppt}
            label="Cancel"
            iconPosition="left"
            Icon={CalendarX}
            onClick={handleCancelApptClick}
          />
          <Button
            size="small"
            type="secondary-gray"
            disabled={!beforeAppt}
            label="Reschedule"
            Icon={CalendarBlank}
            iconPosition="left"
            onClick={handleUpdateAppointmentClick}
          />
        
          {appointmentType === "virtual" ? (
            <Button
              size="small"
              type="primary"
              label="Join Video Visit"
              disabled={videoButtonDisabled()}
              Icon={Phone}
              iconPosition="left"
              onClick={() =>
                history.push("/video", {
                  visitId,
                  patient,
                  noCarePlan,
                  providerId,
                  from: '/scheduling'
                })
              }
            />
          ) : (
            <></>
          )}
        </ButtonGroup>
      </div>
      <div className={styles.row2}>
        <AvatarLabelGroup
          users={[patient]}
          titleOverride={titleOverride}
          linkUser={true}
        />
        <div className={styles.apptTags}>
          <div className={styles.tag}>
            <Tag
              content={"Checked In"}
              type={checkedIn ? "success" : "danger"}
              Icon={checkedIn ? Check : Prohibit}
              iconPosition={"right"}
            />
          </div>

          <Tag
            content={"Pre Visit Info Complete"}
            type={preVisitInfoComplete ? "success" : "danger"}
            Icon={preVisitInfoComplete ? Check : Prohibit}
            iconPosition={"right"}
          />
        </div>
      </div>
      <div className={styles.patientTags}>
        {noCarePlan && <Tag content="No Care Plan" />}
      </div>

      <Drawer
        title={"Update Appointment"}
        onClose={() => {
          handleUpdateAppointmentExit();
        }}
        visible={viewUpdateApptDrawer}
        className={styles.drawer}
      >
        <NewAppointment
          data-dd-privacy="mask"
          visitId={visit.visitId}
          chosenPatient={chosenPatient}
          chosenVisitTypeId={chosenVisitTypeId}
          chosenProvider={chosenProvider}
          onNewAppointmentBooked={handleAppointmentRescheduled}
          disableVisitField={true}
          visit={visit}
        />
      </Drawer>

      <Modal
        onCloseModal={() => {}}
        title="Cancel Appointment"
        dismissable={false}
        visible={cancelModalVisible}
      >
        <div style={{ overflow: "auto" }}>
          <Body>
            Are you sure you want to cancel this appointment? The time will be
            released for another patient.
          </Body>

          <div style={{ height: 32 }} />

          <ButtonGroup className={styles.buttons} align={"end"}>
            <Button
              onClick={() => setCancelModalVisible(false)}
              type="secondary-gray"
              label="Cancel"
            />
            <Button
              onClick={handleCancelApptConfirm}
              label="Cancel Appointment"
            />
          </ButtonGroup>
        </div>
      </Modal>

      <Modal
        onCloseModal={() => setShowProviderModal(false)}
        visible={showProviderModal}
        title={"Provider Detail"}
        centerTitle={true}
        className={styles.providerModal}
      >
        <UserDetails data-dd-privacy="mask" provider={chosenProvider} />
      </Modal>
    </div>
  );
};
