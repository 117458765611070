import { Body } from "../../../components/typography/body/Body"
import { Heading } from "../../../components/typography/heading/Heading"
import { InsuranceInformation } from "../insuranceInformation/InsuranceInformation"
import { PatientInfo } from "../../../lib/interfaces/user"
import { format, isValid, differenceInYears, parse } from 'date-fns'
import styles from './style.module.css'

export interface PatientInformationProps {
  patient: PatientInfo
}

export const PatientInformation = ({patient}: PatientInformationProps) => {

  const getFriendlyGender = (gender: string) => {
    return gender.charAt(0).toUpperCase() + gender.slice(1);
  }

  const getFriendlyBirthDate = (date: string) => {
    let friendlyDate = ''
    if(!date.includes('T')) { friendlyDate = date + "T00:00:00" }
    if(date.includes('Z')) { friendlyDate = date.slice(0, -1) }
    let bday
    if(isValid(new Date(friendlyDate))) { bday = new Date(friendlyDate) }
    else { return '' }
    return format(bday, 'MMMM d, yyyy')
  }

  const getAgeFormatted = (dob: string): string => {
    const dateOfBirth = parse(dob, 'yyyy-MM-dd', new Date())
    const age = differenceInYears(new Date(), dateOfBirth)
    return `(${age} years old)`
  }

  return (
    <div>
      <Heading type='02' className={styles.header}>Patient Information</Heading>
      <div className={styles.rowNoMarginBottom}>
          <div className={styles.column1}>
              <div className={styles.row}>
                  <Body className={styles.category} size='md' color='secondary'>Gender Identity</Body>
                  <Body data-dd-privacy="mask" className={styles.info} size='md' color='primary'>{patient.assignedSex ? getFriendlyGender(patient.assignedSex) : "-"}</Body>
              </div>
              <div className={styles.row}>
                  <Body className={styles.category} size='md' color='secondary'>Birth Date</Body>
                  <Body className={styles.info} size='md' color='primary'>{patient.dateOfBirth ? getFriendlyBirthDate(patient.dateOfBirth) : "-"} {patient.dateOfBirth ? getAgeFormatted(patient.dateOfBirth): ''}</Body>
              </div>
              <div className={styles.row}>
                  <Body className={styles.category} size='md' color='secondary'>Address</Body>
                  <div className={styles.address}>
                      <Body data-dd-privacy="mask" className={styles.info} size='md' color='primary'>{patient.addressLine1 ? patient.addressLine1 : "-"}</Body>
                      {patient.addressLine2 !== null && patient.addressLine2 !== "" &&
                        <Body data-dd-privacy="mask" className={styles.info} size='md' color='primary'>{patient.addressLine2}</Body>}   
                      {patient.city !== null && patient.city !== "" && patient.state !== null && patient.state !== "" && <Body data-dd-privacy="mask" className={styles.info} size='md' color='primary'>{patient.city + ", " + patient.state}</Body>}
                      {patient.zip !== null && patient.zip !== "" && <Body data-dd-privacy="mask" className={styles.info} size='md' color='primary'>{patient.zip}</Body>}

                  </div>        
              </div>
          </div>        
          <div className={styles.column2}>
              <div className={styles.row}>
                  <Body className={styles.category} size='md' color='secondary'>Emergency Contact</Body>
                  <Body data-dd-privacy="mask" className={styles.info} size='md' color='primary'>{patient.emergencyContact && patient.emergencyContact.name ? patient.emergencyContact.name : "-"}</Body>
              </div>
              <div className={styles.row}>
                  <Body className={styles.category} size='md' color='secondary'>Relationship</Body>
                  <Body data-dd-privacy="mask" className={styles.info} size='md' color='primary'>{patient.emergencyContact && patient.emergencyContact.relation ? patient.emergencyContact.relation : "-"}</Body>
              </div>
              <div className={styles.row}>
                  <Body className={styles.category} size='md' color='secondary'>Phone Number</Body>
                  <Body data-dd-privacy="mask" className={styles.info} size='md' color='primary'>{patient.mobile ? patient.mobile : "-"}</Body>
              </div>
              <div className={styles.row}>
                  <Body className={styles.category} size='md' color='secondary'>Email</Body>
                  <Body data-dd-privacy="mask" className={styles.info} size='md' color='primary'>{patient.email ? patient.email : "-"}</Body>
              </div>
          </div>        
      </div>
      <div className={styles.insurance}>
        <InsuranceInformation coverage={patient.coverage ? patient.coverage : []}/>
      </div>
    </div>
  )
}