import { Pencil } from "phosphor-react";
import { useEffect, useMemo, useState } from "react";
import { Button, ButtonGroup } from "../../../components/button/Button";
import { GoalIcon } from "../../../components/customIcons/CustonIcons";
import { FeaturedIcon } from "../../../components/featuredIcon/FeaturedIcon";
import { TextInput } from "../../../components/textInput/TextInput";
import { Body } from "../../../components/typography/body/Body";
import { Heading } from "../../../components/typography/heading/Heading";
import { Intention } from "../../../lib/interfaces/carePlan";
import { useBaseContext } from "../../_base/Base";
import { format, isToday, isWithinInterval, isYesterday, startOfToday, subDays } from "date-fns"
import styles from "./style.module.css";
import { useProvider } from "../../../lib/hooks/useProvider";
import { getLocalDateTime } from "../../../lib/util/date";

interface CarePlanIntentionsProps {
  intention?: Intention
  onSave: (intention: Intention) => Promise<boolean>
}

export const CarePlanIntentions = ({ intention, onSave }: CarePlanIntentionsProps) => {
  const [draftIntention, setDraftIntention] = useState<string>(intention?.value || '')
  const [editing, setEditing] = useState(false)
  const { user } = useBaseContext()
  const [modifiedBy, setModifiedBy] = useState<string>()
  const { getProvider } = useProvider()

  const modifiedOn = useMemo(() => {
    if (intention?.modifiedBy && intention.modifiedOn) {
      const date = getLocalDateTime(intention.modifiedOn)
      const time = format(date, 'h:mm a')
      let day: string;
      if (isToday(date))
        day = 'Today'
      else if (isYesterday(date))
        day = 'Yesterday'
      else if (isWithinInterval(date, { start: subDays(startOfToday(), 6), end: startOfToday() }))
        day = format(date, 'eeee')
      else
        day = format(date, 'M/d/yy')

      return `${day} ${time}`
    }
  }, [intention])

  useEffect(() => {
    if (intention?.modifiedBy)
      getProvider(intention.modifiedBy).then(provider => setModifiedBy(provider.name))
  }, [intention])

  const handleCancel = () => {
    setEditing(false)
    setDraftIntention(intention?.value || '')
  }

  const handleSave = async () => {
    const result = await onSave({
      value: draftIntention,
      modifiedBy: user?.sub as string,
    })
    if(result) {
      setEditing(false)
    } else {}
  }

  return (
    <div className={styles.intentionSection}>
      <div className={styles.header}>
        <div className={styles.headerTitle}>
          <FeaturedIcon Icon={GoalIcon} type="blue" iconColor="#4993D0" />
          <div>
            <Heading type="02">Intention</Heading>
            <Body size="md" weight="medium" color="secondary">
              Collaborate with patient on intention
            </Body>
          </div>
        </div>
        {!editing && (
          <Button
            Icon={Pencil}
            onClick={() => setEditing(true)}
            size="small"
            type="secondary"
          />
        )}
      </div>

      {(draftIntention.length > 0 || editing) && (
        <div className={styles.contents}>
          {editing ? (
            <>
              <TextInput placeholder='Enter your message' value={draftIntention} onChange={setDraftIntention} multiline minRows={5} maxRows={5} />
              <ButtonGroup className={styles.buttonGroup} align='end'>
                <Button label='Cancel' onClick={handleCancel} type='secondary-gray' />
                <Button label='Save' onClick={handleSave} />
              </ButtonGroup>
            </>
          ) : (
            <>
              {modifiedBy && (<Body size='sm' weight='bold' color='link'>{`Edited ${modifiedOn} ${modifiedBy}`}</Body>)}
              < Body size='md'>{draftIntention}</Body>
            </>
          )}
        </div>
      )
      }
    </div >
  );
};
