import clsx from "clsx";
import { useMemo } from "react";
import { UserInfo } from "../../lib/interfaces/user";
import { MouseEventHandler } from "react";
import { Users, CalendarBlank, Chat, BellSimple } from "phosphor-react";
import { Avatar } from "../avatar/Avatar";
import { useHistory, useLocation } from "react-router-dom";
import { MarkLogo } from "../../components/customIcons/CustonIcons";
import styles from "./style.module.css";

type ButtonTypes =
  | "calendar"
  | "messages"
  | "patients"
  | "notifications"
  | "avatar";

// Menu Button
interface MenuButtonProps {
  onClick?: MouseEventHandler;
  type: ButtonTypes;
  badge?: boolean;
  active?: boolean;
  user?: UserInfo;
}

export const MenuButton = ({
  onClick,
  type,
  badge = false,
  active,
  user,
}: MenuButtonProps) => {
  let icon: JSX.Element = <></>;
  let toolTipText = "";
  switch (type) {
    case "calendar":
      icon = <CalendarBlank size={28} color={"white"} />;
      toolTipText = "Scheduling";
      break;
    case "messages":
      icon = <Chat size={28} color={"white"} />;
      toolTipText = "Messaging";
      break;
    case "patients":
      icon = <Users size={28} color={"white"} />;
      toolTipText = "Patients";
      break;
    case "notifications":
      icon = <BellSimple size={28} color={"white"} />;
      toolTipText = "Notifications";
      break;
  }

  return (
    <a
      onClick={onClick}
      title={toolTipText}
      className={clsx(
        type === "avatar" ? styles.navAvatar : styles.navButton,
        badge && styles.withBadge,
        active && styles.active,
        styles[type],
        styles.menuButton
      )}
    >
      {icon}
    </a>
  );
};

interface SidebarProps {
  calendarBadge?: boolean;
  messageBadge?: boolean;
  patientsBadge?: boolean;
  notificationBadge?: boolean;
  user?: UserInfo;
}

export const Sidebar = ({
  calendarBadge,
  messageBadge,
  patientsBadge,
  notificationBadge,
  user,
}: SidebarProps) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const currentApp = useMemo(() => pathname.split("/").filter(Boolean)[0], [
    pathname,
  ]);

  const signedInUser = useMemo(() => {
    return (
      user || {
        id: "",
        name: "",
        firstName: "",
        lastName: "",
        title: "",
      }
    );
  }, [user]);

  const handleAvatarClick = () => {
    history.push("/availability");
  };

  return (
    <nav className={styles.sidebar}>
      <div className={styles.logoContainer}>
        <MarkLogo />
      </div>
      <ul className={styles.navButtonContainer}>
        <li style={{ width: 48 }}>
          <MenuButton
            onClick={() => history.push("/scheduling")}
            type={"calendar"}
            badge={calendarBadge}
            active={currentApp === "scheduling"}
          />
        </li>
        <li>
          <MenuButton
            onClick={() => history.push("/messages")}
            type={"messages"}
            badge={messageBadge}
            active={currentApp === "messages"}
          />
        </li>
        <li>
          <MenuButton
            onClick={() => history.push("/patient")}
            type={"patients"}
            badge={patientsBadge}
            active={currentApp === "patient"}
          />
        </li>
        <div className={styles.separator} />
        <li>
          <MenuButton
            onClick={() => history.push("/notifications")}
            type={"notifications"}
            badge={notificationBadge}
            active={currentApp === "notifications"}
          />
        </li>
        <li>
          <a
            onClick={handleAvatarClick}
            className={clsx(styles.navAvatar, styles.avatar)}
          >
            <Avatar user={signedInUser} size={"lg"} title={"Profile"} />
          </a>
        </li>
      </ul>
    </nav>
  );
};
