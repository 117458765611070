import React, { useState } from "react";
import { Check } from "phosphor-react";
import { Body } from "../typography/body/Body";
import { Option } from "../../lib/interfaces/input";
import { Spinner } from "../spinner/Spinner";
import { Avatar } from "../avatar/Avatar";
import { CircleWavyCheck } from 'phosphor-react'
import styles from './style.module.css'
import _ from "lodash";
import clsx from "clsx";

export interface DropdownOptionProps {
  onToggle: (selected: boolean) => void
  option: Option
  selected?: boolean
}

export const DropdownOption = ({
  onToggle,
  option,
  selected,
}: DropdownOptionProps) => {

  const [hover, setHover] = useState(false)

  return (
  <label className={clsx(styles.dropdownOption, selected && styles.selected)} onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>
    {option.Icon ? (
      <option.Icon size={24} />
    ) : typeof option.avatarUrl === 'string' && (
      <Avatar
        size='xs'
        photo={option.avatarUrl}
        firstName={option.name.split(' ')[0]}
        lastName={option.name.split(' ')[1]} />
    )}
    <div className={styles.dropdownOptionNameAndSecondaryText}>
      <div className={styles.dropdownOptionName}>
        <Body size='md' color={hover ? 'link' : 'primary'}>
          {option.name}
        </Body>
        {option.premiumMember && <CircleWavyCheck color="#1A6D93" weight="fill" className={styles.premium}/>}
      </div>
      {option?.secondaryText && <Body size='xs' color={hover ? 'link' : 'secondary'}>{option.secondaryText}</Body>}
    </div>
    <input className={styles.dropdownOptionCheckbox} checked={selected} type="checkbox" onChange={event => onToggle(event.target.checked)} />
    <Check className={styles.dropdownOptionCheckmark} size={24} />
  </label>
  )
}

export interface DropdownProps {
  onOptionSelect: (option: Option, selected: boolean) => void
  options: Option[]
  selectedValue?: string | string[]
  maxHeight?: number
  width?: number | string
  loading?: boolean
}

export const Dropdown = ({
  onOptionSelect,
  options,
  selectedValue = [],
  maxHeight = 200,
  width,
  loading
}: DropdownProps) => {

  const isSelected = (option: Option) => {
    if (Array.isArray(selectedValue)) return selectedValue.includes(option.value || option.id)
    else return (selectedValue === option.value || selectedValue === option.id || _.isEqual(selectedValue, option.value))
  }

  const preventFocusSteal = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation()
  }

  return (
    <div className={styles.dropdown} style={{ maxHeight: maxHeight, width: width, overflowY: loading ? 'hidden' : 'auto' }} onMouseDown={preventFocusSteal}>
      {!loading && options.length > 0 && options.map(option => (
        <DropdownOption
          option={option}
          key={option.id || option.value?.id || option.value}
          selected={isSelected(option)}
          onToggle={(selected) => onOptionSelect(option, selected)} />
      ))}
      {loading &&
        <div className={styles.noResults}>
          <Spinner top={8} />
        </div>
      }
      {options.length === 0 && !loading &&
        <label className={styles.noResults}>
          <Body size='md' color='secondary'> No Results </Body>
        </label>
      }
    </div>
  )
}
