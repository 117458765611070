import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Avatar } from "../../../components/avatar/Avatar"
import { Body } from "../../../components/typography/body/Body"
import { Heading } from "../../../components/typography/heading/Heading"
import { FormattedAvailability } from "../../../lib/hooks/useAvailabilityManagement";
import { ProviderInfo, UserInfo } from '../../../lib/interfaces/user'
import styles from './style.module.css'

export interface LastUpdate {
  saved: boolean,
  signedInUser: UserInfo,
  availability: FormattedAvailability | undefined
}

export interface dateOptions {
  year: 'numeric', month: 'long', day: 'numeric'
}

export const LastUpdate = ({saved, signedInUser, availability}: LastUpdate) => {

  const [modifiedBy, setModifiedBy] = useState<ProviderInfo | UserInfo>()
  const [modifiedOn, setModifiedOn] = useState("")
  const [initialRender, setInitialRender] = useState(true)

  useEffect(() => {
    if(availability){
      setModifiedBy(availability.lastUpdate.modifiedBy)
      setModifiedOn(availability.lastUpdate.modifiedOn)
    }
  }, [availability])

  useEffect(() => {
    if(initialRender){
      setInitialRender(false)
    }
    else{
      const today = new Date()
      setModifiedOn(format(today, 'MMMM d, yyyy') + ' at ' + format(today, 'h:mmaaa'))
      setModifiedBy(signedInUser)
    }
  }, [saved])
  
  return (
    <div>
        <Heading className={styles.lastUpdateHeading} type='03'>{"Last Update"}</Heading>
        {modifiedOn == "" ? 
          <Body size='sm' weight='regular' color='secondary' className={styles.lastUpdateBody}>{"No updates have been made"}</Body> 
        :
          <>
            <Body size='sm' weight='regular' color='secondary' className={styles.lastUpdateBody}>{modifiedOn}</Body>
            <div className={styles.lastUpdateUser}>
              <Avatar user={modifiedBy} size='xxs' className={styles.lastUpdateUserAvatar}/>
              <Body size='xs' weight='regular' color='secondary' className={styles.lastUpdateUserNameAndTitle}>{modifiedBy?.name + ' - ' + modifiedBy?.title}</Body>
            </div>
          </>
        }
    </div>
  )
}
