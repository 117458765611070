import { Button, ButtonGroup } from "../../components/button/Button"
import { Body } from "../../components/typography/body/Body"
import styles from './style.module.css'

export type LogoutPromptFormProps = {
  onLogoutNow: () => void
  onStayConnected: () => void
}

export const LogoutPromptForm = ({onLogoutNow, onStayConnected} : LogoutPromptFormProps) => {
  return (
    <div>
      <Body>
        We are logging you out soon. Would you like to stay connected ?
      </Body>
      <ButtonGroup className={styles.buttonGroup}>
        <Button label='Log out now' type='secondary-gray' onClick={onLogoutNow} />
        <Button label='Stay Connected' onClick={onStayConnected} />
      </ButtonGroup>
    
    </div>

  )
}