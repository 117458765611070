import { useState, useEffect, useContext, useMemo } from "react";
import { Button, ButtonGroup } from "../../../components/button/Button";
import { SearchSelect } from "../../../components/searchSelect/SearchSelect";
import { Select } from "../../../components/select/Select";
import { Switch } from "../../../components/switch/Switch";
import { Option } from "../../../lib/interfaces/input";
import { FilterOptions } from "../../../lib/interfaces/listOfPatients";
import { useProvider } from "../../../lib/hooks/useProvider";
import { ProviderInfo } from "../../../lib/interfaces/user";
import styles from "./style.module.css";
import { BaseContext } from "../../../lib/context/context";
import ProviderType from "../../../lib/models/providerType";

const sortingOptions: Option[] = [
  { name: "Patient Last Name - A to Z", value: "patient-alph" },
  { name: "Patient Last Name - Z to A", value: "patient-alph-rev" },
];

export interface FiltersFormProps {
  sortingOption: Option;
  filterOptions: FilterOptions;
  onClearAll: () => void;
  onApply: (
    selectedSortingOption: Option,
    selectedFilterOptions: FilterOptions,
    noAppointmentOption: boolean,
    compassPlusOption: boolean,
  ) => void;
  sortDisabled: boolean;
  noAppointmentOption: boolean;
  compassPlusOption: boolean;
  handleNoAppointmentToggleChange: () => void;
  handleCompassPlusToggleChange: () => void;
  applyFilterDisabled: boolean;
  clearDisabled: boolean;
  handleOnFilterClick: () => void;
}

export const FiltersForm = ({
  sortingOption,
  filterOptions,
  onApply,
  onClearAll,
  sortDisabled,
  noAppointmentOption,
  compassPlusOption,
  handleNoAppointmentToggleChange,
  handleCompassPlusToggleChange,
  applyFilterDisabled,
  clearDisabled,
  handleOnFilterClick,
}: FiltersFormProps) => {
  const { getAllProviders } = useProvider();

  const [selectedSortingOption, setSelectedSortingOption] = useState<Option>(
    sortingOption
  );
  const [
    selectedFilterOptions,
    setSelectedFilterOption,
  ] = useState<FilterOptions>(filterOptions);
  const [providers, setProviders] = useState<ProviderInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { userInfo } = useContext(BaseContext);

  const [noAppointmentToggle, setNoAppointmentToggle] = useState<boolean>(noAppointmentOption);
  const [compassPlusToggle, setCompassPlusToggle] = useState<boolean>(compassPlusOption);

  useEffect(() => {
    getAllProviders().then((res) => setProviders(res));
  }, []);

  useEffect(() => {
    if (providers.length > 0) {
      setLoading(false);
    }
  }, [providers]);

  const handleSelectedProvidersChange = (selectedProviders: Option[]) => {
    setSelectedFilterOption({
      ...selectedFilterOptions,
      providers: selectedProviders,
    });
    handleOnFilterClick();
  };

  const handleSelectedSortOptionChange = (selectedOption: Option) => {
    setSelectedSortingOption(selectedOption);
    handleOnFilterClick();
  };

  const handleClickClear = () => {
    setSelectedSortingOption({
      name: "Patient Last Name - A to Z",
      value: "patient-alph",
    });
    setSelectedFilterOption({ providers: [] });
    handleClearAll();
  };

  const assignToDisabled = useMemo(() => {
    if (userInfo?.type === ProviderType.OutsideSpecialist) {
      return true;
    }
    return false;
  }, [userInfo]);

  useEffect(() => {
    //enable clear and filter buttons here
  }, [selectedSortingOption, selectedFilterOptions]);

  const handleApply = () =>
    onApply(selectedSortingOption, selectedFilterOptions, noAppointmentToggle, compassPlusToggle);
  const handleClearAll = () => onClearAll();

  return (
    <div className={styles.filtersForm}>
      <div className={styles.filtersContainer}>
        <div className={styles.select}>
          <Select
            label="Sort By"
            placeholder="Select..."
            disabled={sortDisabled}
            value={selectedSortingOption}
            options={sortingOptions}
            onChange={handleSelectedSortOptionChange}
          />
        </div>

        {!assignToDisabled && (
          <div className={styles.select}>
            <SearchSelect
              label="Assigned to"
              placeholder="Search"
              value={selectedFilterOptions.providers}
              onChange={handleSelectedProvidersChange}
              options={providers.map((provider) => ({
                avatarUrl: provider.photo || "",
                name: provider.firstName + " " + provider.lastName,
                value: provider.id,
              }))}
              loading={loading}
            />
          </div>
        )}

        <div className={styles.fieldContainer}>
          <label className={styles.switchLabel}>Appointments</label>
          <Switch
            active={noAppointmentToggle}
            label="First Appointment Not Yet Scheduled"
            onToggle={() => {
              setNoAppointmentToggle(!noAppointmentToggle);
              handleNoAppointmentToggleChange();
            }}
          />
        </div>

        <div>
          <label className={styles.switchLabel}>Membership</label>
          <Switch
            active={compassPlusToggle}
            label="Compass Plus"
            onToggle={() => {
              setCompassPlusToggle(!compassPlusToggle);
              handleCompassPlusToggleChange();
            }}
          />
        </div>
      </div>
      <ButtonGroup>
        <Button
          type="secondary-gray"
          disabled={clearDisabled}
          onClick={handleClickClear}
          label="Clear All"
        />
        <Button
          type="primary"
          disabled={applyFilterDisabled}
          onClick={handleApply}
          label="Apply Filters"
        />
      </ButtonGroup>
    </div>
  );
};
