export const getAppointmentTypeDisplayString = (appointmentType: string) => {
  switch (appointmentType) {
    case "inperson":
      return "In-Person"
    case "virtual":
      return "Virtual"
    default:
      return ""
  }
}
