import axios, { AxiosResponse } from 'axios'

export interface ResponseData {
  id: string,
  userId: string,
  name: string,
  sharedOn: string,
  sharedBy: string,
  url: string
}

interface FilesResponse extends AxiosResponse {
  data: ResponseData[]
}

export interface UploadFilePayload {
  userId: string,
  name: string,
  file: string
}

export interface DeleteFilePayload {
  userId: string,
  id: string
}

export const fetchFiles = async (userId: string, controller?: AbortController) => {
  const result = await axios.get<FilesResponse, FilesResponse>(
    `/record/attachments?userId=${userId}`,
    {
      signal:  controller ? controller.signal : undefined
    })
    .catch(err => { 
      if(axios.isCancel(err)) return {data: 'cancelled'}
      else return err
    })
  return result.data
}

export const fetchFile = async (userId: string, recordId: string) => {
  const result = await axios.get<FilesResponse, FilesResponse>(
    `/record/attachments?userId=${userId}&id=${recordId}`,
   )
    .catch(err => err)
  return result.data
}

export const postFile = async (file: UploadFilePayload) => {
  const result = await axios.post(
    '/record/attachment',
    file).catch(err => err)

  return result
}

export const deleteFile = async (file: DeleteFilePayload) => {
  const result = await axios.delete(
    '/record/attachment',
    {
      data: file
    }).catch(err => err)

  return result
}
