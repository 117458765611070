import axios, { AxiosResponse } from "axios";

export interface ResponseData {
  id: number;
  title: string;
  accountId: string;
  hoagId: string;
  hoagProviderId: string;
  ehrId: string;
  ehr: string;
  npi: string;
  firstName: string;
  lastName: string;
  preferredName: string;
  nameWithDegree: string;
  status: string;
  primarySpecialty: string;
  highlights: string;
  focusAreas: string;
  bio: string;
  specialties: string[];
  spokenLanguages: string;
  education: string[];
  welcomeMessage: string;
  videoURL: string;
  photoURL: string;
  type: string;
  identifiers: any[];
}

interface AllProvidersResponse extends AxiosResponse {
  data: ResponseData[];
}

export const fetchAll = async () => {
  const result = await axios
    .get<AllProvidersResponse, AllProvidersResponse>("/")
    .catch((err) => err)
  return result.data
}

export const fetch = async (accountId: string) => {
  const result = await axios
    .get<AllProvidersResponse, AllProvidersResponse>("", {
      params: {
        accountId,
      },
    })
    .catch((err) => err)

  return result?.data?.[0];
};

export default {
  fetchAll,
  fetch,
}
