import { useState, useEffect } from "react"
import { Body } from "../../../components/typography/body/Body"
import { CellProps, Table } from "../../../components/table/Table"
import { useTests } from "../../../lib/hooks/useTests"
import { Spinner } from "../../../components/spinner/Spinner"
import styles from './style.module.css'

export interface ResultDetailProps {
  patientId: string,
  orderId: string,
  resultRequestor: string,
  resultDate: string,
  // notes: string,
  orders: CellProps[][],
  resultSignedBy: string
}

export const ResultDetail = ({patientId, orderId, resultRequestor, resultDate, orders, resultSignedBy}: ResultDetailProps) => {

  //const result = mockedResults[resultId]
  //const [result, setResult] = useState(mockedResults[resultId])
  const [loadingTableData, setLoadingTableData] = useState<boolean>(true)
  //const [loadingNotes, setLoadingNotes] = useState<boolean>(true)
  const [tableData, setTableData] = useState<CellProps[][]>([])
  const [notes, setNotes] = useState<string[]>([])

  const { getTestResultData } = useTests()

  useEffect(() => {
    if(orderId !== ''){
      setLoadingTableData(true)
      //setLoadingNotes(true)
      getTestResultData(patientId, orderId).then(_results => { 
        setTableData(_results.tableData); 
        setLoadingTableData(false); 
        setNotes(_results.notes.length === 0 ? ["None"] : _results.notes.map(note => note.Text))
      })
    }
    //gets run when orderId is about to get updated
    return () => {
      setTableData([])
      setNotes([])
      setLoadingTableData(true)
    }
  }, [orderId])

  // useEffect(() => {
  //   setLoadingNotes(false)
  // }, [notes])

  return (
    <div className={styles.resultDetail}>
        <div>
            <Body className={styles.author} size='md' weight='bold'>{'Requestor'}</Body>
            <Body size='md' >{resultRequestor}</Body>
            <Body className={styles.date} size='md' weight='bold'>{'Result'}</Body>
            <Body size='md' >{resultDate}</Body>
            <Body className={styles.notes} size='md' weight='bold'>{'Notes'}</Body>
            {notes.map((note, i) => <Body size='md' key={i}>{note}</Body>)}
            {/* <Body size='md'>{notes}</Body> */}
            <div className={styles.orders}>
              {!loadingTableData ? 
                <Table title='Result Values' data={tableData} columns={['Test', 'Units', 'Value', 'Reference Range']}/>
              :
                <Spinner />
              }                
            </div>
            <Body className={styles.notes} size='md' color='secondary'>{"Signed By: "}</Body>
        </div>
    </div>
  )
}