import { useMemoryRouter } from "../../../../components/modal/Modal"
import { Body } from "../../../../components/typography/body/Body"
import { Heading } from "../../../../components/typography/heading/Heading"
import styles from './style.module.css'

export const IncludePatientForm = () => {
  const { pushRoute } = useMemoryRouter()

  return (
    <div className={styles.includePatientForm}>
      <Body className={styles.prompt} size='md' weight="bold">Will your new message include a patient?</Body>
      <button onClick={() => pushRoute('patient-select')}>
        <Heading type="03" color='link'>Yes</Heading>
        <Body>Message a patient individually or as a group with providers.</Body>
      </button>
      <button onClick={() => pushRoute('patient-select', { props: { patientAsParticipant: false } })}>
        <Heading type="03" color='link'>No</Heading>
        <Body>Message providers about admin duties or to discuss a patient. Patient will not be included in the message.</Body>
      </button>
    </div>
  )

}