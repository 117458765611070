import { useEffect, useState } from "react"
import { Spinner } from "../../../components/spinner/Spinner"
import { UserDetails } from "../../../components/userDetails/UserDetails"
import { usePatient } from "../../../lib/hooks/usePatient"
import { useProvider } from "../../../lib/hooks/useProvider"
import { PatientInfo, ProviderInfo } from "../../../lib/interfaces/user"

interface ParticipantDetailsProps {
  id?: string
  userType?: 'patient' | 'provider'
  closeModal?: () => void
}

export const ParticipantDetails = ({ id, userType, closeModal }: ParticipantDetailsProps) => {
  const [user, setUser] = useState<ProviderInfo | PatientInfo>()
  const { getPatient } = usePatient()
  const { getProvider } = useProvider()

  useEffect(() => {
    if (id) {
      if (userType === 'patient') getPatient(id).then(setUser)
      else getProvider(id).then(setUser)
    }
  }, [id])

  return (
    user ? (
      <UserDetails
        patient={userType === 'patient' ? user : undefined} closeModal={closeModal} //@ts-ignore
        provider={userType === 'provider' ? user : undefined}
      />
    ) : (<Spinner />)
  )
}